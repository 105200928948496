import React from "react";
import "./batchPayslip.css";
import Pagelayout from "../components/Pagelayout";
import { useNavigate } from "react-router-dom";

const BatchPayslip = () => {
  const navigate = useNavigate();
  // Sample data array - in real app, this would likely come from props or API
  const payslips = Array(100).fill({
    name: "Jan Batcpayslip for alkhore branch",
    period: {
      startDate: "01/10/2023",
      endDate: "02/09/2023",
    },
  });

  const handleCreateBtn = () => {
    navigate("create");
  };

  return (
    <Pagelayout  handleCreateBtn={handleCreateBtn} pageName={"Batch Payslip"} headerLayout={1}>
      <div className="batch-payslip__container">
        {payslips.map((payslip, index) => (
          <div key={index} className="batch-payslip__card">
            <div className="batch-payslip__left-border"></div>
            <div className="batch-payslip__content">
              <div className="batch-payslip__field">
                <label className="batch-payslip__label">NAME</label>
                <div className="batch-payslip__value">{payslip.name}</div>
              </div>
              <div className="batch-payslip__field">
                <label className="batch-payslip__label">PERIOD</label>
                <div className="batch-payslip__period">
                  <span>{payslip.period.startDate}</span>
                  <span>{payslip.period.endDate}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Pagelayout>
  );
};

export default BatchPayslip;
