import React from "react";
import Pagelayout from "../../components/Pagelayout";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../components/ListPageComponent";



const PayrollLeave = () => {
  const navigate = useNavigate()
  const handleCreateBtn = ( ) =>{
    navigate("create")
  }

  const headers = [
    { key: "name", label: "Name" },
    { key: "leaveType", label: "Leave Type" },
    { key: "paidUnpaid", label: "Paid/Unpaid" },
    { key: "from", label: "From" },
    { key: "to", label: "To" },
    { key: "days", label: "No of Days" },
    { key: "status", label: "Status" },
  ];

  const data = [
    {
      name: "EMp12 - kshanith",
      leaveType: "Sick Leave",
      paidUnpaid: "Paid Leave",
      from: "24/08/2023",
      to: "30/08/2029",
      days: "8 Hours",
      status: "Approved",
    },]
    const visibleColumns = [
      "name",
      "leaveType",
      "paidUnpaid",
      "from",
      "to",
      "days",
      "status",
    ];
    const handleRowClick = (rowData) => {
      console.log("Clicked row data:", rowData);
    };
  return (
    <Pagelayout pageName={"Leave"} headerLayout={1} handleCreateBtn={handleCreateBtn} >
     <CustomTable
          headers={headers}
          data={data}
          visibleColumns={visibleColumns}
          onRowClick={handleRowClick}
          loading={false}
        />

    </Pagelayout>
  );
};

export default PayrollLeave;




