import React from "react";
import Pagelayout from "../../components/Pagelayout";
import CustomTable from "../../components/ListPageComponent";
import { useNavigate } from "react-router-dom";

const EossPayroll = () => {
    const navigate = useNavigate()
  const headers = [
    { key: "date", label: "Date" },
    { key: "name", label: "Name" },
    { key: "employee", label: "Employee" },
    { key: "netEossSettlement", label: "NET EOSS Settlement" },
    { key: "status", label: "Status" },
  ];
  const data = [
    {
      date: "2024-01-01",
      name: "John Doe",
      employee: "1234567890",
      netEossSettlement: "1000",
      status: "Draft",
    },
    {
      date: "2024-01-02",
      name: "Jane Doe",
      employee: "1234567890",
      netEossSettlement: "2000",
      status: "Done",
    },
  ];
  const visibleColumns = [
    "date",
    "name",
    "employee",
    "netEossSettlement",
    "status",
  ];
  const handleRowClick = (rowData) => {
    console.log("Clicked row data:", rowData);
  };
  const handleCreateBtn = () => {
    navigate("create");
  };
  return (
    <Pagelayout
      pageName="EOSS"
      headerLayout={1}
      createBtn={true}
      handleCreateBtn={handleCreateBtn}
    >
      <CustomTable
        headers={headers}
        data={data}
        visibleColumns={visibleColumns}
        onRowClick={handleRowClick}
        loading={false}

      />
    </Pagelayout>
  );
};

export default EossPayroll;
