import { createSlice } from "@reduxjs/toolkit";

export const viewOrdersBillingSlice = createSlice({
  name: "viewOrdersBillingSlice",
  initialState: {
    ordersList: [],
    tableLoading: false,
    cartLoading: false,
    heldOrders: [],
    receiptData: null,
    pageIndex: 0,
    pagination: false,
    posNotesList: undefined,
  },
  reducers: {
    storeOrdersList: (state, action) => {
      state.ordersList = action.payload;
    },
    storeHeldOrders: (state, action) => {
      state.heldOrders = action.payload;
    },
    setTableLoading: (state, action) => {
      state.tableLoading = action.payload;
    },
    setCartLoading: (state, action) => {
      state.cartLoading = action.payload;
    },
    setReceiptData: (state, action) => {
      state.receiptData = action.payload;
    },
    setPageIndexForViewOrder: (state, action) => {
      state.pageIndex = action.payload;
    },
    setPaginationForViewOrder: (state, action) => {
      state.pagination = action.payload;
    },
    listPosNotes: (state, action) => {
      state.posNotesList = action.payload.listNotes;
    },
  },
});

export const {
  storeOrdersList,
  setTableLoading,
  setCartLoading,
  storeHeldOrders,
  setReceiptData,
  setPageIndexForViewOrder,
  setPaginationForViewOrder,
  listPosNotes,
} = viewOrdersBillingSlice.actions;

export default viewOrdersBillingSlice.reducer;
