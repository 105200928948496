import React from 'react'
import Pagelayout from '../../components/Pagelayout'
import { useNavigate } from 'react-router-dom';

const LoanEligibility = () => {
    const navigate = useNavigate();
    // Sample data array - in real app, this would likely come from props or API
    const data = Array(100).fill({
      name: "Loan Eligibility",
      department: "HR",
      minimumSalary: 10000,
      outstandingAmount: 10000,
     
    });
  
    const handleCreateBtn = () => {
      navigate("create");
    };
  
  return (
    <Pagelayout headerLayout={1} pageName={"Loan Eligibility"} handleCreateBtn={handleCreateBtn}>
      <div className="batch-payslip__container">
        {data.map((data, index) => (
          <div key={index} className="batch-payslip__card">
            <div className="batch-payslip__left-border"></div>
            <div className="batch-payslip__content">
              <div className="batch-payslip__field">
                <label className="batch-payslip__label">DEPARTMENT</label>
                <div className="batch-payslip__value">{data.department}</div>
              </div>
              <div className="batch-payslip__field">
                <label className="batch-payslip__label">MINIMUM SALARY</label>
                <div className="batch-payslip__value">{data.minimumSalary}</div>
              </div>
              <div className="batch-payslip__field">
                <label className="batch-payslip__label">OUTSTANDING AMOUNT</label>
                <div className="batch-payslip__value">{data.outstandingAmount}</div>
              </div>
              
            </div>
          </div>
        ))}
      </div>
    </Pagelayout>
  )
}

export default LoanEligibility