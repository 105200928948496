import React,{useState} from 'react'
import {
  Autocomplete,
  TextField,
  Button,
  Box,
  Paper,
  Typography,
  Stack,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Trash2 } from 'lucide-react';


  import "./payrollLeaveStyles.css";
import Pagelayout from '../../components/Pagelayout';
import { useNavigate } from 'react-router-dom';
const CreatePayrollLeave = () => {
  const [open, setOpen] = useState(false);
  const handleApplyBtn = ()=>{
    setOpen(true)
  }
  const handleClose = () => setOpen(false);
    const navigate = useNavigate()
    const handleBackBtn = ()=>{
        navigate ("/userdashboard/payroll/payrollLeave/")

    }
  return (
    <Pagelayout
      pageName={"Leave"}
      headerLayout={2}
      handleBack={handleBackBtn}
      actionBtn={false}
      
    >
      <Paper className="payroll-leave-cmnt">
        <Box p={2} className="payroll-leave-cmnt__form">
          {/* Action Buttons at the Top */}
          <Stack direction="row" spacing={2} mb={2}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className="payroll-leave-cmnt__apply-btn"
              onClick={handleApplyBtn}
            >
              Apply
            </Button>
            <Button
              variant="contained"
              color="error"
              size="small"
              className="payroll-leave-cmnt__cancel-btn"
            >
              Cancel
            </Button>
          </Stack>
          <Divider />

          <Stack spacing={3} mt={2}>
            {/* First Row */}
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <Autocomplete
                fullWidth
                size="small"
                options={[{ label: "Emp12 - Vaisak", id: 1 }]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Employee"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />

              <Autocomplete
                fullWidth
                size="small"
                options={[{ label: "Sick Leave", id: 1 }]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Leave Type"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />

              <Autocomplete
                fullWidth
                size="small"
                options={[
                  { label: "Paid", id: 1 },
                  { label: "Unpaid", id: 2 },
                ]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Paid/Unpaid"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Stack>

            {/* Second Row */}
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              {/* From Date */}
              <TextField
                fullWidth
                size="small"
                type="date"
                label="From Date"
                InputLabelProps={{ shrink: true }}
              />

              {/* To Date */}
              <TextField
                fullWidth
                size="small"
                type="date"
                label="To Date"
                InputLabelProps={{ shrink: true }}
              />

              <TextField
                fullWidth
                size="small"
                label="No of Days"
                InputLabelProps={{ shrink: true }}
              />
            </Stack>

            {/* Notes Field */}
            <TextField
              fullWidth
              multiline
              rows={4}
              size="small"
              label="Note (Optional)"
              InputLabelProps={{ shrink: true }}
            />

            {/* Leave Summary Card */}
            <Paper variant="outlined" className="payroll-leave-cmnt__summary">
              <Box p={2}>
                <Typography variant="subtitle1" fontWeight="bold" mb={2}>
                  Date Request Includes
                </Typography>
                <Stack spacing={1}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography>15/01/2024</Typography>
                    <Typography color="primary">Full Day</Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography>16/01/2024</Typography>
                    <Typography color="primary">Full Day</Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography>17/01/2024</Typography>
                    <Typography color="warning.main">Half</Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography>18/01/2024</Typography>
                    <Typography color="error">Company not working</Typography>
                  </Box>
                </Stack>
                <Divider sx={{ my: 2 }} />
                <Typography variant="subtitle1" fontWeight="bold">
                  12 Days of Sick Leave
                </Typography>
                <Typography color="primary" variant="caption">
                  5.5 days remaining
                </Typography>
              </Box>
            </Paper>
          </Stack>
        </Box>
      </Paper>

      <LeaveTypeDialog open={open} onClose={handleClose}/>
    </Pagelayout>
  )
}

export default CreatePayrollLeave

const leaveTypes = [
  'Annual Leave',
  'Sick Leave',
  'Maternity Leave',
  'Paternity Leave',
  'Study Leave',
  'Personal Leave'
];

const leaveOptions = [
  { label: 'Paid', value: 'Paid' },
  { label: 'Unpaid', value: 'Unpaid' },
];




export const LeaveTypeDialog = ({ open, onClose }) => {
  const [leaveType, setLeaveType] = useState(null);
  const [noOfDays, setNoOfDays] = useState('');
  const [paidStatus, setPaidStatus] = useState(null);
  const [leaveList, setLeaveList] = useState([]);
  
  // Error states
  const [errors, setErrors] = useState({
    leaveType: '',
    noOfDays: '',
    paidStatus: ''
  });

  const validateForm = () => {
    const newErrors = {
      leaveType: !leaveType ? 'Please select a leave type' : '',
      noOfDays: !noOfDays ? 'Please enter number of days' : '',
      paidStatus: !paidStatus ? 'Please select paid/unpaid status' : ''
    };

    // Additional validation for number-only input
    if (noOfDays && !/^\d+$/.test(noOfDays)) {
      newErrors.noOfDays = 'Please enter numbers only';
    }

    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error !== '');
  };

  const handleAddLeave = () => {
    if (validateForm()) {
      setLeaveList([
        ...leaveList,
        { 
          leaveType, 
          noOfDays: parseInt(noOfDays), 
          paidStatus: paidStatus.value 
        },
      ]);
      setLeaveType(null);
      setNoOfDays('');
      setPaidStatus(null);
      // Clear all errors
      setErrors({
        leaveType: '',
        noOfDays: '',
        paidStatus: ''
      });
    }
  };

  const handleDelete = (index) => {
    const updatedList = leaveList.filter((_, i) => i !== index);
    setLeaveList(updatedList);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Add Leave Type</DialogTitle>

      <Divider/>

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          padding: "16px",
        }}
      >
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={4}>
            <Autocomplete
              value={leaveType}
              onChange={(_, newValue) => {
                setLeaveType(newValue);
                setErrors(prev => ({ ...prev, leaveType: '' }));
              }}
              options={leaveTypes}
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  label="Leave Type" 
                  size="small" 
                  fullWidth 
                  InputLabelProps={{shrink:true}}
                  error={!!errors.leaveType}
                  helperText={errors.leaveType}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              type="text"
              label="No. of Days"
              value={noOfDays}
              onChange={(e) => {
                setNoOfDays(e.target.value);
                setErrors(prev => ({ ...prev, noOfDays: '' }));
              }}
              size="small"
              fullWidth
              InputLabelProps={{shrink:true}}
              error={!!errors.noOfDays}
              helperText={errors.noOfDays}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Autocomplete
              value={paidStatus}
              onChange={(_, newValue) => {
                setPaidStatus(newValue);
                setErrors(prev => ({ ...prev, paidStatus: '' }));
              }}
              options={leaveOptions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  label="Paid/Unpaid" 
                  size="small" 
                  fullWidth 
                  InputLabelProps={{shrink:true}}
                  error={!!errors.paidStatus}
                  helperText={errors.paidStatus}
                />
              )}
            />
          </Grid>
        </Grid>

        <TableContainer
          component={Paper}
          elevation={0} 
          sx={{
            maxHeight: "300px",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#555",
            },
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Leave Type</TableCell>
                <TableCell>No of Days</TableCell>
                <TableCell>Paid/Unpaid</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leaveList.map((leave, index) => (
                <TableRow key={index}>
                  <TableCell>{leave.leaveType}</TableCell>
                  <TableCell>{leave.noOfDays}</TableCell>
                  <TableCell>{leave.paidStatus}</TableCell>
                  <TableCell align="center">
                    <IconButton size="small" onClick={() => handleDelete(index)}>
                      <Trash2 color='red' size={15}/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions sx={{ padding: "16px", display:'flex', justifyContent:"center" }}>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button onClick={handleAddLeave} variant="contained" color="primary">
          Add Leave
        </Button>
      </DialogActions>
    </Dialog>
  );
};
