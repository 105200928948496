import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {useSelector } from 'react-redux';
import store from '../../../../../Redux/store';
import { get_vendor_list_row_data_list } from '../Slice/vendorsListSlice';
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import { VendorsFilterAPICall, viewVendorListAPICall } from '../API/vendorListAPICall';
import { useState } from 'react';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import CustomPagination from '../../../../Single Components/CustomPagination';
import axios from 'axios';
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';
import ReactToPrint from 'react-to-print';
import ExcelJS from "exceljs";

export const Vendors = () => {
  

  let navigateTo=useNavigate()
  const tableListData=useSelector((state)=>state.vendorsListSlice.apiResList);
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const [isLoading,setIsLoading]=useState(false)
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const totalPages = tableListData?.pages;
  const [filteredDatas, setFilteredData] = useState({});
  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };
  const formatFilterInfo = (filters) => {
    const parts = [];

    if (filters?.toDate && filters.fromDate) {
      parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
    }
    if (filters?.branchList) {
      parts.push(
        `Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`
      );
    }
    if (filters?.location) {
      parts.push(`location: ${filters?.location?.name}`);
    }
    if (filters?.toLocation) {
      parts.push(`To Location: ${filters?.toLocation?.name}`);
    }
    if (filters?.status) {
      parts.push(`status: ${filters?.status}`);
    }
    if (filters?.createdBy) {
      parts.push(`createdBy: ${filters?.createdBy}`);
    }
    return parts; // Return the array directly
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Vendors");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["Vendors"]);
    titleRow.font = { size: 15, bold: true };
    sheet.mergeCells("A1:H1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add additional header information
    const filterParts = formatFilterInfo(filteredDatas) || [];

    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 11, bold: true };
        sheet.mergeCells(`A${index + 2}:H${index + 2}`);
        row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
      });
    }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow(["No", "Name", "Phone", "Email", "Country"]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 12,
      bold: true,
      color: { argb: "FF000000" }, // White font color
    };

    headerRow.eachCell((cell, colNumber) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "No", key: "No", width: 15 },
      { header: "Name", key: "name", width: 20 },
      { header: "Phone", key: "mobileNo", width: 25 },
      { header: "Email", key: "email", width: 25 },
      { header: "Vendors", key: "country", width: 25 },
    ];

    tableListData?.list?.forEach((item, index) => {
      const row = sheet.addRow({
        No: index + 1,
        name: item.name,
        mobileNo: item.mobileNo,
        email: item.email || "Nil",
        country: item?.country,
      });

      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };

      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          //   top: { style: "thin", color: { argb: "FF000000" } },
          //   left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FFedf2f9" } },
          //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber >= 1) {
          // Right align all columns except the first
          cell.alignment = { horizontal: "center" };
        }
      });
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Vendors.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  const handleClickRow=(row)=>{
    store.dispatch( setFilterActive(window.location.pathname))
    store.dispatch(get_vendor_list_row_data_list({vendorResRowData:row}))
    navigateTo("/userdashboard/accounts/vendor/Createvendors")
  }
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };

  useEffect(()=>{
    store.dispatch(get_vendor_list_row_data_list({vendorResRowData:undefined}))
    // viewVendorListAPICall(setIsLoading)
  },[])

  useEffect(() => {
   
    if (FormDataInfo.length !== 0) {
    let loadData=window.location.pathname===filtereDataOptions?.filterActive
    !loadData && VendorsFilterAPICall({
    branchList: FormDataInfo.branchList,
    status: FormDataInfo.status.length!==0?(FormDataInfo.status[0]==="ACTIVE"?true:false):null,
    search: FormDataInfo.search,
    index: currentPage-1,
  }, setIsLoading);
}

}, [FormDataInfo,currentPage
  ])
  return (
    <div className='global-page-parent-container'>
      {/* <p className="breadcrumb">Accounts &gt; Vendors &gt; Vendors List</p> */}

      <div className="global-white-bg-container">
      <div className="justify-space-between"
          style={{ gap: "0 12px",  display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}
        >
           {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
                 <h3>Branch name: 
                  {selectedBranch?.storeCode||filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                  -{selectedBranch?.branchName||filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>
              </div>
            )} 
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap:'9px',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
          <div style={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="Share">
                <IconButton>
                  <i
                    class="bi bi-share"
                    style={{
                      backgroundColor: "#9797e9",
                      color: "white",
                      fontSize: "medium",
                      padding: "8px",
                      borderRadius: "2px",
                    }}
                  ></i>
                </IconButton>
              </Tooltip>

              <ReactToPrint
                trigger={() => (
                  <IconButton
                    className="printer-div"
                    style={{ padding: "1px" }}
                  >
                    <i
                      style={{
                        color: "white",
                        backgroundColor: " #d787e7",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                      class="bi bi-printer"
                    ></i>
                  </IconButton>
                )}
                // content={() => paymentsPrintNew}
              />
              <Tooltip title="Download">
                <IconButton onClick={downloadList}>
                  <i
                    class="bi bi-arrow-down-circle"
                    style={{
                      backgroundColor: "rgb(255 118 133 / 69%)",
                      color: "white",
                      fontSize: "medium",
                      padding: "8px",
                      borderRadius: "2px",
                    }}
                  ></i>
                </IconButton>
              </Tooltip>
              <CategorySearchandFilter
                statusOptions={["ACTIVE", "INACTIVE"]}
                onData={handleDataFromChild}
                isStatus={true}
                isDate={false}
                onBranchSelect={handleBranchSelect}
                onFilterChange={handleFilterChange}
              />
              <button
                className="create-button-blue"
                onClick={() =>
                  navigateTo("/userdashboard/accounts/vendor/Createvendors")
                }
              >
                Create
              </button>
            </div>
            </div>
        </div>
        <h3>vendors</h3>
        <div className="justify-center" style={{ justifyContent: "flex-end" }}>
 
                        <CustomPagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                            setFormDataInfo={setFormDataInfo}
                        />
                    </div>
        <div className="create-button-blue-container">
          {/* <button className='create-button-blue' 
           onClick={()=>navigateTo("/userdashboard/accounts/vendor/Createvendors")}>
            Create</button> */}
        </div>

        <div className="global-table-container">
          <table className="global-table">
            <thead>
              <tr>
              <th>No</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Country</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={5}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : tableListData?.list !== undefined || tableListData?.list?.length === 0 ? (
                tableListData?.list?.slice(0)?.reverse().map((r, i) => (
                  <tr key={i} onClick={() => handleClickRow(r)} style={{ cursor: "pointer" }}>
                    <td>{i + 1}</td>
                    <td>{r?.name}</td>
                    <td>{r?.mobileNo}</td>
                    <td>{r?.email || "-NIL-"}</td>
                    <td>{r?.country==="undefined"?"-NIL-":r?.country}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6}>No data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
