import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import {
  BiCheckCircle,
  BiTime,
  BiCheckSquare,
  BiX,
  BiTask,
  BiPencil,
  BiNews,
} from "react-icons/bi";

const StatusLabel = styled(Box)(({ status }) => ({
  width: "10em",
  display: "inline-flex",
  alignItems: "center",
  padding: "6px 12px",
  borderRadius: "4px",
  gap: "6px",
  transition: "all 0.3s ease",
  cursor: "default",
  "&:hover": {
    // transform: "translateY(-2px)",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  ...(status === "complete" && {
    backgroundColor: "rgba(46, 204, 113, 0.1)",
    color: "#27ae60",
    border: "1px solid #27ae60",
  }),
  ...(status === "pending" && {
    backgroundColor: "rgba(241, 196, 15, 0.1)",
    color: "#f39c12",
    border: "1px solid #f39c12",
  }),
  ...(status === "approved" && {
    backgroundColor: "rgba(52, 152, 219, 0.1)",
    color: "#2980b9",
    border: "1px solid #2980b9",
  }),
  ...(status === "rejected" && {
    backgroundColor: "rgba(231, 76, 60, 0.1)",
    color: "#c0392b",
    border: "1px solid #c0392b",
  }),
  ...(status === "done" && {
    backgroundColor: "rgba(155, 89, 182, 0.1)",
    color: "#8e44ad",
    border: "1px solid #8e44ad",
  }),
  ...(status === "draft" && {
    backgroundColor: "rgba(149, 165, 166, 0.1)",
    color: "#7f8c8d",
    border: "1px solid #7f8c8d",
  }),
  ...(status === "post" && {
    backgroundColor: "rgba(230, 126, 34, 0.1)",
    color: "#d35400",
    border: "1px solid #d35400",
  }),

  ...(status === "running" && {
    backgroundColor: "rgba(46, 204, 113, 0.1)",
    color: "#27ae60",
    border: "1px solid #27ae60",
  }),

  ...(status === "expired" && {
    backgroundColor: "rgba(255, 165, 0, 0.1)",
    color: "#ffa500",
    border: "1px solid #ffa500",
  }),

  ...(status === "cancel" && {
    backgroundColor: "rgba(231, 76, 60, 0.1)",
    color: "#e74c5e",
    border: "1px solid #e74c5e",
  }),
  ...(status === "active" && {
    backgroundColor: "rgba(46, 204, 113, 0.1)",
    color: "#27ae60",
    border: "1px solid #27ae60",
  }),

  ...(![
    "complete",
    "pending",
    "approved",
    "rejected",
    "done",
    "draft",
    "post",
    "running",
    "expired",
    "cancel",
    "active",
  ].includes(status) && {
    backgroundColor: "rgba(189, 195, 199, 0.1)",
    color: "#95a5a6",
    border: "1px solid #95a5a6",
  }),
}));

const getStatusIcon = (status) => {
  const iconProps = { size: 20 };
  switch (status) {
    case "complete":
      return <BiCheckCircle {...iconProps} />;
    case "pending":
      return <BiTime {...iconProps} />;
    case "approved":
      return <BiCheckSquare {...iconProps} />;
    case "rejected":
      return <BiX {...iconProps} />;
    case "done":
      return <BiTask {...iconProps} />;
    case "draft":
      return <BiPencil {...iconProps} />;
    case "post":
      return <BiNews {...iconProps} />;
    case "running":
      return <BiCheckCircle {...iconProps} />;
    case "expired":
      return <BiTime {...iconProps} />;
    case "cancel":
      return <BiX {...iconProps} />;
    case "active":
      return <BiCheckCircle {...iconProps} />;
    default:
      return null;
  }
};

const TableStatusLabel = ({ status = "pending" }) => {
  const normalizedStatus = status.toLowerCase();
  const statusText =
    normalizedStatus.charAt(0).toUpperCase() + normalizedStatus.slice(1);

  return (
    <StatusLabel
      status={normalizedStatus}
      role="status"
      aria-label={`Status: ${statusText}`}
    >
      {getStatusIcon(normalizedStatus)}
      <Typography
        variant="body2"
        component="span"
        sx={{
          fontWeight: 600,
          fontSize: "0.875rem",
          lineHeight: "1.2",
          letterSpacing: "0.1px",
        }}
      >
        {statusText}
      </Typography>
    </StatusLabel>
  );
};

export default TableStatusLabel;
