import React from "react";
import Pagelayout from "../../components/Pagelayout";

const data = {
  name: "EMP126-Vaisak",
  checkIn: "12/4/2023 8:00:00",
  checkOut: "12/4/2023 18:00:00",
  duration: "10:00:00 AM",
};

const array = Array(100).fill(data);

const AttendancePayroll = () => {
  return (
    <Pagelayout
      pageName={"Attendance Payroll"}
      headerLayout={1}
      filter={false}
      searchBox={false}
      createBtn={false}
      pagination={false}
    >
      <div className="batch-payslip__container">
        {array.map((item, index) => (
          <div className="payroll-unq-card">
            <div className="payroll-unq-card__header">
              <h3 className="payroll-unq-card__title">{item.name}</h3>
            </div>

            <div className="payroll-unq-card__content">
              <div className="payroll-unq-card__time-group">
                <div className="payroll-unq-card__check">
                  <span className="payroll-unq-card__label">Check In</span>
                  <span className="payroll-unq-card__date">{item.checkIn}</span>
                </div>
                <div className="payroll-unq-card__check">
                  <span className="payroll-unq-card__label">Check Out</span>
                  <span className="payroll-unq-card__date">
                    {item.checkOut}
                  </span>
                </div>
              </div>

              <div className="payroll-unq-card__duration">
                <span className="payroll-unq-card__label">Duration(Hours)</span>
                <span className="payroll-unq-card__time">{item.duration}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Pagelayout>
  );
};

export default AttendancePayroll;
