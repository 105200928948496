import React from "react";
import Pagelayout from "../../components/Pagelayout";
import CustomTable from "../../components/ListPageComponent";
import { useNavigate } from "react-router-dom";
const OvertimePayroll = () => {
  const navigate = useNavigate();
  const headers = [
    { label: "Employee", key: "employee" },
    { label: "Date", key: "date" },
    { label: "Overtime", key: "overtime" },
    { label: "Duration", key: "duration" },
    { label: "Unit Type", key: "unitType" },
    { label: "Amount", key: "amount" },
    { label: "Overtime Amount", key: "overtimeAmount" },
  ];
  const visibleColumns = [
    "employee",
    "date",
    "overtime",
    "duration",
    "unitType",
    "amount",
    "overtimeAmount",
  ];
  const data = [
    {
      employee: "John Doe",
      date: "2021-01-01",
      overtime: "Overtime 1",
      duration: "2 hours",
      unitType: "Hour",
      amount: "100",
      overtimeAmount: "200",
    },
    {
      employee: "Jane Doe",
      date: "2021-01-02",
      overtime: "Overtime 2",
      duration: "3 hours",
      unitType: "Hour",
      amount: "150",
      overtimeAmount: "300",
    },
  ];
  const handleRowClick = (rowData) => {
    console.log("Clicked row data:", rowData);
  };
  const handleCreateBtn = () => {
    navigate("create");
  };



  return (
    <Pagelayout
      pageName="Overtime"
      headerLayout={1}
      createBtn={true}
      handleCreateBtn={handleCreateBtn}
    >
      <CustomTable
        headers={headers}
        data={data}
        visibleColumns={visibleColumns}
        onRowClick={handleRowClick}
        loading={false}
      />
    </Pagelayout>
  );
};

export default OvertimePayroll;
