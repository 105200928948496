import React from "react";
import Pagelayout from "../components/Pagelayout";
import CustomTable from "../components/ListPageComponent";
import { useNavigate } from "react-router-dom";

const ContractPayslip = () => {
  const headers = [
    { key: "name", label: "Name" },
    { key: "department", label: "Department" },
    { key: "designation", label: "Designation" },
    { key: "contractType", label: "Contract Type" },
    { key: "sponsor", label: "Sponsor" },
    { key: "branch", label: "Branch" },
    { key: "from", label: "From" },
    { key: "to", label: "To" },
    { key: "workingSche", label: "Working Sche" },
    { key: "status", label: "Status" },
  ];
  const data = [
    {
      name: "EMp12 - VAISHAK",
      department: "IT",
      designation: "Developer",
      contractType: "Full Time",
      sponsor: "VAISHAK",
      branch: "ALKHORE",
      from: "24/08/2023",
      to: "30/08/2029",
      workingSche: "9:00 AM - 5:00 PM",
      status: "Running",
    },
    {
      name: "EMp12 - VAISHAK",
      department: "IT",
      designation: "Developer",
      contractType: "Full Time",
      sponsor: "VAISHAK",
      branch: "ALKHORE",
      from: "24/08/2023",
      to: "30/08/2029",
      workingSche: "9:00 AM - 5:00 PM",
      status: "Expired",
    },
    {
      name: "EMp12 - VAISHAK",
      department: "IT",
      designation: "Developer",
      contractType: "Full Time",
      sponsor: "VAISHAK",
      branch: "ALKHORE",
      from: "24/08/2023",
      to: "30/08/2029",
      workingSche: "9:00 AM - 5:00 PM",
      status: "Cancel",
    },
    {
      name: "EMp12 - VAISHAK",
      department: "IT",
      designation: "Developer",
      contractType: "Full Time",
      sponsor: "VAISHAK",
      branch: "ALKHORE",
      from: "24/08/2023",
      to: "30/08/2029",
      workingSche: "9:00 AM - 5:00 PM",
      status: "Complete",
    },
    {
      name: "EMp12 - VAISHAK",
      department: "IT",
      designation: "Developer",
      contractType: "Full Time",
      sponsor: "VAISHAK",
      branch: "ALKHORE",
      from: "24/08/2023",
      to: "30/08/2029",
      workingSche: "9:00 AM - 5:00 PM",
      status: "Active",
    },
  ];
  const visibleColumns = [
    "name",
    "department",
    "designation",
    "contractType",
    "sponsor",
    "branch",
    "from",
    "to",
    "workingSche",
    "status",
  ];
  const navigate = useNavigate();
  const handleRowClick = (rowData) => {
    console.log("Clicked row data:", rowData);
  };
  const handleCreateBtn = () => {
    navigate("create");
  };
  
  

  return (
    <Pagelayout
      pageName={"Contract Payslip"}
      headerLayout={1}
      handleCreateBtn={handleCreateBtn}
 
    >
      <CustomTable
        headers={headers}
        data={data}
        visibleColumns={visibleColumns}
        handleRowClick={handleRowClick}
      />
    </Pagelayout>
  );
};

export default ContractPayslip;
