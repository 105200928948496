import React, { useEffect, useRef, useState } from "react";
import { IconButton, TextField, Tooltip } from "@mui/material";
import ReactToPrint from "react-to-print";
import { today } from "../../../../../Js/Date";
import "./ProfitAndLossStyle.css";
import { useSelector } from "react-redux";
import { profitAndLossAPIcall } from "./TrailBalanceAPI";
import { viewAllCurrencyUpdated } from "../../../../../API/Settings/Currency/CurrencyAPI";
import { filterObjFromList } from "../../../../../Js/generalFunctions";
import { LoadingForm } from "../../../../Single Components/LoadingForm";
import PrintComponent from "../../../../print/report/PrintComponent";
import ExcelJS from "exceljs"

export const ProfitAndLoss = () => {
  let componentRef = useRef();
  const decimalPosition = localStorage.getItem("decimalPosition");
  const userInfo = useSelector((state) => state.loginResponseSlice.value);

  const [symbol, setSymbol] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState({ start: today, end: today });
  const [sortedData, setSortedData] = useState({
    incomeArray: [],
    expenseArray: [],
  });

  const handleChangeDate = (value, name) => {
    setDates((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const configurationList = useSelector(
    (state) => state.glConfigurationSlice.apiResList
  );

  useEffect(() => {
    if(configurationList?.yearEndDate && configurationList?.yearStartDate)
    setDates((prev)=>({...prev,start:configurationList?.yearStartDate,end:configurationList?.yearEndDate}))
  }, [configurationList])
  

  const profitAndLoss = useSelector(
    (state) => state.trailbalance.profitAndLossData
  );

  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Profit & Loss');
    worksheet.properties.defaultRowHeight = 20;

    const titleRow = worksheet.addRow(["Profit & Loss Account"]);
    titleRow.font = { size: 14, bold: true };
    worksheet.mergeCells("A1:B1");
    titleRow.getCell(1).alignment = {
        vertical: "middle",
        horizontal: "center",
    };

    // Add row for date range
    const fromDateRow = worksheet.addRow([`As Of : ${dates?.start} to ${dates?.end}`]);
    fromDateRow.font = { size: 12, bold: true };
    worksheet.mergeCells('A2:B2');
    fromDateRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };

    worksheet.addRow([]);

    // Set the width for columns
    worksheet.getColumn(1).width = 50;
    worksheet.getColumn(2).width = 30;

    // Define the background color styles
    const lightBlueStyle = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFe3e8f5' }  // Added FF prefix for full opacity
    };
    const darkBlueStyle = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF072a85' }  // Added FF prefix for full opacity
    };

    // Define the font color styles
    const whiteFontStyle = {
        color: { argb: 'FFFFFFFF' }  // White color with full opacity
    };
    const darkBlueFontStyle = {
        color: { argb: 'FF072a85' }  // Dark blue color with full opacity
    };

    // Function to add row with specific style
    const addStyledRow = (data, fillStyle, fontStyle) => {
        const row = worksheet.addRow(data);
        row.eachCell((cell, colNumber) => {
            if (fillStyle) cell.fill = fillStyle;
            if (fontStyle) cell.font = fontStyle;
            if (colNumber > 1) {
                cell.alignment = { horizontal: 'right', vertical: 'middle' };  // Align text to the end
            }
        });
    };

    sortedData.incomeArray.forEach((res) => {
        if (res._id !== 'Gross Profit') {
            const style = ['Income', 'Cost of revenue'].includes(res._id) ? lightBlueStyle : null;
            addStyledRow([res._id, res.totalClosingBalance.toFixed(decimalPosition)], style, darkBlueFontStyle);
            if (res.accounts && res.accounts.length > 0) {
                res.accounts.forEach((subData) => {
                    addStyledRow([subData.accountName, subData.closingBalance.toFixed(decimalPosition)], null, darkBlueFontStyle);
                });
            }
        }
    });

    addStyledRow(['Gross Profit', sortedData.incomeArray[sortedData.incomeArray.length - 1]?.grossProfitAmount?.toFixed(decimalPosition)], darkBlueStyle, whiteFontStyle);

    sortedData.expenseArray.forEach((res) => {
        if (res._id !== 'Profit & Loss Account') {
            const style = ['Other Income', 'Expenses', 'Depreciations', 'Off -balance sheet'].includes(res._id) ? lightBlueStyle : null;
            addStyledRow([res._id, res.totalClosingBalance.toFixed(decimalPosition)], style, darkBlueFontStyle);
            if (res.accounts && res.accounts.length > 0) {
                res.accounts.forEach((subData) => {
                    addStyledRow([subData.accountName, subData.closingBalance.toFixed(decimalPosition)], null, darkBlueFontStyle);
                });
            }
        }
    });

    addStyledRow(['Profit & Loss Account', sortedData.expenseArray[sortedData.expenseArray.length - 1]?.profitAndLossAccountAmount?.toFixed(decimalPosition)], darkBlueStyle, whiteFontStyle);

    workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "Profit and Loss.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
    });
};

  useEffect(() => {
    if (profitAndLoss) {
      const data = profitAndLoss;

      const sortAndSummarizeData = () => {
        let incomeArray = [];
        let expenseArray = [];
        let incomeSum = 0;
        let expenseSum = 0;
        let costOfRevenue = 0;

        data.forEach((item) => {
          if (item._id === "Income") {
            incomeArray.unshift(item);
            incomeSum += item.totalClosingBalance;
          } else if (item._id === "Cost of revenue") {
            incomeArray.push(item);
            costOfRevenue += item.totalClosingBalance;
          } else if (item._id === "Other Income") {
            expenseArray.unshift(item);
            expenseSum += item.totalClosingBalance;
          } else {
            expenseArray.push(item);
            expenseSum += item.totalClosingBalance;
          }
        });

        // Calculate Gross Profit
        const grossProfit = incomeSum - costOfRevenue;

        // Calculate Net Income
        const netIncome =
          grossProfit +
          (data.find((item) => item._id === "Other Income")
            ?.totalClosingBalance || 0) -
          expenseSum -
          (data.find((item) => item._id === "Depriciations")
            ?.totalClosingBalance || 0) -
          (data.find((item) => item._id === "Off -balance sheet")
            ?.totalClosingBalance || 0);

        // Adding gross profit
        incomeArray.push({
          _id: "Gross Profit",
          grossProfitAmount: grossProfit,
        });

        // Adding profitAndLossAccount
        expenseArray.push({
          _id: "Profit & Loss Account",
          profitAndLossAccountAmount: netIncome,
        });

        return { incomeArray, expenseArray };
      };

      const sortedData = sortAndSummarizeData();
      setSortedData(sortedData);
    }
  }, [profitAndLoss]);

  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  useEffect(() => {
    const startDate = dates.start === "" ? "" : `${dates.start} 00:00:00`;
    const endDate = dates.end === "" ? today : `${dates.end} 23:59:59`;
    setLoading(true);
    profitAndLossAPIcall(
      {
        startDate: startDate,
        endDate: endDate,
      },
      setLoading
    );
  }, [dates]);

  const [showIncomeRows, setShowIncomeRow] = useState({});
  const [showExpenseRows, setShowExpenseRow] = useState({});

  const handleClickIncomeRow = (i) => {
    setShowIncomeRow((prev) => {
      return { ...prev, [i]: !showIncomeRows[i] };
    });
  };

  const handleClickExpenseRow = (i) => {
    setShowExpenseRow((prev) => {
      return { ...prev, [i]: !showExpenseRows[i] };
    });
  };
  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
  }, []);

  return (
    <div className="profit-and-loss-container">
      <div className="pl-wrapper">
        <div className="pl-header-container">
          <div className="balance-sheet-action-btns">
            <div>
              <Tooltip title="Share">
                <IconButton>
                  <i
                    className="bi bi-share"
                    style={{
                      backgroundColor: "#9797e9",
                      color: "white",
                      fontSize: "medium",
                      padding: "8px",
                      borderRadius: "2px",
                    }}
                  ></i>
                </IconButton>
              </Tooltip>
            </div>
            <div>
              <Tooltip title="Print">
                <div>
                <ReactToPrint
                trigger={() => (
                  <IconButton
                    className="printer-div"
                    style={{ padding: "1px" }}
                  >
                    <i
                      style={{
                        color: "white",
                        backgroundColor: " #d787e7",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                      className="bi bi-printer"
                    ></i>
                  </IconButton>
                )}
                content={() => componentRef}
              />
                </div>
              </Tooltip>
            </div>
            <div>
              <Tooltip title="Download">
                <IconButton onClick={downloadList}>
                  <i
                    className="bi bi-arrow-down-circle"
                    style={{
                      backgroundColor: "rgb(255 118 133 / 69%)",
                      color: "white",
                      fontSize: "medium",
                      padding: "8px",
                      borderRadius: "2px",
                    }}
                  ></i>
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className="pl-date-picker-container">
            <div>
              <TextField
                type="date"
                label="From"
                defaultValue={today}
                id="outlined-size-small"
                size="small"
                value={dates?.start}
                onChange={(e) => handleChangeDate(e.target.value, "start")}
              />
            </div>
            <div>
              <TextField
                type="date"
                label="To"
                defaultValue={today}
                id="outlined-size-small"
                size="small"
                value={dates?.end}
                onChange={(e) => handleChangeDate(e.target.value, "end")}
              />
            </div>
          </div>
        </div>
        <div className="pl-body-container">
          <div className="pl-body-header">
            <span>Profit & Loss Account</span>
          </div>
          <div className="pl-table-container">
            {sortedData.incomeArray.map((res, i) => (
              <React.Fragment key={i}>
                {res?._id !== "Gross Profit" && (
                  <div
                    className="pl-normal-td"
                    onClick={() => handleClickIncomeRow(i)}
                  >
                    <span className="pl-account-name">
                      {" "}
                      {res._id !== "Income" ? "Less :" : ""}&nbsp;
                      {res?._id}
                    </span>
                    <span className="pl-account-amount">
                      {symbol}&nbsp;
                      {res.totalClosingBalance.toFixed(decimalPosition)}
                    </span>
                  </div>
                )}
                {showIncomeRows[i] &&
                  res.accounts.length > 0 &&
                  res.accounts.map((subData, j) => (
                    <div key={j} className="pl-details-data-container">
                      <div className="pl-detail-item">
                        <div>{subData?.accountName}</div>
                        <div>
                          {" "}
                          {symbol}&nbsp;
                          {subData?.closingBalance?.toFixed(decimalPosition)}
                        </div>
                      </div>
                    </div>
                  ))}
              </React.Fragment>
            ))}
            <div className="pl-normal-td pl-total-container">
              <span className="pl-account-name">Gross Profit</span>
              <span className="pl-account-amount">
                {symbol}&nbsp;
                {sortedData.incomeArray[
                  sortedData.incomeArray.length - 1
                ]?.grossProfitAmount?.toFixed(decimalPosition)}
              </span>
            </div>
            {sortedData.expenseArray.map((res, i) => (
              <React.Fragment key={i}>
                {res?._id !== "Profit & Loss Account" && (
                  <div
                    className="pl-normal-td"
                    onClick={() => handleClickExpenseRow(i)}
                  >
                    <span className="pl-account-name">
                      {res._id !== "Other Income" ? "Less :" : ""}&nbsp;
                      {res._id}
                    </span>
                    <span className="pl-account-amount">
                      {symbol}&nbsp;
                      {res.totalClosingBalance.toFixed(decimalPosition)}
                    </span>
                  </div>
                )}
                {showExpenseRows[i] &&
                  res.accounts.length > 0 &&
                  res.accounts.map((subData, j) => (
                    <div key={j} className="pl-details-data-container">
                      <div className="pl-detail-item">
                        <div>{subData.accountName}</div>
                        <div>
                          {" "}
                          {symbol}&nbsp;{symbol}&nbsp;
                          {subData.closingBalance.toFixed(decimalPosition)}
                        </div>
                      </div>
                    </div>
                  ))}
              </React.Fragment>
            ))}
            <div className="pl-normal-td pl-total-container">
              <span className="pl-account-name">Profit & Loss Account</span>
              <span className="pl-account-amount">
                {sortedData.expenseArray[
                  sortedData.expenseArray.length - 1
                ]?.profitAndLossAccountAmount?.toFixed(decimalPosition)}
              </span>
            </div>
          </div>

          {/* print view  */}
          {/* <>
            <div className="pl-print-view-container">
              <div
                ref={(el) => (componentRef = el)}
                className="print-container-trail-balance"
                style={{ "@media print": { "@page": { size: "landscape" } } }}
              >
                <div className="trailbalnce-print-header">
                  <div className="company-logo-container">
                    <img
                      height={100}
                      width={100}
                      src="http://placehold.it/100x100"
                      alt=""
                    />
                  </div>
                  <div className="trail-balance-print-title">
                    <span>Balance Sheet</span>
                    <span style={{ fontSize: "13px" }}>
                      {" "}
                      As Off {dates?.end?.split("-").reverse().join("-")}
                    </span>
                  </div>
                  <div className="company-details-container">
                    <span>{userInfo?.data?.profile?.companyName} </span>
                    <span>{userInfo?.data?.profile?.companyAddress} </span>
                    <span>{userInfo?.data?.profile?.companyEmail}</span>
                  </div>
                </div>
                <>
                  <div
                    className="pl-table-container"
                    style={{ overflow: "unset" }}
                  >
                    {sortedData.incomeArray.map((res, i) => (
                      <React.Fragment key={i}>
                        {res?._id !== "Gross Profit" && (
                          <div
                            className="pl-normal-td"
                            onClick={() => handleClickIncomeRow(i)}
                          >
                            <span className="pl-account-name">{res._id}</span>
                            <span className="pl-account-amount">
                              {symbol}&nbsp;
                              {res.totalClosingBalance.toFixed(decimalPosition)}
                            </span>
                          </div>
                        )}
                        {showIncomeRows[i] &&
                          res.accounts.length > 0 &&
                          res.accounts.map((subData, j) => (
                            <div key={j} className="pl-details-data-container">
                              <div className="pl-detail-item">
                                <div>{subData?.accountName}</div>
                                <div>
                                  {" "}
                                  {symbol}&nbsp;
                                  {subData?.closingBalance?.toFixed(
                                    decimalPosition
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                      </React.Fragment>
                    ))}
                    <div className="pl-normal-td pl-total-container">
                      <span className="pl-account-name">Gross Profit</span>
                      <span className="pl-account-amount">
                        {symbol}&nbsp;
                        {sortedData.incomeArray[
                          sortedData.incomeArray.length - 1
                        ]?.grossProfitAmount?.toFixed(decimalPosition)}
                      </span>
                    </div>
                    {sortedData.expenseArray.map((res, i) => (
                      <React.Fragment key={i}>
                        {res?._id !== "Profit & Loss Account" && (
                          <div
                            className="pl-normal-td"
                            onClick={() => handleClickExpenseRow(i)}
                          >
                            <span className="pl-account-name">{res._id}</span>
                            <span className="pl-account-amount">
                              {symbol}&nbsp;
                              {res.totalClosingBalance.toFixed(decimalPosition)}
                            </span>
                          </div>
                        )}
                        {showExpenseRows[i] &&
                          res.accounts.length > 0 &&
                          res.accounts.map((subData, j) => (
                            <div key={j} className="pl-details-data-container">
                              <div className="pl-detail-item">
                                <div>{subData.accountName}</div>
                                <div>
                                  {" "}
                                  {symbol}&nbsp;{symbol}&nbsp;
                                  {subData.closingBalance.toFixed(
                                    decimalPosition
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                      </React.Fragment>
                    ))}
                    <div className="pl-normal-td pl-total-container">
                      <span className="pl-account-name">
                        Profit & Loss Account
                      </span>
                      <span className="pl-account-amount">
                        {sortedData.expenseArray[
                          sortedData.expenseArray.length - 1
                        ]?.profitAndLossAccountAmount?.toFixed(decimalPosition)}
                      </span>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </> */}
           <div style={{ display: "none" }}>
            <div ref={(el) => (componentRef = el)}>
              <PrintComponent pageHeight={60} header={"PROFIT & LOSS ACCOUNT"}>
                <table className="">
                  <thead>
                    <tr>
                     
                    </tr>
                  </thead>
                  <tbody>
                    {sortedData.incomeArray.map((res, i) => (
                      <React.Fragment key={i}>
                        {res?._id !== "Gross Profit" && (
                          <tr>
                            <td className="pl-print-view-sub-head">
                              {res._id}
                            </td>
                            <td className="pl-print-view-sub-head">
                              {symbol}&nbsp;
                              {res.totalClosingBalance.toFixed(decimalPosition)}
                            </td>
                          </tr>
                        )}
                        {
                          res?.accounts?.length > 0 &&
                          res?.accounts?.map((subData, j) => (
                            <tr key={j} className="">
                              <td>{subData?.accountName}</td>
                              <td>
                                {symbol}&nbsp;
                                {subData?.closingBalance?.toFixed(
                                  decimalPosition
                                )}
                              </td>
                            </tr>
                          ))}
                      </React.Fragment>
                    ))}
                    <tr className="">
                      <td className="pl-print-view-Grtt">Gross Profit</td>
                      <td className="pl-print-view-Grtt">
                        {symbol}&nbsp;
                        {sortedData.incomeArray[
                          sortedData.incomeArray.length - 1
                        ]?.grossProfitAmount?.toFixed(decimalPosition)}
                      </td>
                    </tr>

                    {sortedData.expenseArray.map((res, i) => (
                      <React.Fragment key={i}>
                        {res?._id !== "Profit & Loss Account" && (
                          <tr
                            className=""
                            onClick={() => handleClickExpenseRow(i)}
                          >
                            <td className="pl-print-view-sub-head">
                              {res._id}
                            </td>
                            <td className="pl-print-view-sub-head">
                              {symbol}&nbsp;
                              {res.totalClosingBalance.toFixed(decimalPosition)}
                            </td>
                          </tr>
                        )}
                        {
                          res?.accounts?.length > 0 &&
                          res?.accounts?.map((subData, j) => (
                            <tr key={j} className="">
                              <td>{subData.accountName}</td>
                              <td>
                                {symbol}&nbsp;
                                {subData.closingBalance.toFixed(
                                  decimalPosition
                                )}
                              </td>
                            </tr>
                          ))}
                      </React.Fragment>
                    ))}

                    <tr className="">
                      <td className="pl-print-view-Grtt">
                        Profit & Loss Account
                      </td>
                      <td className="pl-print-view-Grtt">
                        {symbol}&nbsp;
                        {sortedData.expenseArray[
                          sortedData.expenseArray.length - 1
                        ]?.profitAndLossAccountAmount?.toFixed(decimalPosition)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </PrintComponent>
            </div>
          </div>
        </div>
      </div>
      <LoadingForm loading={loading} />
    </div>
  );
};

