import React, { useState } from "react";
import Pagelayout from "../components/Pagelayout";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Autocomplete,
} from "@mui/material";
//   import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
//   import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";

const CreateSalaryDisbursement = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    console.log("Back button clicked");
    navigate("/userdashboard/payroll/salarydisbursement");
  };

  const newData = {};

  const handleGeneratePayslip = () => {
    console.log("Generate payslip button clicked");
  };
  const mockData = [
    { id: "EMP054-Vaisak-1", employeeId: "EMP054-Vaisak", netSalary: 3500.0 },
    { id: "EMP054-Vaisak-2", employeeId: "EMP054-Vaisak", netSalary: 500.0 },
    { id: "EMP054-Vaisak-3", employeeId: "EMP054-Vaisak", netSalary: 500.0 },
    { id: "EMP054-Vaisak-4", employeeId: "EMP054-Vaisak", netSalary: 500.0 },
    { id: "EMP054-Vaisak-5", employeeId: "EMP054-Vaisak", netSalary: 500.0 },
    { id: "EMP054-Vaisak-6", employeeId: "EMP054-Vaisak", netSalary: 500.0 },
    { id: "EMP054-Vaisak-7", employeeId: "EMP054-Vaisak", netSalary: 500.0 },
    { id: "EMP054-Vaisak-8", employeeId: "EMP054-Vaisak", netSalary: 500.0 },
    { id: "EMP054-Vaisak-9", employeeId: "EMP054-Vaisak", netSalary: 500.0 },
    { id: "EMP054-Vaisak-10", employeeId: "EMP054-Vaisak", netSalary: 500.0 },
    { id: "EMP054-Vaisak-11", employeeId: "EMP054-Vaisak", netSalary: 500.0 },
    { id: "EMP054-Vaisak-12", employeeId: "EMP054-Vaisak", netSalary: 500.0 },
    { id: "EMP054-Vaisak-13", employeeId: "EMP054-Vaisak", netSalary: 500.0 },
    { id: "EMP054-Vaisak-14", employeeId: "EMP054-Vaisak", netSalary: 500.0 },
    { id: "EMP054-Vaisak-15", employeeId: "EMP054-Vaisak", netSalary: 500.0 },
    { id: "EMP054-Vaisak-16", employeeId: "EMP054-Vaisak", netSalary: 500.0 },
    { id: "EMP054-Vaisak-17", employeeId: "EMP054-Vaisak", netSalary: 500.0 },
    { id: "EMP054-Vaisak-18", employeeId: "EMP054-Vaisak", netSalary: 500.0 },
    { id: "EMP054-Vaisak-19", employeeId: "EMP054-Vaisak", netSalary: 500.0 },
    { id: "EMP054-Vaisak-20", employeeId: "EMP054-Vaisak", netSalary: 500.0 },
    { id: "EMP054-Vaisak-21", employeeId: "EMP054-Vaisak", netSalary: 500.0 },
    { id: "EMP054-Vaisak-22", employeeId: "EMP054-Vaisak", netSalary: 500.0 },
    { id: "EMP054-Vaisak-23", employeeId: "EMP054-Vaisak", netSalary: 500.0 },
    { id: "EMP054-Vaisak-24", employeeId: "EMP054-Vaisak", netSalary: 500.0 },
    // ... add more mock data as needed with unique IDs
  ];

  // Single state for all form data
  const [formData, setFormData] = useState({
    paymentMethod: null,
    accountingDate: null,
    paymentType: null,
    reff: "",
    taxNo: "",
    taxDate: null,
    branch: null,
    department: null,
    establishment: null,
  });

  // Generic handler for all input changes
  const handleInputChange = (field) => (event) => {
    setFormData((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  // Special handler for date fields
  const handleDateChange = (field) => (date) => {
    setFormData((prev) => ({
      ...prev,
      [field]: date,
    }));
  };

  // Special handler for autocomplete fields
  const handleAutocompleteChange = (field) => (event, newValue) => {
    setFormData((prev) => ({
      ...prev,
      [field]: newValue,
    }));
  };

  const handleSave = () => {
    console.log("Save button clicked");
    console.log(formData);
  };
  return (
    <Pagelayout
      headerLayout={2}
      pageName={"Create Salary Disbursement"}
      handleBack={handleBack}
      handleSave={handleSave}
      newData={newData}
      handleGeneratePayslip={handleGeneratePayslip}
    >
      <Paper sx={{ p: 2, mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Autocomplete
              fullWidth
              size="small"
              value={formData.paymentMethod}
              onChange={handleAutocompleteChange("paymentMethod")}
              options={["Bank", "Cash", "Check"]}
              isOptionEqualToValue={(option, value) => option === value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Payment Method"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              size="small"
              label="Accounting Date"
              type="date"
              value={formData.accountingDate || ""}
              onChange={handleInputChange("accountingDate")}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Autocomplete
              fullWidth
              size="small"
              value={formData.paymentType}
              onChange={handleAutocompleteChange("paymentType")}
              options={["Manual", "Automatic"]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Payment Type"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              size="small"
              label="Reff"
              value={formData.reff}
              onChange={handleInputChange("reff")}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              size="small"
              label="Tax no"
              value={formData.taxNo}
              onChange={handleInputChange("taxNo")}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              size="small"
              label="Tax Date"
              type="date"
              value={formData.taxDate || ""}
              onChange={handleInputChange("taxDate")}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Autocomplete
              fullWidth
              size="small"
              value={formData.branch}
              onChange={handleAutocompleteChange("branch")}
              options={[]} // Add your branch options here
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Branch"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Autocomplete
              fullWidth
              size="small"
              value={formData.department}
              onChange={handleAutocompleteChange("department")}
              options={[]} // Add your department options here
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Department"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Autocomplete
              fullWidth
              size="small"
              value={formData.establishment}
              onChange={handleAutocompleteChange("establishment")}
              options={[]} // Add your establishment options here
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Establishment"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ mt: 2 }}>
        <Box
          sx={{
            height: "400px",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <TableContainer
            sx={{
              flex: 1,
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "6px",
                height: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
                borderRadius: "3px",
              },
            }}
          >
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Employee</TableCell>
                  <TableCell align="right">Net Salary</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mockData.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell align="right">
                      {row.netSalary.toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box
            sx={{
              p: 2,
              backgroundColor: "#f5f5f5",
              borderTop: "1px solid rgba(224, 224, 224, 1)",
              display: "flex",
              justifyContent: "space-between",
              position: "sticky", 
              bottom: 0, 
              left: 0,
              right: 0,
              zIndex: 2, 
            }}
          >
            <Typography variant="subtitle1" fontWeight="bold">
              Total
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold">
              {mockData.reduce((sum, row) => sum + row.netSalary, 0).toFixed(2)}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Pagelayout>
  );
};

export default CreateSalaryDisbursement;
