import React from "react";
import { useNavigate } from "react-router-dom";
import store from "../../../../../Redux/store";
import { get_purchase_w_po_row_data_list } from "./purchaseWPOlist";
import { useEffect } from "react";
import { PurchaseWOPOFilterAPICall, viewPurchaseWPOAPICall } from "./purchaseWPOAPI";
import { convertDateFormat, getCurrentTime } from "../../../../../Js/Date";
import { IconButton, Skeleton, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { useState } from "react";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../../Single Components/CustomPagination";
import axios from "axios";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
import ReactToPrint from "react-to-print";
import ExcelJS from "exceljs"

export const PurchaseWOPO1 = () => {
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const userRole = useSelector((state) => state.userRoleSlice.value);
  const userBranchId = localStorage.getItem("branchId");
  const [FormDataInfo, setFormDataInfo] = useState([])


  const tableListData = useSelector(
    (state) => state.purchaseWPOSlice.apiResList
  );

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const totalPages = tableListData?.pages;
  const [filteredDatas, setFilteredData] = useState({});
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };

  let navigate = useNavigate();

  const createFunctuon = () => {
    navigate("/userdashboard/accounts/vendor/purchasewopo/create");
  };
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };

  const handleClickRow = (row) => {
    store.dispatch( setFilterActive(window.location.pathname))
    localStorage.setItem("singleViewById", row._id);
    // store.dispatch(get_purchase_w_po_row_data_list({purchaseWPOSingleData:row}))
    // navigate("/userdashboard/accounts/vendor/purchasewopo/create")
    navigate("/userdashboard/accounts/vendor/purchasewopo/SingleView");
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };
  const handleFilterChange=(updatedItems)=>{
    setFilteredData(updatedItems)
  }
  const formatFilterInfo = (filters) => {
    const parts = [];

    if (filters?.toDate && filters.fromDate) {
      parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
    }
    if (filters?.branchList) {
      parts.push(
        `Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`
      );
    }
    if (filters?.location) {
      parts.push(`location: ${filters?.location?.name}`);
    }
    if (filters?.toLocation) {
      parts.push(`To Location: ${filters?.toLocation?.name}`);
    }
    if (filters?.status) {
      parts.push(`status: ${filters?.status}`);
    }
    if (filters?.createdBy) {
      parts.push(`createdBy: ${filters?.createdBy}`);
    }
    return parts; // Return the array directly
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Purchase WPO");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["Purchase WPO"]);
    titleRow.font = { size: 15, bold: true };
    sheet.mergeCells("A1:H1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add additional header information
    const filterParts = formatFilterInfo(filteredDatas) || [];

    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 11, bold: true };
        sheet.mergeCells(`A${index + 2}:H${index + 2}`);
        row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
      });
    }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow([
      "Date",
      "Name",
      "Vendor",
      "Bill Date",
      "Bill Refference",
      "Total",
      "Created By",
      "Status",
     
    ]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 12,
      bold: true,
      color: { argb: "FF000000" }, // White font color
    };

    headerRow.eachCell((cell, colNumber) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "Date", key: "billDates", width: 15 },
      { header: "Name", key: "sequenceName", width: 20 },
      { header: "Vendor", key: "vendorName", width: 25 },
      { header: "Bill Date", key: "billDate", width: 25 },
      { header: "Bill Refference", key: "billRef", width: 25 },
      { header: "Total", key: "total", width: 15 },
      { header: "Created By", key: "CREATEDBY", width: 15 },
      { header: "Purchase WPO", key: "status", width: 20 },
    ];

    tableListData?.list?.forEach((item, index) => {
      const row = sheet.addRow({
        billDates: item?.billDate,
        sequenceName: item.sequenceName||item.NAME,
        vendorName: item.vendorName,
        billDate: convertDateFormat(item?.billDate),
        billRef: item?.billRef,
        total: item?.total,
        CREATEDBY: item?.CREATEDBY,
        status: item?.status,
        
      });

      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };

      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          //   top: { style: "thin", color: { argb: "FF000000" } },
          //   left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FFedf2f9" } },
          //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber >= 1) {
          // Right align all columns except the first
          cell.alignment = { horizontal: "center" };
        }
      });
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Purchase WPO.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    localStorage.removeItem("singleViewById");
    store.dispatch(
      get_purchase_w_po_row_data_list({ purchaseWPOSingleData: undefined })
    );
    // if (userRole === "user") {
    //   viewPurchaseWPOAPICall({ branchId: userBranchId }, setIsLoading);
    // } else {
    //   viewPurchaseWPOAPICall({}, setIsLoading);
    // }
  }, []);
  console.log(FormDataInfo.status);
  useEffect(() => {
   
    if (FormDataInfo.length !== 0) {

      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&   PurchaseWOPOFilterAPICall({
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
        toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
        createdBy: FormDataInfo.createdBy,
        branchList: FormDataInfo.branchList,
        status: FormDataInfo.status,
        search: FormDataInfo.search,
        index: currentPage-1,
      }, setIsLoading);
    }
   
  }, [FormDataInfo, currentPage]);

  return (
    <div className="global-page-parent-container">
      {/* table */}
      <div className="global-white-bg-container">
        <div className="justify-space-between"
          style={{ gap: "0 12px",  display: 'flex', justifyContent: 'space-between', alignItems: 'end'}}
        >
          {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
                <h3>Branch name: 
                  {selectedBranch?.storeCode||filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                  -{selectedBranch?.branchName||filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>
              </div>
            )} 
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap:'9px',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
           <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap:'9px',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
           <div style={{ display: "flex", alignItems: "center" }}>
          <Tooltip title="Share">
                  <IconButton>
                    <i
                      class="bi bi-share"
                      style={{
                        backgroundColor: "#9797e9",
                        color: "white",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>

                <ReactToPrint
                  trigger={() => (
                    <IconButton
                      className="printer-div"
                      style={{ padding: "1px" }}
                    >
                      <i
                        style={{
                          color: "white",
                          backgroundColor: " #d787e7",
                          fontSize: "medium",
                          padding: "8px",
                          borderRadius: "2px",
                        }}
                        class="bi bi-printer"
                      ></i>
                    </IconButton>
                  )}
                  // content={() => paymentsPrintNew}
                />
                <Tooltip title="Download">
                  <IconButton onClick={downloadList}>
                    <i
                      class="bi bi-arrow-down-circle"
                      style={{
                        backgroundColor: "rgb(255 118 133 / 69%)",
                        color: "white",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>
          <CategorySearchandFilter
            statusOptions={['DRAFT', 'POSTED', 'PAID']}
            onData={handleDataFromChild}
            isCreatedBy={true}
            isStatus={true}
            onBranchSelect={handleBranchSelect}
            onFilterChange={handleFilterChange}
          />
          <button onClick={createFunctuon} className="create-button-blue">
            Create
          </button>
          </div>
          </div>
          </div>
        </div>
        <div className="create-button-blue-container" >
          <h4>Purchase W/PO</h4>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />

        </div>
        <div className="new-global-table-container">
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Vendor</th>
                <th>Bill Date</th>
                <th>Bill Reference</th>
                <th>Total</th>
                <th>Created By</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={8}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : tableListData?.list !== undefined && tableListData?.list?.length !== 0 ? (
                tableListData?.list
                  ?.slice(0)
                 
                  ?.map((r, i) => (
                    <tr onClick={() => handleClickRow(r)}>
                      <td>{r?.billDate}</td>
                      <td>{r?.sequenceName || r?.NAME}</td>
                      <td>{r?.vendorName}</td>
                      <td>{convertDateFormat(r?.billDate)}</td>
                      <td>{r?.billRef}</td>
                      <td>{r?.total || "---"}</td>
                      <td>{r?.CREATEDBY || "---"}</td>
                      <td className="table-data-box">
                        {r?.status === "DRAFT" ? (
                          <span className="draft"> DRAFT</span>
                        ) : r?.status === "POSTED" ? (
                          <span className="post"> POST</span>
                        ) : r?.status === "PAID" ? (
                          <span className='paid'>PAID</span>
                        ) : (
                          <span>{r?.status}</span>
                        )}
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={8}>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
