import React from "react";
import Pagelayout from "../../components/Pagelayout";
import CustomTable from "../../components/ListPageComponent";
import { useNavigate } from "react-router-dom";

const LoanPayroll = () => {
   const navigate = useNavigate();
  const headers = [
    { key: "employee", label: "Employee" },
    { key: "department", label: "Department" },
    { key: "designation", label: "Designation" },
    { key: "mobile", label: "Mobile" },
    { key: "totalLoanAmount", label: "Total Loan Amount" },
    { key: "totalRepaid", label: "Total Repaid" },
    { key: "status", label: "Status" },
  ];
  const visibleColumns = ["employee", "department", "designation", "mobile", "totalLoanAmount", "totalRepaid", "status"];

  const data = [
    {
      employee: "John Doe",
      department: "IT",
      designation: "Developer",
      mobile: "1234567890",
      totalLoanAmount: "10000",
      totalRepaid: "5000",
      status: "Approved",
    },
    {
      employee: "Jane Doe",
      department: "HR",
      designation: "Manager",
      mobile: "0987654321",
      totalLoanAmount: "15000",
      totalRepaid: "7500",
      status: "Pending",
    },
    {
      employee: "John Doe",
      department: "IT",
      designation: "Developer",
      mobile: "1234567890",
      totalLoanAmount: "10000",
      totalRepaid: "5000",
      status: "Rejected",
    },
    {
      employee: "John Doe",
      department: "IT",
      designation: "Developer",
      mobile: "1234567890",
      totalLoanAmount: "10000",
      totalRepaid: "5000",
      status: "Disbursement",
    },
  ];
  const handleRowClick = (rowData) => {
    console.log("Clicked row data:", rowData);
  };
  const handleCreateBtn = () => {
    navigate("create");
  };

  return ( 
    <Pagelayout pageName={"Loan List"} headerLayout={1} handleCreateBtn={handleCreateBtn} >
      <CustomTable
        headers={headers}
        data={data}
        visibleColumns={visibleColumns}
        onRowClick={handleRowClick}
        loading={false}
        />
    </Pagelayout>
  );
};

export default LoanPayroll;
