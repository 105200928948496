import React, { useEffect, useRef, useState } from 'react'
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { filterObjFromList } from '../../../../../Js/generalFunctions';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import { CustomerReceiptReportAPIcall } from './GeneralReportAPI';
import { viewAllCurrencyUpdated } from '../../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../../API/Settings/Company Settings/companySettingsAPI';
import axios from 'axios';
import ReactToPrint from 'react-to-print';
import ExcelJS from "exceljs"
import PrintComponent from '../../../../print/report/PrintComponent';
function CustomerReceiptReport() {
  let paymentsPrintNew = useRef();

  let navigate = useNavigate();

  const tableListData = useSelector((state) => state?.generalReport?.customerReceiptReport)


  const totalPaidAmtKeys = tableListData !== undefined ? Object.keys(tableListData?.total?.totalPaidAmount) : [];


  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue)



  const [FormDataInfo, setFormDataInfo] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [symbol, setSymbol] = useState(null);
  const [filteredData, setFilteredData] = useState("")

  const decimalPosition = localStorage.getItem("decimalPosition");

  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
  };
  const handleFilterChange = (updatedtItems) => {
    setFilteredData(updatedtItems)
  }
  const formatFilterInfo = (filters) => {
    const parts = [];

    if (filters?.fromDate && filters?.toDate) {
      parts.push(`As of ${filters?.fromDate} to ${filters?.toDate}`);
    }

    if (filters?.branchList) {
      parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
    } return parts; // Return the array directly
  };

  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Customer Receipt Report");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["Customer Receipt Report"]);
    titleRow.font = { size: 18, bold: true };
    sheet.mergeCells('A1:G1');
    titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };

    // Add additional header information
    const filterParts = formatFilterInfo(filteredData) || [];

    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 15, bold: true };
        sheet.mergeCells(`A${index + 2}:G${index + 2}`);
        row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
      });
    }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow(["Customer", "INV Count", "INV Amount", "Paid Amount", ...totalPaidAmtKeys, "Credit"]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF002995" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 15,
      bold: true,
      color: { argb: "FFFFFFFF" }, // White font color
    };

    headerRow.eachCell((cell) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "Customer", key: "customerName", width: 50 },
      { header: "INV Count", key: "invoiceCount", width: 20 },
      { header: "INV Amount", key: "invAmount", width: 20 },
      { header: "Paid Amount", key: "total", width: 20 },
      ...totalPaidAmtKeys.map((key) => ({ header: key, key, width: 20 })),
      { header: "Customer Receipt Report", key: "amountDue", width: 20 }
    ];

    tableListData?.report?.forEach((item) => {
      const row = sheet.addRow({
        customerName: item.customerName,
        invoiceCount: item.invoiceCount,
        invAmount: item.invAmount,
        total: ` ${item?.total?.toFixed(2)}`,
        ...Object.fromEntries(totalPaidAmtKeys.map(key => [key, ` ${item?.totalPaidAmount[key]?.toFixed(2) || '0.00'}`])),
        amountDue: ` ${item?.amountDue?.toFixed(2)}`,
      });

      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFededed" }, // Use full hex code with "FF" prefix for better compatibility
      };

      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          top: { style: "thin", color: { argb: "FFFFFFFF" } },
          left: { style: "thin", color: { argb: "FFFFFFFF" } },
          bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
          right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber > 1) {
          cell.alignment = { horizontal: 'right' }
        }

      });
    });

    // Add row for totals
    const totalRow = sheet.addRow([
      "Total",
      `${symbol} ${tableListData?.total !== undefined && tableListData?.total?.invoiceCount?.toFixed(2) || 0.00}`,
      `${symbol} ${tableListData?.total !== undefined && tableListData?.total?.invAmount?.toFixed(2) || 0.00}`,
      `${symbol} ${tableListData?.total !== undefined && tableListData?.total?.total?.toFixed(2) || 0.00}`,
      ...totalPaidAmtKeys.map(key => `${symbol} ${tableListData?.total?.totalPaidAmount[key]?.toFixed(2) || 0.00}`),
      `${symbol} ${tableListData?.total !== undefined && tableListData?.total?.amountDue?.toFixed(2) || 0.00}`,
    ]);

    totalRow.eachCell((cell, colNumber) => {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
      cell.fill = headerFill;
      cell.border = {
        top: { style: "thin", color: { argb: "FFFFFFFF" } },
        left: { style: "thin", color: { argb: "FFFFFFFF" } },
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
        right: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
      if (colNumber > 1) {
        cell.alignment = { horizontal: 'right' }
      }
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Customer Receipt Report.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const handleRowClick = (row) => {
    localStorage.setItem("customerReceiptSingleViewId", row?._id);
    navigate("/userdashboard/sales/Report/CustomerReceiptReportSingleView", { state: { customerName: row?.customerName } })
    // navigate("/userdashboard/accounts/report/generalReport/InvoiceMarginReport/SingleView")
  };

  useEffect(() => {
    let currencyObj = filterObjFromList("_id", currencyListUpdated, "currency", companyProfileData)
    setSymbol(currencyObj?.symbol)
  }, [companyProfileData, currencyListUpdated])
  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, [])

  // useEffect(() => {
  //     CustomerReceiptReportAPIcall()
  // }, [])

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (FormDataInfo?.length !== 0) {
      let body = {
        fromDate: FormDataInfo?.fromDate === '' ? '' : `${FormDataInfo?.fromDate} 00:00:00`,
        toDate: FormDataInfo?.toDate === '' ? '' : `${FormDataInfo?.toDate} 23:59:59`,
        branchId: FormDataInfo?.branchList,
        // search: FormDataInfo?.search,
      }
      CustomerReceiptReportAPIcall(body, source, setIsLoading);
    }
    return () => {
      source.cancel('Request canceled by cleanup');
    };
  }, [FormDataInfo])
  return (
    <div className="global-page-parent-container">
      <div className="global-white-bg-container Header" style={{ display: "flex", justifyContent: "space-between", padding: "0px 30px 0 25px", alignItems: "center" }}>

        <div style={{ display: 'flex' }}>
          <p>Customer Receipt Report</p>
        </div>
        <div style={{ display: "flex" }}>
          <Tooltip title="Share">
            <IconButton>
              <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
            </IconButton>
          </Tooltip>

          <ReactToPrint
            trigger={() => (
              <IconButton className="printer-div" style={{ padding: "1px" }}>
                <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
              </IconButton>
            )}
            content={() => paymentsPrintNew.current}
          />

          <Tooltip title="Download">
            <IconButton onClick={downloadList}>
              <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
            </IconButton>
          </Tooltip>
          <CategorySearchandFilter
            onData={handleDataFromChild}
            isCustomer={true}
            isJournal={true}
            onFilterChange={handleFilterChange}
          />

        </div>
      </div>
      <div className="global-white-bg-container " style={{ alignItems: "center" }}>

        <div className="invoice-table-container" style={{ height: "81vh" }}>
          <div className="invoice-main-div" style={{ maxHeight: "81vh" }}>

            <table>
              <thead>
                <tr>
                  <th style={{ textAlignLast: "center" }}>Customer</th>
                  <th style={{ textAlignLast: "center" }}>INV count</th>
                  <th style={{ textAlignLast: "center" }}>INV Amount</th>
                  <th style={{ textAlignLast: "center" }}>Paid Amount</th>
                  {
                    totalPaidAmtKeys?.map(r =>
                      <th style={{ textAlignLast: "center" }}>{r}</th>
                    )
                  }
                  <th style={{ textAlignLast: "center" }}>Credit</th>
                </tr>
              </thead>
              <tbody>

                {isLoading ? (
                  [...Array(10)].map((r, i) => (
                    <tr key={i}>
                      <td colSpan={8}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) : tableListData?.report !== undefined && tableListData?.report?.length !== 0 ? (
                  tableListData?.report
                    ?.slice(0)
                    ?.map((r, i) => (
                      <tr key={i} onClick={() => handleRowClick(r)}>
                        <td style={{ textAlignLast: "start" }}>{r?.customerName}</td>
                        <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{r?.invoiceCount}</td>
                        <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{r?.invAmount?.toFixed(decimalPosition)}</td>
                        <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{r?.total?.toFixed(decimalPosition)}</td>
                        {totalPaidAmtKeys?.map((key) => (

                          <td style={{ textAlignLast: "end", paddingRight: "10px" }} key={key}>{symbol}&nbsp;{r?.totalPaidAmount[key]?.toFixed(decimalPosition) || '0.00'}</td>
                        ))}


                        <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{r?.amountDue?.toFixed(decimalPosition)}</td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td style={{ textAlign: 'center' }} colSpan={8}>No Data</td>
                  </tr>
                )}

              </tbody>
              {tableListData?.total !== undefined &&
                <tr>
                  <th >Total</th>
                  <th style={{ textAlignLast: "end", paddingRight: "10px" }}>{tableListData?.total?.invoiceCount}</th>
                  <th style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{tableListData?.total?.invAmount?.toFixed(decimalPosition)}</th>
                  <th style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{tableListData?.total?.total?.toFixed(decimalPosition)}</th>
                  {totalPaidAmtKeys.map((key) => (

                    <th style={{ textAlignLast: "end", paddingRight: "10px" }} key={key}>{tableListData?.total?.totalPaidAmount[key]?.toFixed(decimalPosition) || 0}</th>
                  ))}
                  <th style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{tableListData?.total?.amountDue?.toFixed(decimalPosition)}</th>
                </tr>
              }
              {/* <tr>
                                <th >Total</th>
                                <th >{tableListData?.total !== undefined && tableListData?.total?.invoiceCount}</th>
                                <th >{symbol}&nbsp;{tableListData?.total !== undefined && tableListData?.total?.invAmount?.toFixed(decimalPosition)}</th>
                                <th >{symbol}&nbsp;{tableListData?.total !== undefined && tableListData?.total?.total?.toFixed(decimalPosition)}</th>
                                {totalPaidAmtKeys.map((key) => (

                                    <th key={key}>{tableListData?.total?.totalPaidAmount[key] || 0}</th>
                                ))}
                                <th >{symbol}&nbsp;{tableListData?.total !== undefined && tableListData?.total?.amountDue?.toFixed(decimalPosition)}</th>
                            </tr> */}
            </table>
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={paymentsPrintNew}>
          <PrintComponent header={"Customer Receipt Report"} pageHeight={70}>
            <table>
              <thead>
                <tr>
                  <th>Customer</th>
                  <th>INV count</th>
                  <th>INV Amount</th>
                  <th>Paid Amount</th>
                  {totalPaidAmtKeys?.map((r) => (
                    <th>{r}</th>
                  ))}
                  <th>Credit</th>
                </tr>
              </thead>
              <tbody>
                {tableListData?.report !== undefined &&
                  tableListData?.report?.length !== 0 ? (
                  tableListData?.report?.slice(0)?.map((r, i) => (
                    <>
                      <tr key={i} onClick={() => handleRowClick(r)}>
                        <td style={{ textAlignLast: "start" }}>
                          {r?.customerName}
                        </td>
                        <td
                          style={{ textAlignLast: "end", paddingRight: "10px" }}
                        >
                          {r?.invoiceCount}
                        </td>
                        <td
                          style={{ textAlignLast: "end", paddingRight: "10px" }}
                        >
                          {symbol}&nbsp;{r?.invAmount?.toFixed(decimalPosition)}
                        </td>
                        <td
                          style={{ textAlignLast: "end", paddingRight: "10px" }}
                        >
                          {symbol}&nbsp;{r?.total?.toFixed(decimalPosition)}
                        </td>
                        {totalPaidAmtKeys?.map((key) => (
                          <td
                            style={{
                              textAlignLast: "end",
                              paddingRight: "10px",
                            }}
                            key={key}
                          >
                            {symbol}&nbsp;
                            {r?.totalPaidAmount[key]?.toFixed(
                              decimalPosition
                            ) || "0.00"}
                          </td>
                        ))}

                        <td
                          style={{ textAlignLast: "end", paddingRight: "10px" }}
                        >
                          {symbol}&nbsp;{r?.amountDue?.toFixed(decimalPosition)}
                        </td>
                      </tr>
                      {tableListData?.total !== undefined &&
                        tableListData?.report?.length - 1 === i && (
                          <tr>
                            <th>Total</th>
                            <th
                              style={{
                                textAlignLast: "end",
                                paddingRight: "10px",
                              }}
                            >
                              {tableListData?.total?.invoiceCount}
                            </th>
                            <th
                              style={{
                                textAlignLast: "end",
                                paddingRight: "10px",
                              }}
                            >
                              {symbol}&nbsp;
                              {tableListData?.total?.invAmount?.toFixed(
                                decimalPosition
                              )}
                            </th>
                            <th
                              style={{
                                textAlignLast: "end",
                                paddingRight: "10px",
                              }}
                            >
                              {symbol}&nbsp;
                              {tableListData?.total?.total?.toFixed(
                                decimalPosition
                              )}
                            </th>
                            {totalPaidAmtKeys.map((key) => (
                              <th
                                style={{
                                  textAlignLast: "end",
                                  paddingRight: "10px",
                                }}
                                key={key}
                              >
                                {tableListData?.total?.totalPaidAmount[
                                  key
                                ]?.toFixed(decimalPosition) || 0}
                              </th>
                            ))}
                            <th
                              style={{
                                textAlignLast: "end",
                                paddingRight: "10px",
                              }}
                            >
                              {symbol}&nbsp;
                              {tableListData?.total?.amountDue?.toFixed(
                                decimalPosition
                              )}
                            </th>
                          </tr>
                        )}
                    </>
                  ))
                ) : (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={5}>
                      No Data
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </PrintComponent>
        </div>
      </div>

      {/* <LoadingForm loading={isLoading} /> */}
    </div>
  )
}

export default CustomerReceiptReport