import { useState } from "react";
import { Box, Button, Divider, Typography, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import { IoArrowBack, IoSave } from "react-icons/io5";
import { FiEdit, FiSave } from "react-icons/fi";
import { GiCheckMark } from "react-icons/gi";
import { IoMdCheckmark } from "react-icons/io";
import { FaFileAlt } from "react-icons/fa";

const HeaderContainer = styled(Box)(({ theme }) => ({
  position: "sticky",
  top: 0,
  left: 0,
  width: "100%",
  backgroundColor: "#ffffff",
  //   padding: "10px ",
  //   boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  zIndex: 1000,
  "@media (max-width: 600px)": {
    padding: "12px 16px",
  },
}));

const ButtonContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "12px",
});

const StyledButton = styled(Button)({
  width: "8em",
  borderRadius: "5px",
  textTransform: "none",
  transition: "all 0.3s ease",
  "&:hover": {
    // transform: "translateY(-2px)",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  "&:focus": {
    outline: "2px solid #1976d2",
    outlineOffset: "2px",
  },
});

const HeaderLayout2 = ({ actionBtn,newData, handleBack, handleSave, handleEdit, handleGeneratePayslip, generatePayslip }) => {
  return (
    <>
      <HeaderContainer>
        <ButtonContainer>
          <IconButton
            onClick={handleBack}
            aria-label="go back"
            sx={{
              "&:hover": { backgroundColor: "#f5f5f5" },
            }}
          >
            <IoArrowBack size={24} />
          </IconButton>
          {actionBtn && <>
          {newData ? (
            <>
              <StyledButton
                variant="outlined"
                color="primary"
                onClick={handleSave}
                startIcon={<FiSave />}
                aria-label="save changes"
              >
                Save
              </StyledButton>
            </>
          ) : (
            <StyledButton
              variant="outlined"
              color="primary"
              onClick={handleEdit}
              startIcon={<FiEdit />}
              aria-label="edit content"
            >
              Edit
            </StyledButton>
          )}
          </>}
         { generatePayslip && <StyledButton
              style={{ width: "15em" }}
              variant="outlined"
              color="primary"
              onClick={handleGeneratePayslip}
              startIcon={<FaFileAlt  />}
              aria-label="generate payslip"
              
            >
              Generate Payslip
            </StyledButton>}
        </ButtonContainer>
        <Divider sx={{ mt: 2 }} />
      </HeaderContainer>
      {/* {error && (
        <Typography
          color="error"
          sx={{ p: 2, textAlign: "center" }}
          role="alert"
        >
          {error}
        </Typography>
      )} */}
    </>
  );
};

export default HeaderLayout2;
