import React from "react";
import {
  Tag,
  Check,
  X,
  Percent,
  DollarSign,
  Building2,
  Calculator,
} from "lucide-react";
import Pagelayout from "../../components/Pagelayout";
import { useNavigate } from "react-router-dom";
import "./payrollPayheadStyle.css";

const Badge = ({ children, className }) => (
  <span className={`new-pyh-cmpt__badge ${className}`}>{children}</span>
);

export const Switch = ({ checked, className }) => (
  <label className={`new-pyh-cmpt__switch ${className}`}>
    <input
      type="checkbox"
      checked={checked}
      className="new-pyh-cmpt__switch-input"
      onChange={() => {}}
    />
    <span className="new-pyh-cmpt__switch-slider"></span>
  </label>
);

const PayHeadCard = ({ data }) => {
  const getCategoryConfig = (category) => {
    const configs = {
      BASIC: {
        bgClass: "new-pyh-cmpt__card--blue",
        textClass: "new-pyh-cmpt__text--blue",
        icon: DollarSign,
      },
      ALLOWANCE: {
        bgClass: "new-pyh-cmpt__card--purple",
        textClass: "new-pyh-cmpt__text--purple",
        icon: Percent,
      },
      DEDUCTION: {
        bgClass: "new-pyh-cmpt__card--red",
        textClass: "new-pyh-cmpt__text--red",
        icon: Calculator,
      },
      COMMISSION: {
        bgClass: "new-pyh-cmpt__card--green",
        textClass: "new-pyh-cmpt__text--green",
        icon: Percent,
      },
      BONUS: {
        bgClass: "new-pyh-cmpt__card--cyan",
        textClass: "new-pyh-cmpt__text--cyan",
        icon: DollarSign,
      },
    };
    return (
      configs[category] || {
        bgClass: "new-pyh-cmpt__card--gray",
        textClass: "new-pyh-cmpt__text--gray",
        icon: Tag,
      }
    );
  };

  const config = getCategoryConfig(data.category);
  const CategoryIcon = config.icon;

  const getFieldIcon = (fieldKey) => {
    if (fieldKey.includes("amount") || fieldKey.includes("percentage"))
      return DollarSign;
    if (fieldKey.includes("account")) return Building2;
    if (fieldKey.includes("calculation")) return Calculator;
    return null;
  };

  return (
    <div className={`new-pyh-cmpt__card ${config.bgClass}`}>
      <div className="new-pyh-cmpt__card-header">
        <div className="new-pyh-cmpt__header-content">
          <div className="new-pyh-cmpt__category">
            <CategoryIcon size={18} className={config.textClass} />
            <Badge className={`${config.textClass} ${config.bgClass}`}>
              {data.category}
            </Badge>
          </div>
          <div className="new-pyh-cmpt__status">
            <span
              className={`new-pyh-cmpt__status-text ${
                data.active ? "new-pyh-cmpt__status-text--active" : ""
              }`}
            >
              {data.active ? (
                <Check size={14} className="new-pyh-cmpt__status-icon" />
              ) : (
                <X size={14} className="new-pyh-cmpt__status-icon" />
              )}
              {data.active ? "Active" : "Inactive"}
            </span>
            <Switch
              checked={data.active}
              className="new-pyh-cmpt__switch--small"
            />
          </div>
        </div>
      </div>

      <div className="new-pyh-cmpt__card-content">
        <div className="new-pyh-cmpt__name-container">
          <h3 className="new-pyh-cmpt__name">{data.name}</h3>
        </div>

        <div className="new-pyh-cmpt__separator" />

        <div className="new-pyh-cmpt__fields">
          {Object.entries(data.fields || {}).map(([key, value]) => {
            const FieldIcon = getFieldIcon(key);
            return (
              <div key={key} className="new-pyh-cmpt__field">
                <span className="new-pyh-cmpt__field-label">
                  {FieldIcon && (
                    <FieldIcon size={14} className="new-pyh-cmpt__field-icon" />
                  )}
                  {key.replace(/_/g, " ").toUpperCase()}
                </span>
                <span
                  className={`new-pyh-cmpt__field-value ${
                    key.includes("amount")
                      ? "new-pyh-cmpt__field-value--amount"
                      : ""
                  }`}
                >
                  {value}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const PayrollPayHead = () => {
  const navigate = useNavigate();
  const handleCreatePayHead = () => navigate("create");
  const payHeads = [
    {
      category: "BASIC",
      name: "BASIC FOR SALESMAN",
      active: false,
      fields: {
        calculation_type: "% OF CTC",
        percentage: "50 %",
        expense_account: "Salaries a/c",
      },
    },
    {
      category: "BASIC",
      name: "BASIC FOR SALESMAN",
      active: true,
      fields: {
        calculation_type: "FIXED AMOUNT",
        fixed_amount: "5000 AED",
        expense_account: "Salaries a/c",
      },
    },
    {
      category: "ALLOWANCE",
      name: "TELEPHONE ALLOWANCE",
      active: true,
      fields: {
        include_in_basic: "TRUE",
        calculation_type: "% OF BASIC",
        percentage_of_basic: "10 %",
        expense_account: "Salaries a/c",
      },
    },
    {
      category: "DEDUCTION",
      name: "PROFESSIONAL TAX",
      active: true,
      fields: {
        calculation_type: "% OF CTC",
        percentage: "50 %",
        expense_account: "Salaries a/c",
      },
    },
    {
      category: "COMMISSION",
      name: "COMMISSION",
      active: true,
      fields: {
        include_in_basic: "TRUE",
        calculation_type: "% OF BASIC",
        percentage_of_basic: "10 %",
        expense_account: "Salaries a/c",
      },
    },
    {
      category: "BONUS",
      name: "BONUS",
      active: true,
      fields: {
        include_in_basic: "TRUE",
        calculation_type: "% OF BASIC",
        percentage_of_basic: "10 %",
        expense_account: "Salaries a/c",
      },
    },
    {
      category: "BASIC",
      name: "BASIC FOR SALESMAN",
      active: true,
      fields: {
        calculation_type: "% OF CTC",
        percentage: "50 %",
        expense_account: "Salaries a/c",
      },
    },
    {
      category: "BASIC",
      name: "BASIC FOR SALESMAN",
      active: true,
      fields: {
        calculation_type: "FIXED AMOUNT",
        fixed_amount: "5000 AED",
        expense_account: "Salaries a/c",
      },
    },
    {
      category: "ALLOWANCE",
      name: "TELEPHONE ALLOWANCE",
      active: true,
      fields: {
        include_in_basic: "TRUE",
        calculation_type: "% OF BASIC",
        percentage_of_basic: "10 %",
        expense_account: "Salaries a/c",
      },
    },
    {
      category: "DEDUCTION",
      name: "PROFESSIONAL TAX",
      active: true,
      fields: {
        calculation_type: "% OF CTC",
        percentage: "50 %",
        expense_account: "Salaries a/c",
      },
    },
    {
      category: "COMMISSION",
      name: "COMMISSION",
      active: true,
      fields: {
        include_in_basic: "TRUE",
        calculation_type: "% OF BASIC",
        percentage_of_basic: "10 %",
        expense_account: "Salaries a/c",
      },
    },
    {
      category: "BONUS",
      name: "BONUS",
      active: true,
      fields: {
        include_in_basic: "TRUE",
        calculation_type: "% OF BASIC",
        percentage_of_basic: "10 %",
        expense_account: "Salaries a/c",
      },
    },
    {
      category: "BASIC",
      name: "BASIC FOR SALESMAN",
      active: true,
      fields: {
        calculation_type: "% OF CTC",
        percentage: "50 %",
        expense_account: "Salaries a/c",
      },
    },
    {
      category: "BASIC",
      name: "BASIC FOR SALESMAN",
      active: true,
      fields: {
        calculation_type: "FIXED AMOUNT",
        fixed_amount: "5000 AED",
        expense_account: "Salaries a/c",
      },
    },
    {
      category: "ALLOWANCE",
      name: "TELEPHONE ALLOWANCE",
      active: true,
      fields: {
        include_in_basic: "TRUE",
        calculation_type: "% OF BASIC",
        percentage_of_basic: "10 %",
        expense_account: "Salaries a/c",
      },
    },
    {
      category: "DEDUCTION",
      name: "PROFESSIONAL TAX",
      active: true,
      fields: {
        calculation_type: "% OF CTC",
        percentage: "50 %",
        expense_account: "Salaries a/c",
      },
    },
    {
      category: "COMMISSION",
      name: "COMMISSION",
      active: true,
      fields: {
        include_in_basic: "TRUE",
        calculation_type: "% OF BASIC",
        percentage_of_basic: "10 %",
        expense_account: "Salaries a/c",
      },
    },
    {
      category: "BONUS",
      name: "BONUS",
      active: true,
      fields: {
        include_in_basic: "TRUE",
        calculation_type: "% OF BASIC",
        percentage_of_basic: "10 %",
        expense_account: "Salaries a/c",
      },
    },
  ];

  
  return (
    <Pagelayout
      pageName="Pay Head"
      headerLayout={1}
      handleCreateBtn={handleCreatePayHead}
    >
      <div className="new-pyh-cmpt">
        <div className="new-pyh-cmpt__grid">
          {payHeads.map((payHead, index) => (
            <PayHeadCard key={index} data={payHead} />
          ))}
        </div>
      </div>
    </Pagelayout>
  );
};

export default PayrollPayHead;
