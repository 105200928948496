import React, { useState } from "react";
import Pagelayout from "../components/Pagelayout";
import {
  Card,
  Typography,
  Autocomplete,
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  CircularProgress,
  Fade,
  Alert,
} from "@mui/material";
import { styled } from "@mui/system";
import { FaRegTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const CreateContractPayslip = () => {
  // Sample data - replace with your actual data
  const employees = [];
  const departments = [];
  const designations = [];
  const contractTypes = [];
  const sponsors = [];
  const branches = [];
  const durations = ["1 Month", "3 Months", "6 Months", "1 Year"];
  const durationType = ["Fixed", "Flexible"];
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/userdashboard/payroll/contractpayslip");
  };

  return (
    <Pagelayout pageName={"Create Contract Payslip"} headerLayout={2} handleBack={handleBack}>
      <Card sx={{ maxWidth: "100%", mx: "auto", p: 3 }}>
        <Typography variant="h6" sx={{ mb: 2, color: "text.secondary" }}>
          PAYSLIP/2023/10/0001
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Autocomplete
              options={employees}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Employee"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              options={departments}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Department"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              options={designations}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Designation"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              options={contractTypes}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Contract Type"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              options={sponsors}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sponsor"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              options={branches}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Branch"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Duration From"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Duration To"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              options={durations}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Duration"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              options={durationType}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Duration Type"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <TextField
              label="Reference"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </Card>
      <ContactTypePopup />
    </Pagelayout>
  );
};

export default CreateContractPayslip;

//popup

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "16px",
    minWidth: "400px",
    maxWidth: "600px",
    padding: "16px",
  },
}));

const StyledTableContainer = styled(TableContainer)({
  maxHeight: "250px",
  marginTop: "20px",
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
    borderRadius: "3px",
  },
});

export const ContactTypePopup = () => {
  const [open, setOpen] = useState(false);
  const [contactType, setContactType] = useState("");
  const [contactTypes, setContactTypes] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAdd = async () => {
    if (!contactType.trim()) {
      setError("Contact type cannot be empty");
      return;
    }

    setLoading(true);
    try {
      // Simulating API call
      //   await new Promise((resolve) => setTimeout(resolve, 1000));
      setContactTypes([...contactTypes, { id: Date.now(), type: contactType }]);
      setContactType("");
      setError("");
    } catch (err) {
      setError("Failed to add contact type");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      // Simulating API call
      //   await new Promise((resolve) => setTimeout(resolve, 500));
      setContactTypes(contactTypes.filter((type) => type.id !== id));
    } catch (err) {
      setError("Failed to delete contact type");
    } finally {
      setLoading(false);
    }
  };


  return (
    <Box>
      <Button
        variant="contained"
        onClick={handleOpen}
        aria-label="Open add contact type popup"
      >
        Open Popup
      </Button>

      <StyledDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="contact-type-dialog-title"
      >
        <DialogTitle id="contact-type-dialog-title">
          <Typography variant="h6" component="div" fontWeight="bold">
            Add Contract Type
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "row",
                gap: 2,
                mt: 2,
                mb: 4,
              }}
            >
              <TextField
                size="small"
                fullWidth
                label="Contract Type"
                value={contactType}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleAdd();
                  }
                }}
                // if have any value then remove the error

                onChange={(e) => {
                  if (e.target.value) {
                    setError("");
                  }
                  setContactType(e.target.value);
                }}
                error={Boolean(error)}
                helperText={error}
                disabled={loading}
                sx={{
                  flex: 1,
                  height: "37px",
                  "& .MuiFormHelperText-root": {
                    textAlign: "left",
                    marginLeft: 0,
                  },
                }}
                inputProps={{
                  "aria-label": "Contract type input",
                }}
                InputLabelProps={{ shrink: true }}
              />

              <Button
                variant="contained"
                onClick={handleAdd}
                disabled={loading}
                sx={{
                  background: "#1976d2",
                  "&:hover": { background: "#1565c0" },
                }}
                aria-label="Add contract type"
              >
                {loading ? <CircularProgress size={24} /> : "Add"}
              </Button>
            </Box>

            {contactTypes.length > 0 && <StyledTableContainer
              component={Paper}
              sx={{
                boxShadow: "none",
                border: "1px solid rgba(224, 224, 224, 1)",
              }}
            >
              <Table stickyHeader aria-label="contact types table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ background: "#f5f5f5" }}>
                      Contract Type
                    </TableCell>
                    <TableCell align="right" sx={{ background: "#f5f5f5" }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[...contactTypes].reverse().map((item) => (
                    <TableRow
                      key={item.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {item.type}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() => handleDelete(item.id)}
                          color="error"
                          disabled={loading}
                          aria-label="Delete contact type"
                        >
                          <FaRegTrashAlt size={15} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>}

            <Fade in={loading}>
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "rgba(255, 255, 255, 0.7)",
                  zIndex: 1,
                }}
              >
                <CircularProgress />
              </Box>
            </Fade>
          </Box>
        </DialogContent>
      </StyledDialog>
    </Box>
  );
};
