import React, { useState } from "react";
import {
  TextField,
  Button,
  Autocomplete,
  Box,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import Pagelayout from "../components/Pagelayout";
import { Trash } from "lucide-react";
import { useNavigate } from "react-router-dom";

const CreateBatchPayslip = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    batchName: "",
    fromDate: "",
    toDate: "",
    branch: null,
    department: null,
    establishment: null,
    generatePayslip: false,
  });

  const branches = ["Branch 1", "Branch 2", "Branch 3"];
  const departments = ["Dept 1", "Dept 2", "Dept 3"];
  const establishments = ["Est 1", "Est 2", "Est 3"];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAutocompleteChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleSave = () => {
    console.log("Form data:", formData);
  };

  const handleGeneratePayslip = () => {
    setFormData({ ...formData, generatePayslip: true });
  };

  return (
    <Pagelayout
      handleBack={() => navigate("/userdashboard/payroll/batchpayslip")}
      generatePayslip={true}
      handleGeneratePayslip={handleGeneratePayslip}
      headerLayout={2}
    >
      <Box display="flex" flexDirection="column" gap={2} width="100%" m="auto">
        <Grid container spacing={2}>
          {/* First row with three inputs */}
          <Grid item xs={4}>
            <TextField
              label="Batch Name"
              name="batchName"
              value={formData.batchName}
              onChange={handleChange}
              fullWidth
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="From Date"
              name="fromDate"
              type="date"
              value={formData.fromDate}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="To Date"
              name="toDate"
              type="date"
              value={formData.toDate}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
              size="small"
            />
          </Grid>

          {/* Second row with remaining inputs */}
          <Grid item xs={4}>
            <Autocomplete
              options={branches}
              value={formData.branch}
              onChange={(e, value) => handleAutocompleteChange("branch", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Branch"
                  fullWidth
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              options={departments}
              value={formData.department}
              onChange={(e, value) =>
                handleAutocompleteChange("department", value)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Department"
                  fullWidth
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              options={establishments}
              value={formData.establishment}
              onChange={(e, value) =>
                handleAutocompleteChange("establishment", value)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Establishment"
                  fullWidth
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        {/* <Box mt={2} display="flex" justifyContent="center">
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Box> */}
      </Box>
      <Dialog
        open={formData.generatePayslip}
        onClose={() => setFormData({ ...formData, generatePayslip: false })}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          p={2}
          gap={2}
        >
          <Box
            gap={2}
            display="flex"
            justifyContent={"space-between"}
            flexDirection="row"
            alignItems="center"
            p={1}
          >
            <Box>
              <Typography variant="subtitle2">{"branch"}</Typography>
              <Typography
                variant="subtitle1"
                fontWeight="bold"
                fontSize="1.2em"
              >
                {"branch name"}
              </Typography>
            </Box>
            <Box>
              <Button
                sx={{ width: "10em" }}
                variant="contained"
                color="secondary"
              >
                Post
              </Button>
            </Box>
          </Box>

          <Divider />

          <Box
            sx={{
              "&::-webkit-scrollbar": {
                width: "0.5em",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "gray",
                borderRadius: "10px",
              },
            }}
            p={1}
            gap={2}
            display="grid"
            gridTemplateColumns="1fr 1fr"
            maxHeight="50vh"
            overflow="auto"
          >
            {Array.from({ length: 100 }).map((_, index) => (
              <Box
                key={index}
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#f1eeff",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "0.5em",
                  //   add gray hover effect
                  "&:hover": {
                    backgroundColor: "#fffff",
                  },
                }}
              >
                <Typography variant="subtitle3">
                  EMP0{index + 1}- Vaisak{" "}
                </Typography>
                <IconButton sx={{ backgroundColor: "white" }}>
                  <Trash color="red" size={16} />
                </IconButton>
              </Box>
            ))}
          </Box>
        </Box>

        {/* <DialogActions >
          <Button
            variant="outlined" color="primary"
            onClick={() => setFormData({ ...formData, generatePayslip: false })}
          >
            Cancel
          </Button>

          <Button variant="contained" color="primary">
            Post
          </Button>
        </DialogActions> */}
      </Dialog>
    </Pagelayout>
  );
};

export default CreateBatchPayslip;
