import React, { useState } from "react";
import {
  Box,
  Pagination,
  TextField,
  Typography,
  Container,
  Paper,
  IconButton,
  Fade,
} from "@mui/material";
import { styled } from "@mui/system";
import { FaArrowRight } from "react-icons/fa";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: "0px",
  display: "flex",
  flexDirection: "row", // Change to row to align items in a single row
  alignItems: "center", // Adjust to center items vertically
  gap: "20px",
  justifyContent: "flex-end", // Space out items evenly
  // backgroundColor: "#ffffff",
  boxShadow: "none",
  // borderRadius: "12px",
  // transition: "transform 0.3s ease",
  // "&:hover": {
  //   transform: "translateY(-2px)"
  // }
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPaginationItem-root": {
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: "#e3f2fd",
      transform: "scale(1.1)",
    },
    "&.Mui-selected": {
      backgroundColor: "#2196f3",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#1976d2",
      },
    },
  },
}));

const PageInput = styled(TextField)(({ theme }) => ({
  width: "100px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    transition: "all 0.3s ease",
    "&:hover": {
      "& fieldset": {
        borderColor: "#2196f3",
      },
    },
  },
}));

const CustomPagination = ({ totalPages = 10, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [inputPage, setInputPage] = useState("");

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    onPageChange?.(page);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (value === "" || /^\d+$/.test(value)) {
      setInputPage(value);
    }
  };

  const handleInputSubmit = () => {
    const pageNumber = parseInt(inputPage, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      onPageChange?.(pageNumber);
      setInputPage("");
    } else {
      setInputPage("");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleInputSubmit();
    }
  };

  return (
    <div>
      <Fade in timeout={500}>
        <StyledPaper sx={{p:0}} role="navigation" aria-label="Pagination Navigation">
          <StyledPagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            size="small"
            showFirstButton
            showLastButton
            aria-label="pagination"
          />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography
              variant="body1"
              color="textSecondary"
              aria-live="polite"
              fontSize={"14px"}
            >
              Page {currentPage} of {totalPages}
            </Typography>

            <PageInput
              value={inputPage}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              placeholder="Go"
              type="text"
              size="small"
              sx={{
                // width:"25%"
              }}
          
              inputProps={{
                "aria-label": "Go to page input",
                min: 1,
                max: totalPages,
                style:{fontSize:'12px',padding:"5px 12px", }
              }}
            />

            <IconButton
              onClick={handleInputSubmit}
              color="primary"
              aria-label="Go to page"
              sx={{
                fontSize:'10px',
                backgroundColor: "#e3f2fd",
                "&:hover": {
                  backgroundColor: "#bbdefb",
                },
              }}
            >
              <FaArrowRight />
            </IconButton>
          </Box>
        </StyledPaper>
      </Fade>
    </div>
  );
};

export default CustomPagination;
