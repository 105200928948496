import React, { useState } from "react";
import Pagelayout from "../../components/Pagelayout";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  TextField,
  Box,
  Card,
  CardContent,
  Grid,
} from "@mui/material";

const CreateLoanEligibility = () => {
  const [employeeData, setEmployeeData] = useState({
    department: "",
    minimumSalary: "",
    outstandingAmount: "",
  });
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/userdashboard/payroll/loanEligibility/");
  };
  const mockData = {
    departments: ["HR", "Finance", "Marketing", "Sales", "IT"],
  };
  const handleEmployeeDataChange = (field, newValue) => {
    setEmployeeData({ ...employeeData, [field]: newValue });
  };

  const renderAutoComplete = (field, label, options) => (
    <Autocomplete
      options={options}
      value={employeeData[field]}
      renderInput={(params) => (
        <TextField {...params} label={label} size="small" InputLabelProps={{ shrink: true }} />
      )}
      onChange={(_, newValue) => handleEmployeeDataChange(field, newValue)}
      isOptionEqualToValue={(option, value) =>
        option === value || (option === null && value === "")
      }
    />
  );

  return (
    <Pagelayout
      headerLayout={2}
      pageName={"Create Loan Eligibility"}
      handleBack={handleBack}
    >
      <Box>
        <Card>
          <CardContent sx={{ p: 3 }}>
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  {renderAutoComplete(
                    "department",
                    "Department",
                    mockData.departments
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <TextField
                    label="Minimum Salary"
                    size="small"
                    fullWidth
                    value={employeeData.minimumSalary}
                    onChange={(e) =>
                      handleEmployeeDataChange("minimumSalary", e.target.value)
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <TextField
                    label="Outstanding Amount"
                    size="small"
                    fullWidth
                    value={employeeData.outstandingAmount}
                    onChange={(e) =>
                      handleEmployeeDataChange(
                        "outstandingAmount",
                        e.target.value
                      )
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Pagelayout>
  );
};

export default CreateLoanEligibility;
