import axios from "axios";

////*BASE URL LOCAL

// frontend
// axios.defaults.baseURL= process.env.REACT_APP_LOCAL_BASE_URL_FRONTEND;


// SARANG 34
// axios.defaults.baseURL= process.env.REACT_APP_LOCAL_BASE_URL_SARANG;

// kshanith prakash
// axios.defaults.baseURL= process.env.REACT_APP_LOCAL_BASE_URL_kshanith;


// JOWHAR 30

// test server build ip jowhar 30
//  axios.defaults.baseURL = process.env.REACT_APP_LOCAL_BASE_URL_JOWHAR;



// SINAN 13
// axios.defaults.baseURL= process.env.REACT_APP_LOCAL_BASE_URL_SINAN;

// SAVAD 28
// axios.defaults.baseURL = process.env.REACT_APP_LOCAL_BASE_URL_SAVAD;

//*BASE URL PRODUCTION  .com
axios.defaults.baseURL = process.env.REACT_APP_PRODUCTION_BASE_URL_TAILORS;

//*BASE URL PRODUCTION  .com  TAILORS
// axios.defaults.baseURL = process.env.REACT_APP_TAILORS_PRODUCTION_BASE_URL;

// *** BASE URL PRODUCTION UAE
// axios.defaults.baseURL=process.env.REACT_APP_PRODUCTION_BASE_URL_UAE

// *** BASE URL TEST 
// axios.defaults.baseURL=process.env.REACT_APP_TEST_BASE_URL

//*HEADERS:
//json Body
export const HEADERS = {
  headers: {
    "Content-Type": "application/json",
  },
};
//Query
export const HEADER_QUERY = {
  "Content-Type": "application/json",
};
//FormData
export const HEADER_FORM_DATA = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};
