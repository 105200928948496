import React from 'react';
import Pagelayout from '../../components/Pagelayout';
import { Wallet, Tag, User, Building2, CheckCircle, XCircle } from 'lucide-react';
import './payotherStyles.css';
import { useNavigate } from 'react-router-dom';

const PayOthersPayroll = () => {
  const navigate = useNavigate();
  // Sample data array with 50 items
  const payOthersData = Array(50).fill().map((_, index) => ({
    category: 'BONUS',
    name: `EID BONUS ${index + 1}`,
    amount: '100 AED',
    defaultAccount: 'Salaries a/c',
    isActive: true
  }));

  const handleCreatePayOthers = () => {
    navigate("create");
  }

  return (
    <Pagelayout pageName="Pay Others" headerLayout={1} handleCreateBtn={handleCreatePayOthers}>
      <div className="py-ot-new-crd-container">
        {payOthersData.map((item, index) => (
          <div key={index} className="py-ot-new-crd">
            <div className="py-ot-new-crd-header">
              <div className="py-ot-new-crd-category">
                <Tag size={18} className="icon" />
                <div className="category-content">
                  <span className="label">Category</span>
                  <span className="value">{item.category}</span>
                </div>
              </div>
              <div className={`py-ot-new-crd-status ${item.isActive ? 'active1' : 'inactive'}`}>
                {item.isActive ? (
                  <CheckCircle size={16} className="status-icon" />
                ) : (
                  <XCircle size={16} className="status-icon" />
                )}
                {item.isActive ? 'Active' : 'Inactive'}
              </div>
            </div>

            <div className="py-ot-new-crd-name">
              <User size={18} className="icon" />
              <div className="name-content">
                <span className="label">Name</span>
                <span className="value">{item.name}</span>
              </div>
            </div>

            <div className="py-ot-new-crd-footer">
              <div className="py-ot-new-crd-amount">
                <Wallet size={18} className="icon" />
                <div className="amount-content">
                  <span className="label">Amount</span>
                  <span className="value">{item.amount}</span>
                </div>
              </div>
              <div className="py-ot-new-crd-account">
                <Building2 size={18} className="icon" />
                <div className="account-content">
                  <span className="label">Default Account</span>
                  <span className="value">{item.defaultAccount}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Pagelayout>
  );
};

export default PayOthersPayroll;