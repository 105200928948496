import React, { useState } from "react";
import CustomTable from "../components/ListPageComponent";
import Pagelayout from "../components/Pagelayout";
import { useNavigate } from "react-router-dom";

const SalaryDisbursement = () => {
  // Example usage:
  const headers = [
    { key: "name", label: "Name" },
    { key: "accountingDate", label: "Accounting Date" },
    { key: "paymentMethod", label: "Payment Method" },
    { key: "paymentType", label: "Payment Type" },
    { key: "status", label: "Status" },
  ];

  const data = [
    {
      name: "EMp12 - VAISHAK",
      accountingDate: "24/08/2023",
      paymentMethod: "Cheque",
      paymentType: "Salary",
      status: "Draft",
    },
    {
      name: "EMp12 - VAISHAK",
      accountingDate: "24/08/2023",
      paymentMethod: "Cheque",
      paymentType: "Salary",
      status: "Post",
    },
    {
      name: "EMp13 - JAY",
      accountingDate: "24/08/2023",
      paymentMethod: "Bank Transfer",
      paymentType: "Bonus",
      status: "Draft",
    },
  ];

  // Only show specific columns
  const visibleColumns = [
    "name",
    "accountingDate",
    "paymentMethod",
    "paymentType",
    "status",
  ];
  const [isGridView, setIsGridView] = useState(false);
  const navigate = useNavigate();

  const handleRowClick = (rowData) => {
    console.log("Clicked row data:", rowData);
  };
  const handleCreateBtn = () => {
    // console.log("Create button clicked");
    navigate("create");
  };

  return (
    <Pagelayout pageName={"Salary Disbursement"} headerLayout={1} handleCreateBtn={handleCreateBtn}>
      <CustomTable
        headers={headers}
        data={data}
        visibleColumns={visibleColumns}
        onRowClick={handleRowClick}
        loading={false}
        
      />
    </Pagelayout>
  );
};

export default SalaryDisbursement;
