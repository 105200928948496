import React, { useState } from "react";
import Pagelayout from "../components/Pagelayout";
import { Grid, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CreateSponsorPayslip = () => {
    const navigate = useNavigate()
    const [sponsorData, setSponsorData] = useState({
        name: "",
        sponsorNo: "",
        sponsorExpiry: "",
    })
    const handleSponsorDataChange = (field, value) => {
        setSponsorData({...sponsorData, [field]: value})
    }
    const handleSaveBtn = () => {
        console.log(sponsorData)
    }
    const handleBackBtn = () => {
        navigate("/userdashboard/payroll/sponsorpayslip")
    }
  return (
    <Pagelayout pageName={"Create Sponsor Payslip"} headerLayout={2} handleBack={handleBackBtn} handleSave={handleSaveBtn} newData={true}>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Name"
            size="small"
            InputLabelProps={{ shrink: true }}
            value={sponsorData.name}
            onChange={(e) => handleSponsorDataChange("name", e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Sponsor No"
            size="small"
            InputLabelProps={{ shrink: true }}
            value={sponsorData.sponsorNo}
            onChange={(e) => handleSponsorDataChange("sponsorNo", e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Sponsor Expiry"
            size="small"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={sponsorData.sponsorExpiry}
            onChange={(e) => handleSponsorDataChange("sponsorExpiry", e.target.value)}
          />
        </Grid>
      </Grid>
    </Pagelayout>
  );
};

export default CreateSponsorPayslip;
