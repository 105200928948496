import React, { useState } from "react";
import { Box, Card, CardContent, Typography, Avatar, Tooltip, Snackbar, IconButton, Grid, Divider } from "@mui/material";
import { styled } from "@mui/system";
import { FiCopy, FiCheck } from "react-icons/fi";
import { FaBuilding, FaBriefcase, FaCalendarAlt, FaUserTie, FaBlackTie } from "react-icons/fa";

const StyledCard = styled(Card)({
  maxWidth: "500px",
  width: "500px",
  // margin: "5px",
  padding: "16px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  boxSizing: "border-box", 
  transition: "border-color 0.3s ease",
  border: "1px solid rgba(0, 0, 0, 0.12)", 
  boxShadow:"none",
  "&:hover": {
    borderColor: "rgba(0, 0, 0, 0.2)" 
  }
});



const DetailSection = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginBottom: "12px",
  gap: "8px"
});

const Label = styled(Typography)({
  fontWeight: 600,
  minWidth: "120px"
});

const Value = styled(Typography)({
  flex: 1,
  display: "flex",
  alignItems: "center",
  gap: "8px"
});

const InfoRow = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginBottom: "5px",
  gap: "12px"
});

const PaySlipCard = () => {
  const [copied, setCopied] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const employeeData = {
    name: "John Doe",
    employeeId: "EMP123456",
    profilePicture: "https://images.unsplash.com/photo-1633332755192-727a05c4013d",
    period: "January 2024",
    branch: "Main Office",
    contractType: "Full-time",
    designation: "Senior Developer",
    department: "Engineering"
  };

  const handleCopy = (text, field) => {
    navigator.clipboard.writeText(text);
    setCopied(field);
    setSnackbarOpen(true);
    setTimeout(() => {
      setCopied("");
      setSnackbarOpen(false);
    }, 2000);
  };

  const renderDetail = (label, value, copyable = false) => (
    <DetailSection>
      <Label variant="subtitle2">{label}:</Label>
      <Value variant="body2">
        {value}
        {copyable && (
          <Tooltip title="Copy to clipboard">
            <IconButton
              size="small"
              onClick={() => handleCopy(value, label)}
              sx={{ padding: "4px" }}
              aria-label={`Copy ${label}`}
            >
              {copied === label ? <FiCheck /> : <FiCopy />}
            </IconButton>
          </Tooltip>
        )}
      </Value>
    </DetailSection>
  );

  const renderInfoItem = (icon, label, value) => (
    <InfoRow>
      <IconButton
        aria-label={label}
        sx={{
          backgroundColor: "#f5f5f5",
          "&:hover": { backgroundColor: "#e0e0e0" }
        }}
      >
        {icon}
      </IconButton>
      <Box>
        <Typography variant="caption" color="textSecondary">
          {label}
        </Typography>
        <Typography variant="body1" fontWeight="medium">
          {value || "Not Available"}
        </Typography>
      </Box>
    </InfoRow>
  );

  return (
    <StyledCard role="article" aria-label="Employee Pay Slip"  >
      <CardContent>
        <Box sx={{ display: "flex", alignItems: "center", marginBottom: "20px", }}>
          <Avatar
            src={employeeData.profilePicture}
            alt="Employee profile picture"
            sx={{ width: 80, height: 80, marginRight: "16px" }}
          />
          <Box>
            <Typography variant="h5" component="h1" gutterBottom>
              {employeeData.name}
            </Typography>
            {renderDetail("Employee ID", employeeData.employeeId, true)}
          </Box>
        </Box>
        <Divider sx={{ my: 2 }} />

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            {renderInfoItem(<FaBuilding size={20} />, "Branch", employeeData.branch)}
            {renderInfoItem(<FaBriefcase size={20} />, "Contract Type", employeeData.contractType)}
            {renderInfoItem(<FaCalendarAlt size={20} />, "Period", employeeData.period)}
          </Grid>
          <Grid item xs={12} md={6}>
            {renderInfoItem(<FaUserTie size={20} />, "Designation", employeeData.designation)}
            {renderInfoItem(<FaBlackTie  size={20} />, "Department", employeeData.department)}
          </Grid>
        </Grid>
      </CardContent>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        message="Copied to clipboard"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </StyledCard>
  );
};

export default PaySlipCard;
