import React, { useState, useEffect } from "react";
import "./componentStyles.css";
import PageHeader from "./PageHeader";

const Pagelayout = ({
  children,
  isGridView,
  setIsGridView,
  handleCreateBtn,
  headerLayout,
  pageName,
  handleBack,
  newData, 
  handleEdit,
  handleSave,
  generatePayslip,
  handleGeneratePayslip,
  gridView,
  filter,
  searchBox,
  createBtn,
  pagination,
  actionBtn
}) => {


  return (
    <div className="page-layout-wrapper">
      <div className={`page-layout-container `}>
        <PageHeader
          isGridView={isGridView}
          setIsGridView={setIsGridView}
          handleCreateBtn={handleCreateBtn}
          pageName={pageName}
          headerLayout={headerLayout}
          handleBack={handleBack}
          newData={newData}
          handleEdit={handleEdit}
          handleSave={handleSave}
          generatePayslip={generatePayslip}
          handleGeneratePayslip={handleGeneratePayslip}
          gridView={gridView}
          filter={filter}
          searchBox={searchBox}
          createBtn={createBtn}
          pagination={pagination}
          actionBtn={actionBtn}
        />
        <div style={{ padding: "0.5em" }}>{children}</div>
      </div>
    </div>
  );
};

export default Pagelayout;
