import React, { useEffect, useState } from "react";
import Pagelayout from "../components/Pagelayout";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Box,
  Typography,
  Paper,
  Grid,
  Autocomplete,
  IconButton,
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
} from "@mui/material";

import "./payslipStyles.css";

import { Delete, Edit } from "@mui/icons-material";

// Custom Tab Component
// CustomTab.jsx
const CustomTab = ({ label, isActive, onClick }) => (
  <button
    className={`custom-tab ${isActive ? "active" : ""}`}
    onClick={onClick}
  >
    {label}
  </button>
);
// Custom TabPanel Component
const CustomTabPanel = ({ children, value, index }) => (
  <div role="tabpanel" hidden={value !== index} className="custom-tab-panel">
    {value === index && children}
  </div>
);
const CreatePayslip = () => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(0);

  // Mock data - In real application, these would come from API
  const mockData = {
    employees: ["Emp12- Vaishak", "Emp13- John"],
    branches: ["Dubai", "Abu Dhabi"],
    designations: ["ACCOUNTANT", "MANAGER"],
    departments: ["FINANCE", "HR"],
    contracts: ["VYSHAK CONTRACT", "STANDARD CONTRACT"],
    sponsors: ["LEEYET", "SPONSOR2"],
    templates: ["ACCOUNTANT SALARY TEMPLATE", "BASIC TEMPLATE"],
    components: ["Basic", "Food", "Transport", "Tax"],
  };

  const [employeeData, setEmployeeData] = useState({
    employee: null,
    branch: null,
    fromDate: "2023-08-01",
    toDate: "2023-09-01",
    designation: null,
    department: null,
    contract: null,
    sponsor: null,
    template: null,
    accountDate: "2023-09-01",
    otherInputs: [],
    earnings: [
      { component: "Basic", code: "BSC", amount: "500.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
      { component: "Food", code: "Food", amount: "110.00" },
    ],
    deductions: [{ component: "Tax", code: "TAX", amount: "300.00" }],
  });

  const [workDays, setWorkDays] = useState({
    totalWorkingDays: "",
    paidDays: "",
    unpaidDays: "",
  });

  const [otherInputForm, setOtherInputForm] = useState({
    component: null,
    description: "",
    amount: "",
  });

  const [editingIndex, setEditingIndex] = useState(null);

  const handleTabChange = (newValue) => setCurrentTab(newValue);

  const handleInputChange = (event, field) => {
    setWorkDays((prevData) => ({
      ...prevData,
      [field]: event.target.value,
    }));
  };

  const handleEmployeeDataChange = (field, value) => {
    setEmployeeData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleAddOtherInput = () => {
    if (!otherInputForm.component || !otherInputForm.amount) return;

    if (editingIndex !== null) {
      setEmployeeData((prevData) => ({
        ...prevData,
        otherInputs: prevData.otherInputs.map((item, index) =>
          index === editingIndex ? { ...otherInputForm } : item
        ),
      }));
      setEditingIndex(null);
    } else {
      setEmployeeData((prevData) => ({
        ...prevData,
        otherInputs: [...prevData.otherInputs, { ...otherInputForm }],
      }));
    }
    setOtherInputForm({ component: null, description: "", amount: "" });
  };

  const handleEditOtherInput = (index) => {
    setOtherInputForm(employeeData.otherInputs[index]);
    setEditingIndex(index);
  };

  const handleDeleteOtherInput = (index) => {
    setEmployeeData((prevData) => ({
      ...prevData,
      otherInputs: prevData.otherInputs.filter((_, i) => i !== index),
    }));
  };

  const handleEarningDeductionEdit = (type, index) => {
    console.log(`Editing ${type} at index ${index}`);
  };

  const handleEarningDeductionDelete = (type, index) => {
    setEmployeeData((prevData) => ({
      ...prevData,
      [type]: prevData[type].filter((_, i) => i !== index),
    }));
  };

  const renderAutoComplete = (field, label, options) => (
    <Autocomplete
      options={options}
      value={employeeData[field]}
      onChange={(_, newValue) => handleEmployeeDataChange(field, newValue)}
      isOptionEqualToValue={(option, value) =>
        option === value || (option === null && value === "")
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );

  const tabs = [
    { label: "WORK DAYS", id: 0 },
    { label: "OTHER INPUTS", id: 1 },
    { label: "SALARY CALCULATION", id: 2 },
  ];

  return (
    <Pagelayout
      handleBack={() => navigate("/userdashboard/payroll/payslip")}
      headerLayout={2}
    >
      <Card sx={{ maxWidth: "100%", mx: "auto", p: 3 }}>
        <Typography variant="h6" sx={{ mb: 2, color: "text.secondary" }}>
          PAYSLIP/2023/10/0001
        </Typography>

        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={6} md={3}>
            {renderAutoComplete("employee", "Employee", mockData.employees)}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {renderAutoComplete("branch", "Branch", mockData.branches)}
          </Grid>
          {["fromDate", "toDate"].map((field, index) => (
            <Grid item xs={12} sm={6} md={3} key={field}>
              <TextField
                fullWidth
                size="small"
                label={field === "fromDate" ? "From" : "To"}
                type="date"
                value={employeeData[field]}
                onChange={(e) =>
                  handleEmployeeDataChange(field, e.target.value)
                }
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          ))}
        </Grid>

        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} sm={6} md={3}>
            {renderAutoComplete(
              "designation",
              "Designation",
              mockData.designations
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {renderAutoComplete(
              "department",
              "Department",
              mockData.departments
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {renderAutoComplete("contract", "Contract", mockData.contracts)}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {renderAutoComplete("sponsor", "Sponsor", mockData.sponsors)}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {renderAutoComplete("template", "Template", mockData.templates)}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              size="small"
              label="Account Date"
              type="date"
              value={employeeData.accountDate}
              onChange={(e) =>
                handleEmployeeDataChange("accountDate", e.target.value)
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>

        {/* Custom Tabs */}
        <div className="">
          <div className="tab-wrapper-py">
            {tabs.map((tab) => (
              <CustomTab
                key={tab.id}
                label={tab.label}
                isActive={currentTab === tab.id}
                onClick={() => handleTabChange(tab.id)}
              />
            ))}
          </div>
        </div>

        <CustomTabPanel value={currentTab} index={0}>
          <Grid container spacing={2}>
            {[
              { label: "Total Working Days", field: "totalWorkingDays" },
              { label: "Paid Days", field: "paidDays" },
              { label: "Unpaid Days", field: "unpaidDays" },
            ].map(({ label, field }) => (
              <Grid item xs={12} sm={4} key={field}>
                <TextField
                  fullWidth
                  label={label}
                  size="small"
                  type="number"
                  value={workDays[field]}
                  onChange={(event) => handleInputChange(event, field)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </CustomTabPanel>

        <CustomTabPanel value={currentTab} index={1}>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={4}>
              <Autocomplete
                options={mockData.components}
                value={otherInputForm.component}
                onChange={(_, value) =>
                  setOtherInputForm((prev) => ({ ...prev, component: value }))
                }
                isOptionEqualToValue={(option, value) =>
                  option === value || (option === null && value === "")
                }
                renderInput={(params) => (
                  <TextField 

                   {...params} label="Component" size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Description"
                size="small"
                fullWidth
                value={otherInputForm.description}
                onChange={(e) =>
                  setOtherInputForm((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Amount"
                size="small"
                fullWidth
                type="number"
                value={otherInputForm.amount}
                onChange={(e) =>
                  setOtherInputForm((prev) => ({
                    ...prev,
                    amount: e.target.value,
                  }))
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                onClick={handleAddOtherInput}
                disabled={!otherInputForm.component || !otherInputForm.amount}
              >
                {editingIndex !== null ? "Update" : "Add"}
              </Button>
            </Grid>
          </Grid>

          <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Component</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {employeeData.otherInputs.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.component}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.amount}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleEditOtherInput(index)}>
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteOtherInput(index)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CustomTabPanel>

        {/* <CustomTabPanel value={currentTab} index={2}>
          {["earnings", "deductions"].map((section) => (
            <TableContainer
              key={section}
              component={Paper}
              sx={{ maxHeight: 300, mt: 2 }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Component</TableCell>
                    <TableCell>Code</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employeeData[section].map((item, idx) => (
                    <TableRow key={idx}>
                      <TableCell>{item.component}</TableCell>
                      <TableCell>{item.code}</TableCell>
                      <TableCell>{item.amount}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => handleEarningDeductionEdit(section, idx)}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          onClick={() => handleEarningDeductionDelete(section, idx)}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableRow>
                  <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>
                    TOTAL {section.toUpperCase()}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {employeeData[section]
                      .reduce((sum, item) => sum + parseFloat(item.amount), 0)
                      .toFixed(2)}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </Table>
            </TableContainer>
          ))}
        </CustomTabPanel> */}
        <CustomTabPanel value={currentTab} index={2}>
          {["earnings", "deductions"].map((section) => (
            <TableContainer
              key={section}
              component={Paper}
              sx={{ maxHeight: 300, mt: 2 }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Component</TableCell>
                    <TableCell>Code</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employeeData[section].map((item, idx) => (
                    <TableRow key={idx}>
                      <TableCell>{item.component}</TableCell>
                      <TableCell>{item.code}</TableCell>
                      <TableCell>{item.amount}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            handleEarningDeductionEdit(section, idx)
                          }
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            handleEarningDeductionDelete(section, idx)
                          }
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                {/* Sticky Footer Row */}
                <TableFooter>
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      sx={{
                        position: "sticky",
                        bottom: 0,
                        backgroundColor: "white",
                        fontWeight: "bold",
                      }}
                    >
                      TOTAL {section.toUpperCase()}
                    </TableCell>
                    <TableCell
                      sx={{
                        position: "sticky",
                        bottom: 0,
                        backgroundColor: "white",
                        fontWeight: "bold",
                      }}
                    >
                      {employeeData[section]
                        .reduce((sum, item) => sum + parseFloat(item.amount), 0)
                        .toFixed(2)}
                    </TableCell>
                    <TableCell
                      sx={{
                        position: "sticky",
                        bottom: 0,
                        backgroundColor: "white",
                      }}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          ))}
        </CustomTabPanel>

        {currentTab === 2 && (
          <Paper sx={{ p: 2, mt: 4 }}>
            <Grid container>
              <Grid item xs={8}>
                <Typography variant="h6">NET SALARY</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" align="right">
                  {employeeData.earnings.reduce(
                    (sum, item) => sum + parseFloat(item.amount),
                    0
                  ) -
                    employeeData.deductions.reduce(
                      (sum, item) => sum + parseFloat(item.amount),
                      0
                    )}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Card>
    </Pagelayout>
  );
};

export default CreatePayslip;
