import React from 'react'
import { DollarSign, Briefcase, Calculator, Calendar } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import "./paymentTemplateStyles.css"
import Pagelayout from '../../components/Pagelayout';
import { Grid } from '@mui/material';

const PaytemlatePayroll = () => {
    const handleCreatePaytemplate = () => {
        navigate("create");
    }
    const navigate = useNavigate();
    const components = [
        {
          label: 'Basic',
          value: 8900.00,
          type: '% OF CTC',
          percentage: '89.00',
          icon: <DollarSign size={18} />
        },
        {
          label: 'Tel ALW',
          value: 445.00,
          type: '% OF BASIC',
          percentage: '05.00',
          icon: <Calculator size={18} />
        },
        {
          label: 'House ALW',
          value: 445.00,
          type: '% OF BASIC',
          percentage: '05.00',
          icon: <Briefcase size={18} />
        },
        {
          label: 'Food ALW',
          value: 100.00,
          type: 'FIXED',
          icon: <Calendar size={18} />
        },
        {
          label: 'Trans ALW',
          value: 100.00,
          type: 'FIXED',
          icon: <Calendar size={18} />
        },
        {
          label: 'Other ALW',
          value: 10.00,
          type: 'FIXED',
          icon: <Calculator size={18} />
        }
      ];

      const dummyData = [
        {
            name: "SALESMAN SALARY",
            salary: 10000.00
        },
        {
            name: "SALESMAN SALARY",
            salary: 10000.00
        }
      ]
  return (
   <Pagelayout pageName="Pay Template" headerLayout={1} handleCreateBtn={handleCreatePaytemplate}>
    <Grid container spacing={2}>
        {dummyData.map((item, index) => (
        <Grid item xs={12} md={6}>
        <div className="py-new-temp-container">
        <div className="py-new-temp-header">
          <div className="py-new-temp-title">
            <div className="py-new-temp-name">{item.name}</div>
            <div className="py-new-temp-salary">{item.salary?.toFixed(2)} AED</div>
          </div>
          <div className="py-new-temp-fixed-pay">FIXED PAY</div>
        </div>

        <div className="py-new-temp-table">
          <div className="py-new-temp-table-header">
            <div>COMPONENTS</div>
            <div>CALCULATION TYPE</div>
            <div>MONTHLY</div>
            <div>YEARLY</div>
          </div>

          {components.map((component, index) => (
            <div key={index} className="py-new-temp-row">
              <div className="py-new-temp-row-content">
                <div className={`py-new-temp-component ${component.isBlue ? 'blue' : ''}`}>
                  {component.isBlue && <DollarSign size={14} />}
                  {component.label}
                </div>
                <div className="py-new-temp-calculation">
                  {component.type}
                  {component.percentage && (
                    <span className="py-new-temp-percentage">({component.percentage})</span>
                  )}
                </div>
                <div className="py-new-temp-value">{component.monthly?.toFixed(2)}</div>
                <div className="py-new-temp-value">{component.yearly?.toFixed(2)}</div>
              </div>
              <div className="py-new-temp-dotted-line"></div>
            </div>
          ))}

          <div className="py-new-temp-total">
            <div>TOTAL</div>
            <div></div>
            <div className="py-new-temp-value">10000.00</div>
            <div className="py-new-temp-value">120000.00</div>
          </div>
        </div>
      </div>
  
            </Grid>
        ))}
    </Grid>
   
   </Pagelayout>
  )
}

export default PaytemlatePayroll