import React, { useState } from "react";
import Pagelayout from "../components/Pagelayout";
import CustomTable from "../components/ListPageComponent";
import PaySlipCard from "../components/PaySlipCard";
import { useNavigate } from "react-router-dom";

const Payslip = () => {
  // Example usage:
 
 const headers = [
        { key: "name", label: "Name" },
        { key: "leaveType", label: "Leave Type" },
        { key: "paidUnpaid", label: "Paid/Unpaid" },
        { key: "from", label: "From" },
        { key: "to", label: "To" },
        { key: "days", label: "No of Days" },
        { key: "status", label: "Status" },
      ];
    
      const data = [
        {
          name: "EMp12 - VAISHAK",
          leaveType: "Sick Leave",
          paidUnpaid: "Paid Leave",
          from: "24/08/2023",
          to: "30/08/2029",
          days: "8 Hours",
          status: "Approved",
        },
        {
          name: "EMp12 - kshanith",
          leaveType: "Sick Leave",
          paidUnpaid: "Paid Leave",
          from: "24/08/2023",
          to: "30/08/2029",
          days: "8 Hours",
          status: "Rejected",
        },
        {
          name: "EMp12 - kshanith",
          leaveType: "Sick Leave",
          paidUnpaid: "Paid Leave",
          from: "24/08/2023",
          to: "30/08/2029",
          days: "8 Hours",
          status: "complete",
        },
        {
          name: "EMp12 - kshanith",
          leaveType: "Sick Leave",
          paidUnpaid: "Paid Leave",
          from: "24/08/2023",
          to: "30/08/2029",
          days: "8 Hours",
          status: "pending",
        },
        {
          name: "EMp12 - kshanith",
          leaveType: "Sick Leave",
          paidUnpaid: "Paid Leave",
          from: "24/08/2023",
          to: "30/08/2029",
          days: "8 Hours",
          status: "done",
        },
        {
          name: "EMp12 - kshanith",
          leaveType: "Sick Leave",
          paidUnpaid: "Paid Leave",
          from: "24/08/2023",
          to: "30/08/2029",
          days: "8 Hours",
          status: "draft",
        },
        {
          name: "EMp12 - kshanith",
          leaveType: "Sick Leave",
          paidUnpaid: "Paid Leave",
          from: "24/08/2023",
          to: "30/08/2029",
          days: "8 Hours",
          status: "post",
        },
      ];
  // Only show specific columns
  const visibleColumns = [
    "name",
    "leaveType",
    "paidUnpaid",
    "from",
    "to",
    "days",
    "status",
  ];
  const [isGridView, setIsGridView] = useState(false);
  const navigate = useNavigate();

  const handleRowClick = (rowData) => {
    console.log("Clicked row data:", rowData);
  };
  const handleCreateBtn = () => {
    // console.log("Create button clicked");
    navigate("create");
  };
  return (
    <Pagelayout
      headerLayout={1}
      pageName={"Payroll"}
      handleCreateBtn={handleCreateBtn}
      isGridView={isGridView}
      setIsGridView={setIsGridView}
      gridView={true}
    >
      {!isGridView ? (
        <CustomTable
          headers={headers}
          data={data}
          visibleColumns={visibleColumns}
          onRowClick={handleRowClick}
          loading={false}
        />
      ) : (
        <div style={{ display: "flex", flexWrap: "wrap", gap: "1em" }}>
          {Array(20)
            .fill(null)
            .map((r, i) => (
              <PaySlipCard />
            ))}
        </div>
      )}
    </Pagelayout>
  );
};

export default Payslip;
