import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import store from '../../../../../Redux/store';
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import { get_sales_order_row_data_list } from './salesOrderSlice';
import { salesOrderFilterAPICall, viewSalesOrdersListAPICall } from './salesOrderAPI';
import { convertDateFormat, getCurrentTime } from '../../../../../Js/Date';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import CustomPagination from '../../../../Single Components/CustomPagination';
import axios from 'axios';
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';
import ReactToPrint from 'react-to-print';
import ExcelJS from "exceljs"

export const SalesOrder = () => {
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const tableListData = useSelector((state) => state.salesOrderSlice.apiResList);
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [filteredData,setFilteredData]=useState()
  const totalPages = tableListData?.pages;

  let navigate = useNavigate();

  const handleDataFromChild = (data) => {
   
    setFormDataInfo(data)
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };

  const userRole = useSelector((state) => state.userRoleSlice.value)
  const userBranchId = localStorage.getItem("branchId")



  const createBranchTransfer = () => {
    navigate("/userdashboard/sales/orders/salesOrder/create")

  }
  const [isLoading, setIsLoading] = useState(false)

  const singleViewFn = (row) => {
    store.dispatch( setFilterActive(window.location.pathname))
    //  store.dispatch(get_sales_order_row_data_list({salesOrderSingleData:row}))
    //navigate("/userdashboard/sales/orders/salesOrder/create")
    localStorage.setItem("singleViewById", row?._id)
    navigate("/userdashboard/sales/orders/salesOrder/singleView")
  }
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const handleFilterChange=(updatedItems)=>{
    setFilteredData(updatedItems)
  }
  const formatFilterInfo = (filters) => {
    const parts = [];
  
    if (filters?.toDate && filters.fromDate) {
      parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
    }
    if (filters?.branchList) {
      parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
    }
   
    if (filters?.status) {
      parts.push(`Status: ${filters?.status}`);
    }
    if (filters?.createdBy) {
      parts.push(`created By: ${filters?.createdBy?.staff_name}`);
    }
    
    return parts; 
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Sales Order");
    sheet.properties.defaultRowHeight = 20;
  
    // Add header section
    const titleRow = sheet.addRow(["Sales Order"]);
    titleRow.font = { size: 15, bold: true };
    sheet.mergeCells('A1:F1');
    titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
  
    // Add additional header information
    const filterParts = formatFilterInfo(filteredData) || [];
  
    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 11, bold: true };
        sheet.mergeCells(`A${index + 2}:F${index + 2}`);
        row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
      });
    }
  
    // Add an empty row between header and table
    sheet.addRow([]);
  
    // Header row styling
    const headerRow = sheet.addRow(["Date", "Name","Customer","Total","Created By","Status"]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 12,
      bold: true,
      color: { argb: "FF000000" }, // White font color
    };
  
    headerRow.eachCell((cell, colNumber) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
    })
  
    sheet.columns = [
      { header: "Date", key: "quotationDate", width: 18 },
      { header: "Name", key: "NAME", width: 20 },
      { header: "Customer", key: "CUSTOMER", width: 25 },
      { header: "Total", key: "total", width: 15 },
      { header: "Created By", key: "CREATEDBY", width: 25 },
      { header: "Sales Order", key: "status", width: 15 },
  
    ];
  
    tableListData?.list?.forEach((item,index) => {
      const row = sheet.addRow({
        quotationDate:convertDateFormat(item?.quotationDate),
        NAME: item.NAME,
        CUSTOMER: item?.CUSTOMER,
        total: item?.total,
        CREATEDBY: item?.CREATEDBY,
        status: item?.status,
      
      });
  
      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };
      
      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
        //   top: { style: "thin", color: { argb: "FF000000" } },
        //   left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FFedf2f9" } },
        //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber >= 1) { // Right align all columns except the first
            cell.alignment = { horizontal: 'center' };
          }
         
      });
    });
  
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Sales Order.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
   
    if (FormDataInfo?.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&salesOrderFilterAPICall({
        statusType: 2,
        fromDate: FormDataInfo?.fromDate === '' ? '' : `${FormDataInfo?.fromDate} 00:00:00`,
      toDate: FormDataInfo?.toDate === '' ? '' : `${FormDataInfo?.toDate} 23:59:59`,
        createdBy: FormDataInfo?.createdBy,
        branchList: FormDataInfo?.branchList,
        status: FormDataInfo?.status?.length === 0 ? '' : FormDataInfo?.status[0],
        search: FormDataInfo?.search,
      }, setIsLoading);
    }
 
  }, [FormDataInfo]);

 
  console.log(FormDataInfo);



  useEffect(() => {
    // if (userRole === "user") {
    //   viewSalesOrdersListAPICall({ branchId: userBranchId }, setIsLoading)
    // }
    // else {
    //   viewSalesOrdersListAPICall({}, setIsLoading)
    // }
    localStorage.setItem("singleViewById", "")
    store.dispatch(get_sales_order_row_data_list({ salesOrderSingleData: undefined }))
  }, [])

  return (
    <div className='global-page-parent-container'>
      {/* table */}
      <div className='global-white-bg-container'>
        <div className="justify-space-between"
          style={{ gap: "0 12px", display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}
        >
          {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
               <h3>Branch name: 
                  {selectedBranch?.storeCode||filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                  -{selectedBranch?.branchName||filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>
              </div>
            )}
             <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap:'9px',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
               <div style={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="Share">
                  <IconButton>
                    <i
                      class="bi bi-share"
                      style={{
                        backgroundColor: "#9797e9",
                        color: "white",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>

                <ReactToPrint
                  trigger={() => (
                    <IconButton
                      className="printer-div"
                      style={{ padding: "1px" }}
                    >
                      <i
                        style={{
                          color: "white",
                          backgroundColor: " #d787e7",
                          fontSize: "medium",
                          padding: "8px",
                          borderRadius: "2px",
                        }}
                        class="bi bi-printer"
                      ></i>
                    </IconButton>
                  )}
                  // content={() => paymentsPrintNew}
                />
                <Tooltip title="Download">
                  <IconButton onClick={downloadList}>
                    <i
                      class="bi bi-arrow-down-circle"
                      style={{
                        backgroundColor: "rgb(255 118 133 / 69%)",
                        color: "white",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>
          <CategorySearchandFilter
            statusOptions={['SALES ORDER', 'DONE']}
            onData={handleDataFromChild}
            isCreatedBy={true}
            isStatus={true}
            onBranchSelect={handleBranchSelect}
            onFilterChange={handleFilterChange}
          />
          <button onClick={createBranchTransfer} className='create-button-blue'>Create</button>
          </div>
          {/* <CategorySearchandFilter
            statusOptions={['SALES ORDER', 'DONE']}
            onData={handleDataFromChild}
            isCreatedBy={true}
            isStatus={true}
            onBranchSelect={handleBranchSelect}
          />
          <button onClick={createBranchTransfer} className='create-button-blue'>Create</button> */}
          </div>
        </div>
        <div className='create-button-blue-container'>
          <h3>Sales Order</h3>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
          {/* <button onClick={createBranchTransfer}  className='create-button-blue'>Create</button> */}
        </div>
        <div className='new-global-table-container'>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Customer</th>
                <th>Total</th>
                <th>Created By</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody >
              {

                isLoading ? (
                  [...Array(10)].map((r, i) => (
                    <tr key={i}>
                      <td colSpan={8}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) :

                  tableListData?.list !== undefined && tableListData?.list?.length !== 0 ? tableListData?.list?.slice(0)?.map((r, i) => (
                    <tr onClick={() => singleViewFn(r)}>
                      <td>{convertDateFormat(r?.quotationDate)}</td>
                      <td>{r?.sequenceName || r?.NAME}</td>
                      <td>{r?.CUSTOMER}</td>
                      <td>{r?.total}</td>
                      <td>{r?.CREATEDBY}</td>
                      <td className='table-data-box'>
                        {r?.status === "SALES ORDER" ? (
                          <span className='sales-order'> Sale Order</span>
                        ) : r?.status === "DONE" ? (
                          <span className='post'> Done</span>
                        ) : (
                          <span>{r?.status}</span>
                        )}
                      </td>
                    </tr>
                  ))
                    :
                    <tr>
                      <td colSpan={7}>No Data</td>
                    </tr>
              }
            </tbody>

          </table>
        </div>
      </div>

    </div>
  )
}
