import { Skeleton } from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import dummyProductItemImg from "../../../../../Assets/BillingImages/emptyFoodImg.svg";
import useProducts from '../Utilities/useProducts';
import BarcodeReader from "react-barcode-reader";
import { errorSnackMsg } from '../../../../Custom Hooks/SnackBarUtilities';

const ProductsList = ({ addToCart, isLoading,selectedCategory,specialItemCat }) => {
    const decimalPosition = localStorage.getItem('decimalPosition');
    const userInfo = useSelector((state) => state.loginResponseSlice.value);
    const { productsList, productType } = useSelector((state) => state.newBillingSlice);
    const { tableLoading } = useSelector((state) => state.mainDashBoardSlice);
    const { searchBarInput } = useSelector((state) => state.topBarSliceBilling);
    const { selectedPOSCategory } = useSelector((state) => state.billingScreenSlice);
    const { data, loading } = useProducts({ branchId: userInfo?.branchPk });

    const [filteredData, setFilteredData] = useState([]);

    // Create a Map for quick lookup of products by name
    const productMap = useMemo(() => {
        const map = new Map();
        const sourceData = specialItemCat ? productsList : data || [];
        
        sourceData.forEach(product => {
            map.set(product.productName.toLowerCase(), product);
        });
        return map;
    }, [data, productsList, specialItemCat]);

    const productMapForBarcode = useMemo(() => {
        const map = new Map();
        (data || []).forEach(product => {
            if (product.barcode) map.set(product.barcode, product);
        });
        return map;
    }, [data]);
    // Filter products based on search query
    const getFilteredData = (searchQuery) => {
        const lowerSearchQuery = searchQuery?.toLowerCase();
        const results = [];

        productMap.forEach((product) => {
            const matchesSearch = lowerSearchQuery ? product?.productName.toLowerCase().includes(lowerSearchQuery) : true;
            const matchesCategory = selectedCategory ? product.mainCatgeoryId === selectedCategory : true; // Replace 'categoryId' with actual field
            const matchesSubCategory = selectedPOSCategory ? product.poscat === selectedPOSCategory : true; // Replace 'subCategoryId' with actual field
            
            if (matchesSearch && matchesCategory && matchesSubCategory) {
                results.push(product);
            }
        });

        return results;
    };

    const onBarcodeScan = (barcode) => {
        const product = productMapForBarcode.get(Number(barcode));
        if (product) {
          addToCart(product)
        } else {
          errorSnackMsg(("Product not found !!"))
        }
      };
    useEffect(() => {
        setFilteredData(getFilteredData(searchBarInput));
    }, [searchBarInput, productMap,selectedPOSCategory,selectedCategory]); 


    const renderSkeletons = (count) => (
        [...Array(count)].map((_, i) => (
            <div key={i} className="billing-single-product-container">
                <Skeleton width="100%" height="100px" />
                <Skeleton width="100%" height="20px" />
                <div className="billing-product-list-select-container">
                    <Skeleton width="60%" height="20px" />
                    <Skeleton width="30%" height="30px" />
                </div>
            </div>
        ))
    );
    return (
        <div className='cards'>
            {(loading || isLoading) ? ( renderSkeletons(10)) :
             filteredData.length !== 0 ? (
                filteredData.map((product, index) => (
                    <div
                        className="card-content"
                        key={index}
                        onClick={() => addToCart(product)}
                        style={{ maxHeight: "fit-content" }}
                    >
                        <img
                            src={product?.imageUrl && product?.imageUrl.length !== 0
                                ? product?.imageUrl
                                : dummyProductItemImg}
                            alt="No Img"
                            className="product-image"
                        />
                        <div className="card-details">
                            <h1>{product.productName}</h1>
                            {productType === 3 ? (
                                product?.productList?.map((item, i) => (
                                    <p style={{ display: "flex", flexWrap: "wrap" }} key={i}>
                                        <span style={{ overflow: "hidden" }}>
                                            {item?.productName}
                                        </span>
                                        <span style={{ marginLeft: "auto" }}>
                                            {item.qty}
                                        </span>
                                    </p>
                                ))
                            ) : (
                                <p>
                                    {product.salesPrice?.toFixed(decimalPosition)}
                                </p>
                            )}
                        </div>
                       
                    </div>
                ))
            ) : (
                <div className="center-div" style={{ height: "61vh" }}>
                    No Products Available !!
                </div>
            )}
            {tableLoading &&  renderSkeletons(10)}

          <BarcodeReader onScan={onBarcodeScan} />

        </div>
    );
}

export default ProductsList;
