import { IconButton, Skeleton, TextField, Tooltip } from "@mui/material";
import React, { useRef, useState } from "react";
import { getCurrentTime, previousDate, today } from "../../../../../Js/Date";
import { useEffect } from "react";
import { trailBalanceListAPI } from "./TrailBalanceAPI";
import { useSelector } from "react-redux";
import { Key } from "@mui/icons-material";
import { viewAllCurrencyUpdated } from "../../../../../API/Settings/Currency/CurrencyAPI";
import { companyProfileInfoForUser } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { filterObjFromList } from "../../../../../Js/generalFunctions";
import ReactToPrint from "react-to-print";
import PrintComponent from "../../../../print/report/PrintComponent";
import ExcelJS from "exceljs"

export const TrialBalance = () => {
  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  // console.log(userInfo);
  let componentRef = useRef();
  const configurationList = useSelector(
    (state) => state.glConfigurationSlice.apiResList
  );

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [symbol, setSymbol] = useState(null);

  // State variables for column totals
  const [debitOpeningTotal, setDebitOpeningTotal] = useState(0);
  const [creditOpeningTotal, setCreditOpeningTotal] = useState(0);
  const [debitTransactionTotal, setDebitTransactionTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [creditTransactionTotal, setCreditTransactionTotal] = useState(0);
  const [debitClosingTotal, setDebitClosingTotal] = useState(0);
  const [creditClosingTotal, setCreditClosingTotal] = useState(0);
  const decimalPosition = localStorage.getItem("decimalPosition");
  //trail balance list
  const TrailBalance = useSelector(
    (state) => state.trailbalance.trailBalanceListData
  );
  // console.log(TrailBalance);
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Trial Balance");
    sheet.properties.defaultRowHeight = 20;
  
    // Add header section
    const titleRow = sheet.addRow(["TRIAL BALANCE"]);
    titleRow.font = { size: 18, bold: true };
    sheet.mergeCells('A1:G1');
    titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };

     // Add row for date range
     const fromDateRow = sheet.addRow([`As Of : ${fromDate} to ${toDate}`]);
     fromDateRow.font = { size: 15, bold: true };
     sheet.mergeCells('A2:G2');
     fromDateRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
  
    // Add an empty row between header and table
    sheet.addRow([]);
  
    // Header row styling
    const headerRow = sheet.addRow(["Particular", "Opening Balance", "", "Transaction", "", "Closing Balance", ""]);
    const headerRow2 = sheet.addRow(["", "Debit", "Credit", "Debit", "Credit", "Debit", "Credit"]);
    
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF264A95" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 15,
      bold: true,
      color: { argb: "FFFFFFFF" }, // White font color
    };
  
    // Merge cells for "Opening Balance", "Transaction", and "Closing Balance"
    sheet.mergeCells('B3:C3');
    sheet.mergeCells('D3:E3');
    sheet.mergeCells('F3:G3');
  
    headerRow.eachCell((cell) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
    });
    headerRow2.eachCell((cell) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
    });
  
    sheet.columns = [
      { header: "Particular", key: "accNameAndCode", width: 50 },
      { header: "Debit", key: "debitOpeningBalance", width: 20 },
      { header: "Credit", key: "creditOpeningBalance", width: 20 },
      { header: "Debit", key: "debitTransactionAmount", width: 20 },
      { header: "Credit", key: "creditTransactionAmount", width: 20 },
      { header: "Debit", key: "debitClosingBalance", width: 20 },
      { header: "TRIAL BALANCE", key: "creditClosingBalance", width: 20 },
    ];
  
    TrailBalance?.forEach((item) => {
      const row = sheet.addRow({
        accNameAndCode: item?.accNameAndCode,
        debitOpeningBalance: item.openingBalance >= 0 ? ` ${item.openingBalance?.toFixed(decimalPosition)}` : "0.00",
        creditOpeningBalance: item.openingBalance < 0 ? ` ${Math.abs(item.openingBalance?.toFixed(decimalPosition))}` : "0.00",
        debitTransactionAmount: item.transactionAmount >= 0 ? ` ${item.transactionAmount?.toFixed(decimalPosition)}` : "0.00",
        creditTransactionAmount: item.transactionAmount < 0 ? ` ${Math.abs(item.transactionAmount?.toFixed(decimalPosition))}` : "0.00",
        debitClosingBalance: item.closingBalance >= 0 ? ` ${item.closingBalance?.toFixed(decimalPosition)}` : "0.00",
        creditClosingBalance: item.closingBalance < 0 ? ` ${Math.abs(item.closingBalance?.toFixed(decimalPosition))}` : "0.00",
      });
  
      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };
      
      row.eachCell((cell,colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          top: { style: "thin", color: { argb: "FFC2CBF9" } },
          left: { style: "thin", color: { argb: "FFC2CBF9" } },
          bottom: { style: "thin", color: { argb: "FFC2CBF9" } },
          right: { style: "thin", color: { argb: "FFC2CBF9" } },
        };
        if (colNumber >= 1) { // Right align all columns except the first
          cell.alignment = { horizontal: 'right' };
        }
      });
    });
  

    // Add row for totals
// Add row for totals
const totalRow = sheet.addRow([
  "Total",
  `${symbol} ${debitOpeningTotal.toFixed(decimalPosition)}`,
  `${symbol} ${Math.abs(creditOpeningTotal).toFixed(decimalPosition)}`,
  `${symbol} ${debitTransactionTotal.toFixed(decimalPosition)}`,
  `${symbol} ${Math.abs(creditTransactionTotal).toFixed(decimalPosition)}`,
  `${symbol} ${debitClosingTotal.toFixed(decimalPosition)}`,
  `${symbol} ${Math.abs(creditClosingTotal).toFixed(decimalPosition)}`,
]);


  
    totalRow.eachCell((cell,colNumber) => {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
      cell.fill = headerFill;
      cell.border = {
        top: { style: "thin", color: { argb: "FFFFFFFF" } },
        left: { style: "thin", color: { argb: "FFFFFFFF" } },
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
        right: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
      if (colNumber >= 1) { // Right align all columns except the first
        cell.alignment = { horizontal: 'right' };
      }
    });
  
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Trial Balance.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  
  //currency
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );

  //company info
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );

  const getFromDate = (e) => {
    setFromDate(e.target.value);
  };
  const getToDate = (e) => {
    setToDate(e.target.value);
  };
  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    // console.log(currencyObj);
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  useEffect(() => {
    // Calculate column totals
    let debitOpening = 0;
    let creditOpening = 0;
    let debitTransaction = 0;
    let creditTransaction = 0;
    let debitClosing = 0;
    let creditClosing = 0;

    TrailBalance?.forEach((item) => {
      debitOpening += Math.max(item.openingBalance, 0);
      creditOpening += Math.min(item.openingBalance, 0);
      debitTransaction += Math.max(item.transactionAmount, 0);
      creditTransaction += Math.min(item.transactionAmount, 0);
      debitClosing += Math.max(item.closingBalance, 0);
      creditClosing += Math.min(item.closingBalance, 0);
    });

    // Update state outside the loop
    setDebitOpeningTotal(debitOpening);
    setCreditOpeningTotal(creditOpening);
    setDebitTransactionTotal(debitTransaction);
    setCreditTransactionTotal(creditTransaction);
    setDebitClosingTotal(debitClosing);
    setCreditClosingTotal(creditClosing);
  }, [TrailBalance]);

  // useEffect(() => {
  //   trailBalanceListAPI({
  //     startDate: fromDate === '' ? '' : `${fromDate} 00:00:00`,
  //     endDate: toDate === '' ? '' : `${toDate} 23:59:59`,
  //   },setIsLoading);
  // }, [fromDate, toDate]);

  useEffect(() => {
    if (toDate !== undefined && fromDate !== undefined) {
      const startDate = fromDate === "" ? "" : `${fromDate} 00:00:00`;
      const endDate = toDate === "" ? "" : `${toDate} 23:59:59`;

      trailBalanceListAPI({
        startDate: startDate,
        endDate: endDate,
      });
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);

  useEffect(() => {
    if (configurationList !== undefined) {
      setToDate(configurationList?.yearEndDate);
      setFromDate(configurationList?.yearStartDate);
    }
  }, [configurationList]);

  const [lastIndex, setLastIndex] = useState(null);
  const printStyles = {
    "@media print": {
      "@page": {
        size: "landscape",
      },
    },
  };

  return (
    <div className="top-main-container">
      <div className="secound-main-container-div">
        <div className="first-div-container action-btns-container">
          <div>
            <Tooltip title="Share">
              <IconButton>
                <i
                  class="bi bi-share"
                  style={{
                    backgroundColor: "#9797e9",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>
          </div>
          <div>
            <ReactToPrint
              trigger={() => (
                <Tooltip title="Print">
                  <IconButton
                    className="printer-div"
                    style={{ padding: "1px" }}
                  >
                    <i
                      style={{
                        color: "white",
                        backgroundColor: " #d787e7",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                      class="bi bi-printer"
                    ></i>
                  </IconButton>
                </Tooltip>
              )}
              content={() => componentRef}
            />
          </div>
          <div>
            <Tooltip title="Download">
              <IconButton onClick={downloadList}>
                <i
                  class="bi bi-arrow-down-circle"
                  style={{
                    backgroundColor: "rgb(255 118 133 / 69%)",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <div className="top-main-container" style={{ width: "32%" }}>
          <div className="date-con-div" style={{ marginRight: "-57px" }}>
            <div className="new-global-single-input" style={{ width: "41%" }}>
              <TextField
                value={fromDate}
                onChange={getFromDate}
                id="outlined-basic"
                label="From"
                variant="outlined"
                type="date"
                focused
                disabled={""}
                InputProps={{
                  inputProps: {
                    max: today, // Assuming you want to set a max date
                  },
                  inputProps: {
                    style: { textAlign: "center" }, // Adjust the text alignment
                  },
                }}
              />
              <p className="doc-validation-alert">{""}</p>
            </div>

            <div className="new-global-single-input" style={{ width: "41%" }}>
              <TextField
                value={toDate}
                onChange={getToDate}
                id="outlined-basic"
                label="To"
                variant="outlined"
                type="date"
                focused
                disabled={""}
                InputProps={{
                  inputProps: {
                    max: today,
                  },
                  inputProps: {
                    style: { textAlign: "center" },
                  },
                }}
              />
              <p className="doc-validation-alert">{""}</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p style={{ fontWeight: 600, fontSize: "1em", marginLeft: "1em" }}>
          Trail Balance
        </p>
        <div className="table-container">
          <table className="table">
            <thead>
              <tr className="table-head">
                <td rowSpan="2" style={{ borderRadius: "10px 0 0 0" }}>
                  Particular
                </td>
                <td colSpan="2">Opening Balance</td>
                <td colSpan="2">Transaction</td>
                <td colSpan="2" style={{ borderRadius: "0 10px 0 0" }}>
                  Closing Balance
                </td>
              </tr>
              <tr className="table-head2">
                <td>Debit</td>
                <td>Credit</td>
                <td>Debit</td>
                <td>Credit</td>
                <td>Debit</td>
                <td>Credit</td>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={8}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <>
                  {TrailBalance?.map((item, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "start" }}>
                        &nbsp;{item.accNameAndCode}
                      </td>
                      <td>
                        {symbol}&nbsp;
                        {item.openingBalance >= 0
                          ? item.openingBalance?.toFixed(decimalPosition)
                          : "0.00"}
                      </td>
                      <td>
                        {symbol}&nbsp;
                        {item.openingBalance < 0
                          ? Math.abs(
                              item.openingBalance?.toFixed(decimalPosition)
                            )
                          : "0.00"}
                      </td>
                      <td>
                        {symbol}&nbsp;
                        {item.transactionAmount >= 0
                          ? item.transactionAmount?.toFixed(decimalPosition)
                          : "0.00"}
                      </td>
                      <td>
                        {symbol}&nbsp;
                        {item.transactionAmount < 0
                          ? Math.abs(
                              item.transactionAmount?.toFixed(decimalPosition)
                            )
                          : " 0.00"}
                      </td>
                      <td>
                        {symbol}&nbsp;
                        {item.closingBalance >= 0
                          ? item.closingBalance?.toFixed(decimalPosition)
                          : " 0.00"}
                      </td>
                      <td>
                        {symbol}&nbsp;
                        {item.closingBalance < 0
                          ? Math.abs(
                              item.closingBalance?.toFixed(decimalPosition)
                            )
                          : "0.00"}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
            <tfoot>
              <tr className="table-head2">
                <td
                  style={{
                    backgroundColor: "rgb(38, 74, 149)",
                    color: "white",
                    textAlign: "center",
                    fontWeight: "500",
                    borderRadius: "0 0 0 10px",
                  }}
                >
                  Total
                </td>
                <td style={{ border: "none" }}>
                  {symbol}&nbsp;{debitOpeningTotal?.toFixed(decimalPosition)}
                </td>
                <td style={{ border: "none" }}>
                  {symbol}&nbsp;
                  {Math.abs(creditOpeningTotal)?.toFixed(decimalPosition)}
                </td>
                <td style={{ border: "none" }}>
                  {symbol}&nbsp;
                  {debitTransactionTotal?.toFixed(decimalPosition)}
                </td>
                <td style={{ border: "none" }}>
                  {symbol}&nbsp;
                  {Math.abs(creditTransactionTotal)?.toFixed(decimalPosition)}
                </td>
                <td style={{ border: "none" }}>
                  {symbol}&nbsp;{debitClosingTotal?.toFixed(decimalPosition)}
                </td>
                <td style={{ border: "none", borderRadius: "0 0 10px 0" }}>
                  {symbol}&nbsp;
                  {Math.abs(creditClosingTotal)?.toFixed(decimalPosition)}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      {/* print container */}
      <div style={{ display: "none" }}>
        <div
          ref={(el) => (componentRef = el)}
          className="print-container-trail-balance"
          style={{ "@media print": { "@page": { size: "landscape" } } }}
        >
          <div className="trailbalnce-print-header">
            <div className="company-logo-container">
              <img
                height={100}
                width={100}
                src={userInfo?.logo || "http://placehold.it/100x100"}
                alt=""
              />
            </div>
            <div className="trail-balance-print-title">
              <span>Trail Balance</span>
              <span style={{ fontSize: "13px" }}> As Off {toDate}</span>
            </div>
            <div className="company-details-container">
              <span>{userInfo?.data?.profile?.companyName} </span>
              <span>{userInfo?.data?.profile?.companyAddress} </span>
              <span>{userInfo?.data?.profile?.companyEmail}</span>
            </div>
          </div>
          <div>
            <div className="table-container">
              <table className="table">
                <thead>
                  <tr className="table-head">
                    <td rowSpan="2" style={{ borderRadius: "10px 0 0 0" }}>
                      Particular
                    </td>
                    <td colSpan="2">Opening Balance</td>
                    <td colSpan="2">Transaction</td>
                    <td colSpan="2" style={{ borderRadius: "0 10px 0 0" }}>
                      Closing Balance
                    </td>
                  </tr>
                  <tr className="table-head2">
                    <td>Debit</td>
                    <td>Credit</td>
                    <td>Debit</td>
                    <td>Credit</td>
                    <td>Debit</td>
                    <td>Credit</td>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    [...Array(10)].map((r, i) => (
                      <tr key={i}>
                        <td colSpan={8}>
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={"100%"}
                            height={40}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <>
                      {TrailBalance?.map((item, index) => {
                        if (
                          index === TrailBalance.length - 1 &&
                          lastIndex === null
                        ) {
                          setLastIndex(index);
                        }
                        return (
                          <tr key={index}>
                            <td style={{ textAlign: "start" }}>
                              &nbsp;{item.accNameAndCode}
                            </td>
                            <td>
                              {symbol}&nbsp;
                              {item.openingBalance >= 0
                                ? item.openingBalance?.toFixed(decimalPosition)
                                : "0.00"}
                            </td>
                            <td>
                              {symbol}&nbsp;
                              {item.openingBalance < 0
                                ? Math.abs(
                                    item.openingBalance?.toFixed(
                                      decimalPosition
                                    )
                                  )
                                : "0.00"}
                            </td>
                            <td>
                              {symbol}&nbsp;
                              {item.transactionAmount >= 0
                                ? item.transactionAmount?.toFixed(
                                    decimalPosition
                                  )
                                : "0.00"}
                            </td>
                            <td>
                              {symbol}&nbsp;
                              {item.transactionAmount < 0
                                ? Math.abs(
                                    item.transactionAmount?.toFixed(
                                      decimalPosition
                                    )
                                  )
                                : " 0.00"}
                            </td>
                            <td>
                              {symbol}&nbsp;
                              {item.closingBalance >= 0
                                ? item.closingBalance?.toFixed(decimalPosition)
                                : " 0.00"}
                            </td>
                            <td>
                              {symbol}&nbsp;
                              {item.closingBalance < 0
                                ? Math.abs(
                                    item.closingBalance?.toFixed(
                                      decimalPosition
                                    )
                                  )
                                : "0.00"}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={7}></td>
                  </tr>
                </tfoot>
                {lastIndex !== null &&
                  TrailBalance?.length - 1 === lastIndex && (
                    <tbody>
                      <tr className="table-head2">
                        <td
                          style={{
                            backgroundColor: "rgb(38, 74, 149)",
                            color: "white",
                            textAlign: "center",
                            fontWeight: "500",
                            borderRadius: "0 0 0 10px",
                          }}
                        >
                          Total
                        </td>
                        <td style={{ border: "none" }}>
                          {symbol}&nbsp;
                          {debitOpeningTotal?.toFixed(decimalPosition)}
                        </td>
                        <td style={{ border: "none" }}>
                          {symbol}&nbsp;
                          {Math.abs(creditOpeningTotal)?.toFixed(
                            decimalPosition
                          )}
                        </td>
                        <td style={{ border: "none" }}>
                          {symbol}&nbsp;
                          {debitTransactionTotal?.toFixed(decimalPosition)}
                        </td>
                        <td style={{ border: "none" }}>
                          {symbol}&nbsp;
                          {Math.abs(creditTransactionTotal)?.toFixed(
                            decimalPosition
                          )}
                        </td>
                        <td style={{ border: "none" }}>
                          {symbol}&nbsp;
                          {debitClosingTotal?.toFixed(decimalPosition)}
                        </td>
                        <td
                          style={{ border: "none", borderRadius: "0 0 10px 0" }}
                        >
                          {symbol}&nbsp;
                          {Math.abs(creditClosingTotal)?.toFixed(
                            decimalPosition
                          )}
                        </td>
                      </tr>
                    </tbody>
                  )}
              </table>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div style={{ position: "relative" }} ref={(el) => (componentRef = el)}>
          <PrintComponent pageHeight={55} header={"TRAIL BALANCE"}>
          
            <table >
              <thead>
                <tr className="table-head1-new">
                  <th rowSpan="2">Particular</th>
                  <th colSpan="2">Opening Balance</th>
                  <th colSpan="2">Transaction</th>
                  <th colSpan="2">Closing Balance</th>
                </tr>
                <tr className="table-head2-new">
                  <th >Debit</th>
                  <th >Credit</th>
                  <th >Debit</th>
                  <th >Credit</th>
                  <th >Debit</th>
                  <th >Credit</th>
                </tr>
              </thead>
              <tbody>
                {TrailBalance?.map((item, index) => (
                  
                  <>
                    <tr key={index}>
                      <td>&nbsp;{item.accNameAndCode}</td>
                      <td>
                        {symbol}&nbsp;
                        {item.openingBalance >= 0
                          ? item.openingBalance?.toFixed(decimalPosition)
                          : "0.00"}
                      </td>
                      <td>
                        {symbol}&nbsp;
                        {item.openingBalance < 0
                          ? Math.abs(
                              item.openingBalance?.toFixed(decimalPosition)
                            )
                          : "0.00"}
                      </td>
                      <td>
                        {symbol}&nbsp;
                        {item.transactionAmount >= 0
                          ? item.transactionAmount?.toFixed(decimalPosition)
                          : "0.00"}
                      </td>
                      <td>
                        {symbol}&nbsp;
                        {item.transactionAmount < 0
                          ? Math.abs(
                              item.transactionAmount?.toFixed(decimalPosition)
                            )
                          : " 0.00"}
                      </td>
                      <td>
                        {symbol}&nbsp;
                        {item.closingBalance >= 0
                          ? item.closingBalance?.toFixed(decimalPosition)
                          : " 0.00"}
                      </td>
                      <td>
                        {symbol}&nbsp;
                        {item.closingBalance < 0
                          ? Math.abs(
                              item.closingBalance?.toFixed(decimalPosition)
                            )
                          : "0.00"}
                      </td>
                    </tr>

                   {TrailBalance.length -1  === index &&  <tr style={{ color: "black", width: "100%" }}>
                      <td
                        style={{
                          backgroundColor: "rgb(38, 74, 149)",
                          color: "white",
                          textAlign: "center",
                          fontWeight: "500",
                          borderRadius: "0 !important",
                          width: "40.5%",
                        }}
                      >
                        Total
                      </td>
                      <td style={{ width: "10%" }}>
                        {symbol}&nbsp;
                        {debitOpeningTotal?.toFixed(decimalPosition)}
                      </td>
                      <td>
                        {symbol}&nbsp;
                        {Math.abs(creditOpeningTotal)?.toFixed(decimalPosition)}
                      </td>
                      <td>
                        {symbol}&nbsp;
                        {debitTransactionTotal?.toFixed(decimalPosition)}
                      </td>
                      <td>
                        {symbol}&nbsp;
                        {Math.abs(creditTransactionTotal)?.toFixed(
                          decimalPosition
                        )}
                      </td>
                      <td>
                        {symbol}&nbsp;
                        {debitClosingTotal?.toFixed(decimalPosition)}
                      </td>
                      <td
                      >
                        {symbol}&nbsp;
                        {Math.abs(creditClosingTotal)?.toFixed(decimalPosition)}
                      </td>
                    </tr>}
                  </>
                ))}
              </tbody>
            </table>
           
          </PrintComponent>
        </div>
      </div>
    </div>
  );
};
