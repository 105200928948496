import React from "react";
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  Paper,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Pagelayout from "../../components/Pagelayout";

// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  //   margin: theme.spacing(2),
  borderRadius: 12,
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
}));

const LabelTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
}));

const PayrollSettings = () => {
  return (
    <Pagelayout
      pageName="Payroll Settings"
      headerLayout={1}
      filter={false}
      pagination={false}
      searchBox={false}
      createBtn={false}
    >
      <Box sx={{ margin: "0 auto" }}>
        <StyledPaper>
          <Stack spacing={3}>
            {/* Salary Period Section */}
            <Box>
              <LabelTypography>SALARY PERIOD</LabelTypography>
              <Stack direction="row" spacing={2}>
                <TextField
                  size="small"
                  fullWidth
                  type="date"
                  label="Day From"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  sx={{ bgcolor: "background.paper" }}
                />
                <TextField
                  size="small"
                  fullWidth
                  type="date"
                  label="Day To"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  sx={{ bgcolor: "background.paper" }}
                />
              </Stack>
            </Box>

            {/* Salary Journal Section */}
            <Box>
              <LabelTypography>SALARY JOURNAL</LabelTypography>
              <Autocomplete
                size="small"
                fullWidth
                options={[]} // Add your options here
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Salary journal"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Box>

            {/* Salary Payables Section */}
            <Box>
              <LabelTypography>SALARY PAYABLES A/C</LabelTypography>
              <Autocomplete
                size="small"
                fullWidth
                options={[]} // Add your options here
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Salaries Payable a/c"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Box>

            {/* Staff Loan Section */}
            <Box>
              <LabelTypography>STAFF LOAN A/C</LabelTypography>
              <Autocomplete
                size="small"
                fullWidth
                options={[]} // Add your options here
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Staff Loan a/c"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Box>
          </Stack>
        </StyledPaper>
      </Box>
    </Pagelayout>
  );
};

export default PayrollSettings;
