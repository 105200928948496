import React, { useState } from "react";
import {
  Grid,
  TextField,
  Autocomplete,
  Paper,
  Typography,
  Box,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import Pagelayout from "../../components/Pagelayout";
import { useNavigate } from "react-router-dom";
import { LeaveTypeDialog } from "../leave/CreatePayrollLeave";

const CreateOvertimePayroll = () => {
  const [showPreviousOT, setShowPreviousOT] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleShowPreviousOT = () => {
    setShowPreviousOT(!showPreviousOT);
  };
  const handleBack = () => {
    navigate("/userdashboard/payroll/overtime");
  };

  const handleSave = () => {
    console.log("save");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Pagelayout
      pageName="Create Overtime Payroll"
      headerLayout={2}
      handleBack={handleBack}
      handleSave={handleSave}
      newData={true}
    >
      <Card elevation={3} sx={{ maxWidth: "100%", mx: "auto", p: 1 }}>
        <CardContent>
          <Box sx={{ p: 2 }}>
            {/* Header with ID */}
            <Typography variant="h6" sx={{ mb: 3, color: "text.secondary" }}>
              OVRT/2023/10/0001
            </Typography>

            {/* Main Form */}
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <Autocomplete
                  size="small"
                  options={[]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Employee"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  size="small"
                  label="Create Date"
                  fullWidth
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Autocomplete
                  size="small"
                  options={["Normal OT"]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Overtime Type"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  size="small"
                  label="Duration"
                  type="number"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Autocomplete
                  size="small"
                  options={["Hour"]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Unit Type"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  size="small"
                  label="Amount"
                  type="number"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  size="small"
                  label="Overtime Amount"
                  type="number"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  size="small"
                  label="Description"
                  multiline
                  rows={4}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>

            {/* Previous OT Details Section */}
            <Box
              sx={{ mt: 4, backgroundColor: "#f8f9fa", p: 2, borderRadius: 1 }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Previous OT details
                </Typography>
                <Button
                  variant="text"
                  color="primary"
                  onClick={handleShowPreviousOT}
                >
                  {showPreviousOT ? "Hide" : "Show"}
                </Button>
              </Box>
              {showPreviousOT && (
                <Grid container spacing={2}>
                  {[1, 2, 3, 4, 5].map((item) => (
                    <Grid item xs={12} sm={6} md={2.4} key={item}>
                      <Card
                        variant="outlined"
                        sx={{ border: "1px dashed grey" }}
                      >
                        <CardContent>
                          <Typography variant="body2" color="text.secondary">
                            03/17/2023
                          </Typography>
                          <Typography variant="body1">OT - 1 Day</Typography>
                          <Typography variant="body2">
                            Amount/Day - 20.00
                          </Typography>
                          <Typography variant="body2">Total - 20.00</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>

            {/* Net Amount Section */}
            {showPreviousOT && (
              <Paper sx={{ mt: 4, p: 2, bgcolor: "#f8f9fa" }}>
                <Typography variant="h6" color="primary">
                  Net pay amount
                </Typography>
                <Typography variant="h5" color="primary" sx={{ mt: 1 }}>
                  Rs 5,0000.00
                </Typography>
              </Paper>
            )}
          </Box>
        </CardContent>
      </Card>
      <LeaveTypeDialog open={open} onClose={handleClose}/>
    </Pagelayout>
  );
};

export default CreateOvertimePayroll;
