import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Skeleton,
  Box,
  Typography,
  styled
} from '@mui/material';
import TableStatusLabel from './StatusLabel';

// Styled components
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    width: '100%',
    // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', 
    border: "1px solid rgba(0, 0, 0, 0.12)", 
    boxShadow:"none",
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '3px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '3px',
      '&:hover': {
        background: '#555',
      },
    },
  }));
  

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  '&.header': {
    backgroundColor: theme.palette.grey[50],
    fontWeight: 600,
    borderBottom: `2px solid ${theme.palette.grey[200]}`,
    zIndex: 1,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    // border: 0,
  },
  transition: theme.transitions.create(['background-color'], {
    duration: theme.transitions.duration.shortest,
  }),
}));

const StatusChip = styled('span')(({ theme, status }) => ({
  padding: theme.spacing(1, 2),
  borderRadius: '16px',
  fontSize: '0.875rem',
  display: 'inline-block',
  ...(status === 'Approved' && {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark,
  }),
  ...(status === 'Rejected' && {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark,
  }),
  ...(status === 'Pending' && {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
  }),
}));

const NoDataBox = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(4),
  textAlign: 'center',
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

const CustomTable = ({ 
  headers = [], 
  data = [], 
  visibleColumns = [], 
  onRowClick,
  loading = false,
  maxHeight = "680px"
}) => {
  const displayHeaders = visibleColumns.length > 0 
    ? headers.filter(header => visibleColumns.includes(header.key))
    : headers;

  const handleRowClick = (rowData) => {
    if (onRowClick) {
      onRowClick(rowData);
    }
  };

  if (loading) {
    return (
      <StyledTableContainer sx={{ maxHeight }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {displayHeaders?.map((header, index) => (
                <StyledTableCell key={index} className="header">
                  <Skeleton animation="wave" height={20} />
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(5)].map((_, rowIndex) => (
              <StyledTableRow key={rowIndex}>
                {displayHeaders.map((_, colIndex) => (
                  <StyledTableCell key={colIndex}>
                    <Skeleton animation="wave" height={20} />
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    );
  }

  if (!data.length) {
    return (
      <NoDataBox>
        <Typography variant="h6" color="textSecondary">
          No data available
        </Typography>
      </NoDataBox>
    );
  }

  return (
    <StyledTableContainer  sx={{ maxHeight, borderBottom:"#ffff" }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {displayHeaders?.map((header, index) => (
              <StyledTableCell
                key={index}
                className="header"
                align={header.numeric ? 'right' : 'left'}
              >
                {header.label}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody >
          {data?.map((row, rowIndex) => (
            <StyledTableRow
              key={rowIndex}
              onClick={() => handleRowClick(row)}
            >
              {displayHeaders?.map((header, colIndex) => (
                <StyledTableCell
                  key={colIndex}
                  align={header.numeric ? 'right' : 'left'}
                  sx={{padding:"4px 10px "}}
                >
                  {header.key === 'status' ? (
                    // <StatusChip status={row[header.key]}>
                    //   {row[header.key]}
                    // </StatusChip>
                    <TableStatusLabel status={row[header.key]}/>
                  ) : (
                    row[header.key]
                  )}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default CustomTable;