import React, { useState, useEffect, useRef } from "react";
import { FaSearch, FaTimes, FaHistory } from "react-icons/fa";
import './advancedSearchStyles.css'

const SearchBox = () => {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [error, setError] = useState("");
  const [recentSearches, setRecentSearches] = useState([]);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  const dummyData = [
    "Apple",
    "Banana",
    "Cherry",
    "Date",
    "Elderberry",
    "Fig",
    "Grape",
    "Honeydew",
    "Kiwi",
    "Lemon"
  ];

  useEffect(() => {
    if (query.trim() === "") {
      setSuggestions([]);
      setError("");
      return;
    }

    const filteredSuggestions = dummyData.filter((item) =>
      item.toLowerCase().includes(query.toLowerCase())
    );

    setSuggestions(filteredSuggestions);
    setError(filteredSuggestions.length === 0 ? "No matching results found." : "");
  }, [query]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
    setIsOpen(true);
    setSelectedIndex(-1);
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    setIsOpen(false);
    addToRecentSearches(suggestion);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setSelectedIndex((prevIndex) =>
        prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : -1));
    } else if (e.key === "Enter" && selectedIndex !== -1) {
      setQuery(suggestions[selectedIndex]);
      setIsOpen(false);
      addToRecentSearches(suggestions[selectedIndex]);
    } else if (e.key === "Tab") {
      e.preventDefault();
      if (suggestions.length > 0) {
        setQuery(suggestions[0]);
        setIsOpen(false);
        addToRecentSearches(suggestions[0]);
      }
    }
  };

  const highlightMatch = (text, query) => {
    const parts = text.split(new RegExp(`(${query})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <span key={index} className="highlight">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const addToRecentSearches = (search) => {
    setRecentSearches((prevSearches) => {
      const updatedSearches = [search, ...prevSearches.filter((s) => s !== search)];
      return updatedSearches.slice(0, 2); // Show only the last 2 searches
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !inputRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="advance-search-container">
      <div className="advance-search-relative">
        <div className="advance-search-relative">
          <input
            ref={inputRef}
            type="text"
            value={query}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Search..."
            className="advance-search-input"
            aria-label="Search"
            aria-autocomplete="list"
            aria-controls="search-suggestions"
            aria-expanded={isOpen}
          />
          <div className="advance-search-clear-container">
            {query ? (
              <FaTimes
                className="advance-search-clear"
                onClick={() => {
                  setQuery("");
                  setIsOpen(false);
                  inputRef.current.focus();
                }}
              />
            ) : (
              <FaSearch className="advance-search-icon" />
            )}
          </div>
        </div>
        {isOpen && (
          <ul
            id="search-suggestions"
            ref={dropdownRef}
            className="advance-search-dropdown"
            role="listbox"
          >
            {recentSearches.length > 0 && (
              <li className="advance-search-label">Recent Searches</li>
            )}
            {recentSearches.map((search, index) => (
              <li
                key={`recent-${index}`}
                onClick={() => handleSuggestionClick(search)}
                className="advance-search-item"
                role="option"
              >
                <FaHistory style={{marginRight:'0.5em'}} className="advance-search-icon" />
                {highlightMatch(search, query)}
              </li>
            ))}
            {suggestions.length > 0 && recentSearches.length > 0 && (
              <li className="advance-search-label">Suggestions</li>
            )}
            {suggestions.map((suggestion, index) => (
              <li
                key={`suggestion-${index}`}
                onClick={() => handleSuggestionClick(suggestion)}
                className={`advance-search-item ${
                  index === selectedIndex ? "selected" : ""
                }`}
                role="option"
                aria-selected={index === selectedIndex}
              >
                {highlightMatch(suggestion, query)}
              </li>
            ))}
          </ul>
        )}
      </div>
      {/* {error && (
        <p className="advance-search-error" role="alert">
          {error}
        </p>
      )} */}
    </div>
  );
};

export default SearchBox;
