import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import store from "../../../../../Redux/store";
import { get_vendor_bill_row_data_list } from "../Slice/vendorBillSlice";
import { convertDateFormat, getCurrentTime } from "../../../../../Js/Date";
import { VendorBillsFilterAPICall, getVendorpoViewAPICall, viewVendorBillAPICall } from "../API/vendorBillAPI";
import { IconButton, Skeleton, Tooltip } from "@mui/material";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../../Single Components/CustomPagination";
import axios from "axios";
import { set_Back_button_action, setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
import ReactToPrint from "react-to-print";
import ExcelJS from "exceljs"

export const VendorBills1 = () => {

  let navigateTo = useNavigate();
  const [path,setPath]= useState(false);
  const VenderPoId =localStorage.getItem("singleViewByIdgrn")
  const [selectedHeading, setSelectedHeading] = useState("Vendor Bill");
  const [filteredArray, setFilteredArray] = useState([]);
  const [FormDataInfo,setFormDataInfo]=useState([])
  const [filteredDatas, setFilteredData] = useState({});
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const tableListData = useSelector(
    (state) => state.vendorBillSlice.apiResList
  );
  const vendorBill = useSelector((state)=> state.vendorBillSlice.PoVendorList)
 
  console.log(vendorBill);
//     const [isLoading,setIsLoading]=useState(false)
//     const handleClickRow=(row)=>{
//       // store.dispatch(get_vendor_bill_row_data_list({vendorBillSingleData:row}))
//       //navigateTo("/userdashboard/accounts/vendor/createvendorBills")
// // console.log("row",row);
//     localStorage.setItem('singleViewById', row._id);
//       navigateTo("/userdashboard/accounts/vendor/SingleView")
//     }
  
//     useEffect(()=>{
//   localStorage.removeItem('singleViewById');
//       store.dispatch(get_vendor_bill_row_data_list(undefined))
//       viewVendorBillAPICall(setIsLoading)
//     },[])


  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const totalPages = filteredArray?.pages;
  const handleClickRow = (row) => {
    store.dispatch( setFilterActive(window.location.pathname))
    // store.dispatch(get_vendor_bill_row_data_list({vendorBillSingleData:row}))
    //navigateTo("/userdashboard/accounts/vendor/createvendorBills")
    // console.log("row",row);
    // get_vendor_bill_row_data_list({ vendorBillSingleRowData: undefined })
    localStorage.removeItem("singleVendorById");
    localStorage.setItem("singleViewById", row._id);
    navigateTo("/userdashboard/accounts/vendor/SingleView");
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };
  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  }

  const formatFilterInfo = (filters) => {
    const parts = [];

    if (filters?.toDate && filters.fromDate) {
      parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
    }
    if (filters?.branchList) {
      parts.push(
        `Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`
      );
    }
    if (filters?.location) {
      parts.push(`location: ${filters?.location?.name}`);
    }
    if (filters?.toLocation) {
      parts.push(`To Location: ${filters?.toLocation?.name}`);
    }
    if (filters?.status) {
      parts.push(`status: ${filters?.status}`);
    }
    if (filters?.createdBy) {
      parts.push(`createdBy: ${filters?.createdBy}`);
    }
    return parts; // Return the array directly
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Vendor Bill");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["Vendor Bill"]);
    titleRow.font = { size: 15, bold: true };
    sheet.mergeCells("A1:H1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add additional header information
    const filterParts = formatFilterInfo(filteredDatas) || [];

    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 11, bold: true };
        sheet.mergeCells(`A${index + 2}:H${index + 2}`);
        row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
      });
    }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow([
      "No",
      "Name",
      "Vendor",
      "Bill Date",
      "Due Date",
      "Bill Refference",
      "Total",
      "Status",
     
    ]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 12,
      bold: true,
      color: { argb: "FF000000" }, // White font color
    };

    headerRow.eachCell((cell, colNumber) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "No", key: "No", width: 10 },
      { header: "Name", key: "name", width: 20 },
      { header: "Vendor", key: "vendor", width: 25 },
      { header: "Bill Date", key: "billDate", width: 25 },
      { header: "Due Date", key: "dueDate", width: 25 },
      { header: "Bill Refference", key: "billreference", width: 15 },
      { header: "Total", key: "total", width: 15 },
      { header: "Vendor Bills", key: "status", width: 20 },
    ];

    tableListData?.list?.forEach((item, index) => {
      const row = sheet.addRow({
        No: index+1 ,
        name: item.name,
        vendor: item.vendor,
        billDate: convertDateFormat(item?.billDate),
        dueDate: convertDateFormat(item?.dueDate),
        billreference: item?.billreference,
        total: item?.total,
        status: item?.status,
        
      });

      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };

      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          //   top: { style: "thin", color: { argb: "FF000000" } },
          //   left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FFedf2f9" } },
          //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber >= 1) {
          // Right align all columns except the first
          cell.alignment = { horizontal: "center" };
        }
      });
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "VendorBills.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  const dispatch = useDispatch();
  useEffect(()=>{
if(VenderPoId !==null){
  vendorBill!==undefined && setFilteredArray(vendorBill)
  setPath(true)
}
else{
  setPath(false)
      localStorage.removeItem("singleViewById");
      VenderPoId === null &&  setFilteredArray(tableListData?.list)
}
  },[vendorBill,tableListData])

  useEffect(() => {
    if(VenderPoId){
      getVendorpoViewAPICall({purchaseId:VenderPoId},setIsLoading);
    }else{
    localStorage.removeItem("singleViewById");
    store.dispatch(
      get_vendor_bill_row_data_list({ vendorBillSingleRowData: undefined })
    );
    // viewVendorBillAPICall(setIsLoading);
}}, [VenderPoId]);


useEffect(() => {

  if(FormDataInfo.length!==0){ 
    let loadData=window.location.pathname===filtereDataOptions?.filterActive
    !loadData &&   
  VendorBillsFilterAPICall({
  paymentStatus: FormDataInfo.status.length!==0 ? (FormDataInfo.status[0]==="PAID"):null,
  fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
  toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
  branchList: FormDataInfo.branchList,
  status:FormDataInfo.status.length!==0 ? (FormDataInfo.status[0]==="DRAFT"?FormDataInfo.status:["COMPLETED"] ):"",
  search: FormDataInfo.search,
  index: currentPage-1,
},setIsLoading);
}

}, [FormDataInfo, currentPage]);

const changeColor = (heading) => {
  setSelectedHeading(heading);
};

const handleDataFromChild = (data) => {
  setFormDataInfo(data)
};

const handleBackpage=()=>{
 // localStorage.removeItem("singleViewByIdgrn")
  navigateTo("/userdashboard/purchaseManager/NewPurchaseOrder")
  dispatch(set_Back_button_action({ backButtonClickAction: true }));
}
  return (
    <div className="global-page-parent-container">
      {/* <p className="breadcrumb">Accounts &gt; Vendors &gt; Vendor Bills</p> */}
      {path && (
        <div className="first-main-container-div">
        <div className="first-main-heading-div" id="headingDiv">
          <p
            style={{
              marginLeft: "14px",
              color: selectedHeading === "Vendor Bill" ?"black" : "gray",
            }}
            onClick={() => {changeColor("Vendor Bill");handleBackpage()}}
          >
            Purchase Order
          </p>
          <p
            style={{
              color: selectedHeading === "purchase Vendor Bill" ? "black" : "gray",
            }}
            //onClick={() => changeColor("purchase Order GRN")}
          >
         Vendor Bill
          </p>
          
        </div>
        </div>
        )}
      <div className="global-white-bg-container">
      <div className="justify-space-between"
        style={{gap:"0 12px",display: 'flex', justifyContent: 'space-between', alignItems: 'end'}}
        >
           {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
                 <h3>Branch name: 
                  {selectedBranch?.storeCode||filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                  -{selectedBranch?.branchName||filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>
              </div>
            )} 
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              // gap:'9px',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
          <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              // gap:'9px',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
           <div style={{ display: "flex", alignItems: "center" }}>
        
        <Tooltip title="Share">
          <IconButton>
            <i
              class="bi bi-share"
              style={{
                backgroundColor: "#9797e9",
                color: "white",
                fontSize: "medium",
                padding: "8px",
                borderRadius: "2px",
              }}
            ></i>
          </IconButton>
        </Tooltip>

        <ReactToPrint
          trigger={() => (
            <IconButton
              className="printer-div"
              style={{ padding: "1px" }}
            >
              <i
                style={{
                  color: "white",
                  backgroundColor: " #d787e7",
                  fontSize: "medium",
                  padding: "8px",
                  borderRadius: "2px",
                }}
                class="bi bi-printer"
              ></i>
            </IconButton>
          )}
          // content={() => paymentsPrintNew}
        />
        <Tooltip title="Download">
          <IconButton onClick={downloadList}>
            <i
              class="bi bi-arrow-down-circle"
              style={{
                backgroundColor: "rgb(255 118 133 / 69%)",
                color: "white",
                fontSize: "medium",
                padding: "8px",
                borderRadius: "2px",
              }}
            ></i>
          </IconButton>
        </Tooltip>
     
  <CategorySearchandFilter
  statusOptions={['COMPLETED','DRAFT','PAID']}
  onData={handleDataFromChild}
  isStatus={true}
  onBranchSelect={handleBranchSelect}
  onFilterChange={handleFilterChange}
  />
  <button
      className="btn btn-primary"
      onClick={() =>
        navigateTo(
          "/userdashboard/accounts/vendor/createvendorBills"
        )
      }
    >
      Create
    </button>
    </div>
            </div>
            </div>
        </div>
        <div className="create-button-blue-container">
          <h4>Vendor Bills</h4>
          {VenderPoId=== null &&(
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />)}
          {/* <button
            className="create-button-blue"
            onClick={() => {
              localStorage.removeItem("singleViewById");
              navigateTo("/userdashboard/accounts/vendor/createvendorBills");
            }}
          >
            Create
          </button> */}
        </div>
      

        <div className="new-global-table-container">
          <table>
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Vendor</th>
                <th>Bill Date</th>
                <th>Due Date</th>
                <th>Bill Refference</th>
                <th>Total</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={8}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : filteredArray !== undefined || filteredArray?.length === 0 ? (
                filteredArray
                  ?.slice(0)
                 
                  .map((r, i) => (
                    <tr
                      key={i}
                      onClick={() => handleClickRow(r)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{i + 1}</td>
                      <td>{r?.name}</td>
                      <td>{r?.vendor}</td>
                      <td>{convertDateFormat(r?.billDate)}</td>
                      <td>
                        {r?.dueDate === "no date"
                          ? r?.dueDate
                          : convertDateFormat(r?.dueDate)}
                      </td>
                      <td>{r?.billreference}</td>
                      <td>{r?.total}</td>
                      <td className="table-data-box">
                        {r?.status === "COMPLETED" ? (
                          <span className="post"> POSTED</span>
                        ) : r?.status === "DRAFT" ?(
                          <span className="draft"> DRAFTED</span>
                        ) : r?.status==="PAID"?(
                            <span className='paid'>PAID</span>
                          ):
                          r?.status==='RETURNED'?(
                            <span className="return">RETURN</span>
                          )
                       : (
                          "---"
                        )}
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={8}>No data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
