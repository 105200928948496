import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Calendar,
  ChevronLeft,
  Filter,
  Building,
  List,
  MapPin,
  LayoutList,
  Building2,
} from "lucide-react";
import Select from "react-select";
import "./AdvancedFilter.css";
import { dateFormatConverter, formatDate } from "../../util/utilityFunctions";
import { useSelector } from "react-redux";
import { viewAllBranchesAPICall } from "../../API/Settings/Company Settings/companySettingsAPI";

// Get current date and date 30 days ago
const currentDate = new Date();
const thirtyDaysAgo = new Date();
thirtyDaysAgo.setDate(currentDate.getDate() - 180);

const AdvancedFilter = forwardRef(
  (
    {
      filterOptions = ["date", "branch", "status", "location", "category"],
      handleFilter,
    },
    ref
  ) => {
    // --
    const allBranchesList = useSelector(
      (state) => state.allBranchesSlice.value
    );
    // console.log("all branch",allBranchesList)
    // --
    const [isOpen, setIsOpen] = useState(false);
    const [activeFilter, setActiveFilter] = useState(null);
    const [filterData, setFilterData] = useState({
      date: { from: formatDate(thirtyDaysAgo), to: formatDate(currentDate) },
      branch: [],
      status: [],
      location: [],
      category: [],
    });

    const [formData, setFormData] = useState({
      branchOptions: [],
      statusOptions: [],
    });

    //  setFormData()

    // const branchOptions = [
    //   { value: "main", label: "Main Branch" },
    //   { value: "downtown", label: "Downtown" },
    //   { value: "suburb", label: "Suburb" },
    //   { value: "airport", label: "Airport" },
    //   { value: "central", label: "Central" },
    //   { value: "east", label: "East Wing" },
    //   { value: "west", label: "West Wing" },
    // ];

    const statusOptions = [
      { value: "pending", label: "Pending" },
      { value: "completed", label: "Completed" },
      // { value: "in_progress", label: "In Progress" },
      // { value: "cancelled", label: "Cancelled" },
    ];
    const locationOptions = [
      { value: "l1", label: "l1" },
      { value: "12", label: "l2" },
      { value: "l3", label: "l3" },
      { value: "l4", label: "l4" },
    ];
    const categoryOptions = [
      { value: "category1", label: "Category 1" },
      { value: "category2", label: "Category 2" },
    ];

    const handleApplyFilters = () => {
      // console.log("Applied Filters:", filterData);
      setIsOpen(false);
      handleFilter(filterData);
    };

    const handleClearFilters = () => {
      setFilterData({
        date: { from: null, to: null },
        branch: [],
        status: [],
        location: [],
        category: [],
      });
    };

    // Expose the state to the parent using `useImperativeHandle`
    useImperativeHandle(ref, () => ({
      getChildState: () => filterData,
      setFilterData,
    }));

    const renderFilterPreview = (filterName) => {
      switch (filterName) {
        case "date":
          return filterData.date.from && filterData.date.to
            ? `${dateFormatConverter(
                filterData.date.from
              )} - ${dateFormatConverter(filterData.date.to)}`
            : "No date selected";  
        case "branch":
          return filterData.branch.length
            ? `${filterData.branch.length} selected`
            : "No branches selected";
        case "status":
          return filterData.status.length
            ? `${filterData.status.length} selected`
            : "No statuses selected";
        case "location":
          return filterData.location.length
            ? `${filterData.location.length} selected`
            : "No location selected";
        case "category":
          return filterData.category.length
            ? `${filterData.category.length} selected`
            : "No category selected";
        default:
          return "";
      }
    };

    const filterByCondition = (option) => {
      if (filterData.branch.length === 0) {
        return option !== "location";
      }
      return true;
    };

    //--
    const branchOptions = allBranchesList?.map((branch) => ({
      value: branch._id,
      label: branch.branchName,
    }));

    //initial api calls
    useEffect(() => {
      viewAllBranchesAPICall();
    }, []);

    // console.log("allBranchesList",allBranchesList)
    // console.log("braaaaaaaaaanch",branchOptions)

    const renderFilterContent = () => {
      switch (activeFilter) {
        case "date":
          return (
            <div className="adv-flt-filter-content">
              <h4 className="adv-flt-filter-title">Select Date Range</h4>
              <div className="adv-flt-date-range">
                <input
                  type="date"
                  className="adv-flt-date-input"
                  value={filterData.date.from || ""}
                  onChange={(e) =>
                    setFilterData({
                      ...filterData,
                      date: { ...filterData.date, from: e.target.value },
                    })
                  }
                />
                <span>to</span>
                <input
                  type="date"
                  className="adv-flt-date-input"
                  value={filterData.date.to || ""}
                  onChange={(e) =>
                    setFilterData({
                      ...filterData,
                      date: { ...filterData.date, to: e.target.value },
                    })
                  }
                />
              </div>
            </div>
          );
        case "branch":
          return (
            <div className="adv-flt-filter-content">
              <h4 className="adv-flt-filter-title">Select Branches</h4>
              <Select
                isMulti
                options={branchOptions}
                value={filterData.branch}
                onChange={(selected) =>
                  setFilterData({
                    ...filterData,
                    branch: selected,
                    location: selected.length === 0 ? [] : filterData.location,
                  })
                }
                placeholder="Select branches"
                className="adv-flt-react-select"
                classNamePrefix="adv-flt-react-select"
              />
            </div>
          );
        case "status":
          return (
            <div className="adv-flt-filter-content">
              <h4 className="adv-flt-filter-title">Select Statuses</h4>
              <Select
                isMulti
                options={statusOptions}
                value={filterData.status}
                onChange={(selected) =>
                  setFilterData({ ...filterData, status: selected })
                }
                placeholder="Select statuses"
                className="adv-flt-react-select"
                classNamePrefix="adv-flt-react-select"
              />
            </div>
          );
        case "location":
          return (
            <div className="adv-flt-filter-content">
              <h4 className="adv-flt-filter-title">Select Location</h4>
              <Select
                isMulti
                options={locationOptions}
                value={filterData.location}
                onChange={(selected) =>
                  setFilterData({ ...filterData, location: selected })
                }
                placeholder="Select locations"
                className="adv-flt-react-select"
                classNamePrefix="adv-flt-react-select"
              />
            </div>
          );
        case "category":
          return (
            <div className="adv-flt-filter-content">
              <h4 className="adv-flt-filter-title">Select category</h4>
              <Select
                isMulti
                options={categoryOptions}
                value={filterData.category}
                onChange={(selected) =>
                  setFilterData({ ...filterData, category: selected })
                }
                placeholder="Select categories"
                className="adv-flt-react-select"
                classNamePrefix="adv-flt-react-select"
              />
            </div>
          );
        default:
          return (
            <div className="adv-flt-filter-list">
              {filterOptions.filter(filterByCondition).map((option) => (
                <div
                  key={option}
                  className="adv-flt-filter-option"
                  onClick={() => setActiveFilter(option)}
                >
                  {option === "date" && <Calendar className="adv-flt-icon" />}
                  {option === "branch" && (
                    <Building2 className="adv-flt-icon" />
                  )}
                  {option === "status" && <List className="adv-flt-icon" />}
                  {option === "location" && <MapPin className="adv-flt-icon" />}
                  {option === "category" && (
                    <LayoutList className="adv-flt-icon" />
                  )}
                  <span>
                    {option.charAt(0).toUpperCase() + option.slice(1)}
                  </span>
                  <span className="adv-flt-preview">
                    {renderFilterPreview(option)}
                  </span>
                </div>
              ))}
            </div>
          );
      }
    };

    return (
      <div className="adv-flt-container">
        <button className="adv-flt-trigger" onClick={() => setIsOpen(!isOpen)}>
          <Filter className="adv-flt-icon" />
        </button>
        {isOpen && (
          <div className="adv-flt-popover">
            {activeFilter && (
              <button
                className="adv-flt-back-button"
                onClick={() => setActiveFilter(null)}
              >
                <ChevronLeft className="adv-flt-icon" /> Back
              </button>
            )}
            {renderFilterContent()}
            <div className="adv-flt-actions">
              <button
                className="adv-flt-button adv-flt-button-clear"
                onClick={handleClearFilters}
              >
                Clear
              </button>
              <button
                className="adv-flt-button adv-flt-button-apply"
                onClick={handleApplyFilters}
              >
                Apply
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default AdvancedFilter;
