import React, { useState } from 'react';
import Pagelayout from '../../components/Pagelayout';
import {
  TextField,
  Autocomplete,
  Grid,
  Paper,
  Typography,
  IconButton,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const CALCULATION_TYPES = ['% OF CTC', '% OF BASIC', 'FIXED'];
const ALLOWANCE_TYPES = ['TEL ALW', 'HOUSE ALW', 'FOOD ALW'];

const StyledTableContainer = styled(TableContainer)({
  maxHeight: 400,
  marginTop: 16,
  '&::-webkit-scrollbar': {
    width: '6px',
    height: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '3px',
  },
});

const StyledTableFooter = styled(TableRow)({
  position: 'sticky',
  bottom: 0,
  background: '#f5f5f5',
  fontWeight: 'bold',
});

const CreatePaytemplate = () => {
  const [templateData, setTemplateData] = useState({
    name: '',
    period: 'YEARLY',
    totalAmount: '',
    basic: {
      type: 'BASIC',
      calculationType: '% OF CTC',
      amount: '',
      monthly: '',
      yearly: '',
    },
    allowances: [],
    total: {
      monthly: 0,
      yearly: 0
    }
  });

  const [tableData, setTableData] = useState([]);

  const handleBasicChange = (field, value) => {
    setTemplateData(prev => ({
      ...prev,
      [field]: value
    }));
    
    // Recalculate basic if totalAmount changes
    if (field === 'totalAmount') {
      calculateBasic(templateData.basic.amount, value);
    }
  };

  const calculateBasic = (percentage, totalAmount) => {
    if (percentage && totalAmount) {
      const yearlyValue = (parseFloat(totalAmount) * parseFloat(percentage)) / 100;
      const monthlyValue = yearlyValue / 12;

      setTemplateData(prev => ({
        ...prev,
        basic: {
          ...prev.basic,
          monthly: monthlyValue.toFixed(2),
          yearly: yearlyValue.toFixed(2)
        }
      }));
    }
  };

  const handleBasicAmountChange = (value) => {
    setTemplateData(prev => ({
      ...prev,
      basic: {
        ...prev.basic,
        amount: value
      }
    }));
    calculateBasic(value, templateData.totalAmount);
  };

  const calculateAllowance = (amount, calculationType, index) => {
    let monthly = 0;
    let yearly = 0;

    if (calculationType === '% OF CTC' && templateData.totalAmount) {
      yearly = (parseFloat(templateData.totalAmount) * parseFloat(amount)) / 100;
      monthly = yearly / 12;
    } else if (calculationType === '% OF BASIC' && templateData.basic.yearly) {
      yearly = (parseFloat(templateData.basic.yearly) * parseFloat(amount)) / 100;
      monthly = yearly / 12;
    } else if (calculationType === 'FIXED') {
      monthly = parseFloat(amount);
      yearly = monthly * 12;
    }

    const updatedAllowances = [...templateData.allowances];
    updatedAllowances[index] = {
      ...updatedAllowances[index],
      monthly: monthly.toFixed(2),
      yearly: yearly.toFixed(2)
    };

    setTemplateData(prev => ({
      ...prev,
      allowances: updatedAllowances
    }));
    calculateTotal(updatedAllowances);
  };

  const calculateTotal = (allowances) => {
    const basicMonthly = parseFloat(templateData.basic.monthly) || 0;
    const basicYearly = parseFloat(templateData.basic.yearly) || 0;

    const totalMonthly = allowances.reduce((sum, allowance) => 
      sum + (parseFloat(allowance.monthly) || 0), basicMonthly);
    const totalYearly = allowances.reduce((sum, allowance) => 
      sum + (parseFloat(allowance.yearly) || 0), basicYearly);

    setTemplateData(prev => ({
      ...prev,
      total: {
        monthly: totalMonthly.toFixed(2),
        yearly: totalYearly.toFixed(2)
      }
    }));
  };

//   const handleAddAllowance = () => {
//     setTemplateData(prev => ({
//       ...prev,
//       allowances: [
//         ...prev.allowances,
//         {
//           type: '',
//           calculationType: '% OF CTC',
//           amount: '',
//           monthly: '',
//           yearly: ''
//         }
//       ]
//     }));
//   };

  const handleRemoveAllowance = (index) => {
    const updatedAllowances = templateData.allowances.filter((_, i) => i !== index);
    setTemplateData(prev => ({
      ...prev,
      allowances: updatedAllowances
    }));
    calculateTotal(updatedAllowances);
  };

  const handleAddBasic = () => {
    if (!templateData.basic.amount) return;
    
    const basicRow = {
      payHead: 'BASIC',
      calculationType: templateData.basic.calculationType,
      amount: `${templateData.basic.amount}%`,
      monthly: templateData.basic.monthly,
      yearly: templateData.basic.yearly,
    };

    setTableData(prev => [...prev, basicRow]);
  };

  const handleAddAllowance = () => {
    const currentAllowance = templateData.allowances[templateData.allowances.length - 1];
    if (!currentAllowance?.amount) return;

    const allowanceRow = {
      payHead: currentAllowance.type,
      calculationType: currentAllowance.calculationType,
      amount: currentAllowance.calculationType === 'FIXED' 
        ? `${currentAllowance.amount} AED` 
        : `${currentAllowance.amount}%`,
      monthly: currentAllowance.monthly,
      yearly: currentAllowance.yearly,
    };

    setTableData(prev => [...prev, allowanceRow]);
  };
  const navigate = useNavigate();
  const handleBack = () => {
    navigate('/userdashboard/payroll/configuration/payTemplate/');
  }

  return (
    <Pagelayout pageName="Create Pay Template" headerLayout={2} handleBack={handleBack}>
      <StyledPaper>
        <Grid container spacing={2}>
          {/* Header Section */}
          <Grid item xs={4}>
            <TextField
              fullWidth
              size="small"
              label="NAME"
              value={templateData.name}
              onChange={(e) => handleBasicChange('name', e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              fullWidth
              size="small"
              options={['YEARLY', 'MONTHLY']}
              value={templateData.period}
              onChange={(_, value) => handleBasicChange('period', value)}
              renderInput={(params) => (
                <TextField {...params} label="PERIOD" InputLabelProps={{ shrink: true }} />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              size="small"
              label="AMOUNT"
              value={templateData.totalAmount}
              onChange={(e) => handleBasicChange('totalAmount', e.target.value)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: <Typography variant="caption">AED</Typography>,
              }}
            />
          </Grid>

          {/* Basic Section - Add button */}
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1">Basic Component</Typography>
              <Button
                startIcon={<AddCircleOutlineIcon />}
                onClick={handleAddBasic}
                size="small"
                variant="contained"
              >
                Add Basic
              </Button>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={2.4}>
                <TextField
                  fullWidth
                  size="small"
                  label="BASIC"
                  value={templateData.basic.type}
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
              </Grid>
              <Grid item xs={2.4}>
                <TextField
                  fullWidth
                  size="small"
                  label="CALCULATION TYPE"
                  value={templateData.basic.calculationType}
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
              </Grid>
              <Grid item xs={2.4}>
                <TextField
                  fullWidth
                  size="small"
                  label="AMOUNT"
                  value={templateData.basic.amount}
                  onChange={(e) => handleBasicAmountChange(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: <Typography variant="caption">%</Typography>,
                  }}
                />
              </Grid>
              <Grid item xs={2.4}>
                <TextField
                  fullWidth
                  size="small"
                  label="MONTHLY"
                  value={templateData.basic.monthly}
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
              </Grid>
              <Grid item xs={2.4}>
                <TextField
                  fullWidth
                  size="small"
                  label="YEARLY"
                  value={templateData.basic.yearly}
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Allowances Section - Update button */}
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1">Allowances</Typography>
              <Button
                startIcon={<AddCircleOutlineIcon />}
                onClick={handleAddAllowance}
                size="small"
                variant="contained"
              >
                Add Allowance
              </Button>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={2.4}>
                <TextField
                  fullWidth
                  size="small"
                  label="BASIC"
                  value={templateData.allowances.type}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2.4}>
                <TextField
                  fullWidth
                  size="small"
                  label="CALCULATION TYPE"
                  value={templateData.basic.calculationType}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2.4}>
                <TextField
                  fullWidth
                  size="small"
                  label="AMOUNT"
                  value={templateData.basic.amount}
                  onChange={(e) => handleBasicAmountChange(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: <Typography variant="caption">%</Typography>,
                  }}
                />
              </Grid>
              <Grid item xs={2.4}>
                <TextField
                  fullWidth
                  size="small"
                  label="MONTHLY"
                  value={templateData.basic.monthly}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={2.4}>
                <TextField
                  fullWidth
                  size="small"
                  label="YEARLY"
                  value={templateData.basic.yearly}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
           
        
          
          </Grid>

          {/* Add Table Section */}
          <Grid item xs={12}>
            <StyledTableContainer component={Paper}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>PAY HEAD</TableCell>
                    <TableCell>CALCULATION TYPE</TableCell>
                    <TableCell>AMOUNT</TableCell>
                    <TableCell>MONTHLY</TableCell>
                    <TableCell>YEARLY</TableCell>
                    <TableCell width={50}>ACTION</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.payHead}</TableCell>
                      <TableCell>{row.calculationType}</TableCell>
                      <TableCell>{row.amount}</TableCell>
                      <TableCell>{row.monthly}</TableCell>
                      <TableCell>{row.yearly}</TableCell>
                      <TableCell>
                        <IconButton 
                          size="small" 
                          color="error"
                          onClick={() => {
                            const newData = tableData.filter((_, i) => i !== index);
                            setTableData(newData);
                          }}
                        >
                          <DeleteOutlineIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  <StyledTableFooter>
                    <TableCell colSpan={3}>TOTAL</TableCell>
                    <TableCell>
                      {tableData.reduce((sum, row) => sum + parseFloat(row.monthly || 0), 0).toFixed(2)}
                    </TableCell>
                    <TableCell>
                      {tableData.reduce((sum, row) => sum + parseFloat(row.yearly || 0), 0).toFixed(2)}
                    </TableCell>
                    <TableCell />
                  </StyledTableFooter>
                </TableBody>
              </Table>
            </StyledTableContainer>
          </Grid>

         
        </Grid>
      </StyledPaper>
    </Pagelayout>
  );
};

export default CreatePaytemplate;
