import React, { useEffect, useRef, useState } from "react";
import "./componentStyles.css";
import AdvancedFilter from "../../../filter/AdvancedFilter";
import SearchBox from "../../../search/AdvancedSearch";
import CustomPagination from "../../../pagination/AdvancedPagination1";
import { IconButton } from "@mui/material";
import { FaList, FaThLarge } from "react-icons/fa";
import HeaderLayout2 from "./PageHeader2";

const PageHeader = ({
  isGridView,
  setIsGridView,
  handleCreateBtn,
  pageName,
  headerLayout, 
  handleBack,
  handleEdit,
  handleSave,
  newData,
  generatePayslip,
  handleGeneratePayslip,
  gridView,
  filter=true,
  searchBox=true,
  createBtn=true,
  pagination=true ,
  actionBtn=true

}) => {
  const [error, setError] = useState("");
  const [loadingPage, setLoadingPage] = useState(false);
  //pagination

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  //  filter submission
  const [filterData, setFilterData] = useState(null);
  const handleFilterSubmission = (data) => {
    setFilterData(data);
  };
  const childRef = useRef();

  // Function to get the child's state
  const handleGetChildState = () => {
    if (childRef.current) {
      const state = childRef.current.getChildState();
      setFilterData(state);
    }
  };

  //  initial rendering
  useEffect(() => {
    if (!filterData) {
      handleGetChildState();
    }
    if (filterData) {
      setLoadingPage(true);
      //   dispatch(setAlterationList({ ...alterationListData, data: [] }));
      //   listAlterationAPI({
      //     body: {
      //       index: currentPage,
      //       shiftId: "",
      //       filter: {
      //         date: {
      //           from: `${filterData?.date?.from} ${currentTime}`,
      //           to: `${filterData?.date?.to} 23:59:59`,
      //         },
      //         branch: extractValue(filterData.branch),
      //         status: extractValue(filterData.status),
      //       },
      //     },
      //     setLoadingPage,
      //   });
    }
  }, [filterData, currentPage]);
  
  const handleLayoutChange = () => {
    try {
      setIsGridView(!isGridView);
    } catch (err) {
      setError("Failed to change layout. Please try again.");
      setTimeout(() => setError(""), 3000);
    }
  };

  const onPageChange =(pageNumber)=>{
 console.log("page change",pageNumber)
  }

  return (
    <header className="new-section-header">
      <div className="new-section-header__content">
        {headerLayout == 1 ? (
          <>
            <div className="new-section-header__filter">
             {filter && <AdvancedFilter
                handleFilter={handleFilterSubmission}
                filterOptions={["date", "branch", "status"]}
                ref={childRef}
              />}
              {searchBox && <SearchBox />}
             {createBtn && <button
                onClick={() => handleCreateBtn()}
                className="new-section-header__create-button"
              >
                Create
              </button>}
            </div>
          </>
        ) : (
          <>
          <HeaderLayout2 actionBtn={actionBtn} handleGeneratePayslip={handleGeneratePayslip} generatePayslip={generatePayslip}   handleEdit={handleEdit}handleSave={handleSave}   handleBack={handleBack} newData={newData}/>
          </>
        )}
      </div>

      <div className="new-section-header__pagination">
        {headerLayout == 1 ? (
          <>
            <strong style={{ margin: 0, fontSize: "25px", fontWeight: "500" }}>
              {pageName}
            </strong>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
             {gridView && <IconButton
                onClick={handleLayoutChange}
                aria-label={
                  isGridView ? "Switch to list view" : "Switch to grid view"
                }
                sx={{ p: 1 }}
              >
                {isGridView ? <FaList size={15} /> : <FaThLarge size={15} />}
              </IconButton>}
            {pagination &&  <CustomPagination totalPages={10} onPageChange={onPageChange}  />}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </header>
  );
};

export default PageHeader;
