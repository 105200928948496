import axios from "axios"
import { HEADERS, HEADER_FORM_DATA, HEADER_QUERY } from "../../../../API/UrlAndPaths"
import store from "../../../../Redux/store"
import { setSubmitLoading, setTableLoadingGlobal } from "../../../../Redux/Dashboard/mainDashBoardSlice"
import { get_promotion_offer_list, get_Promotion_single_view } from "./PromotionSlice"
import { setErrorSnack, setSnackMsg, setSuccessSnack } from "../../Sales/Billing/Slice/billingScreenSlice"


// create promotion and offers
export const createPromotionAndOffersAPI= async (body) => {
   
    await axios.post("workorder/addPromotionAndOffer", body, HEADER_FORM_DATA)
        .then((res) => {
            
            if (res.status === 201) {
                SingleViewPromotionAPI({_id:res.data._id})
                localStorage.setItem("rowId",res.data._id)
            }
        })
        .catch((err) => {
            store.dispatch(setTableLoadingGlobal(false))
            if(err.response.status===500){
            }
        })
}
//singleview promotion //
export const SingleViewPromotionAPI = async (body) => {
    await axios.post("workorder/viewPromotionAndOfferById", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch( get_Promotion_single_view(res.data))
            }
        })
        .catch((err) => {
            store.dispatch( get_Promotion_single_view(undefined))
        })
}
//edit Reward
export const editedPromotionAndofferAPI = async (body, draftFn) => {
    store.dispatch(setSubmitLoading(true))
    await axios.put("workorder/editPromotionAndOffer", body, HEADERS)
        .then((res) => {
            store.dispatch(setSubmitLoading(false))
            if (res.status === 201) {
                store.dispatch(setSnackMsg("Edited"))
                store.dispatch(setSuccessSnack(true))
                draftFn()
            }
        })
        .catch((err) => {
            store.dispatch(setSubmitLoading(false))
            if (err.response.status === 500) {
                store.dispatch(setSnackMsg("Internal Server Error !!"))
            } else {
                store.dispatch(setSnackMsg(err.response.data))
            }
            store.dispatch(setErrorSnack(true))

        })
}
//list of promotion & offers//
export const ListPromotionAndOffersAPI = async (body) => {
    await axios.post("workorder/viewPromotionAndOffer",body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                
                store.dispatch(get_promotion_offer_list(res.data))

            }
        })
        .catch((err) => {
            store.dispatch(get_promotion_offer_list(undefined))


        })
}

//delete promotion and offer
export const deleteApiCall = (id) => {
    axios
      .delete("workorder/deletePromotionAndOfferById", { headers: HEADER_QUERY,
        data:{_id:id}
      } )
      .then((res) => {
        ListPromotionAndOffersAPI();
      })
      .catch((err) => {
        window.alert("SOMETHING WRONG >>>");
      });
  };

