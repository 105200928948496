import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { HEADERS } from '../../../../../API/UrlAndPaths';
import store from '../../../../../Redux/store';
import { setApplyBtnClicked } from './filterButtonSlice';
import { useSelector } from 'react-redux';
import { successSnackMsg } from '../../../../Custom Hooks/SnackBarUtilities';

const useProducts = (payload) => {
    const [data, setData] = useState(() => {
        const storedData = sessionStorage.getItem('productsData');
        return storedData ? JSON.parse(storedData) : null;
    });
    const [loading, setLoading] = useState(!data);
    const [error, setError] = useState(null);
    const isFetching = useRef(false); // Track the status of API calls
    const {applyBtnClicked}=useSelector((state)=>state.filterButtonSlice)

    useEffect(() => {
        const fetchProducts = async () => {
            // Avoid duplicate requests if one is already pending
            if (isFetching.current) return;

            isFetching.current = true; // Set to true while fetching

            try {
                setLoading(true);
                const response = await axios.post('readymade/viewproductsforpos', payload, HEADERS);
                setData(response.data);
                sessionStorage.setItem('productsData', JSON.stringify(response.data));
                applyBtnClicked==="productFetch" && successSnackMsg("Product lists refreshed")
            } catch (err) {
                setError(err);
                console.error('Error fetching products:', err);
            } finally {
                setLoading(false);
                isFetching.current = false; // Set to false after fetching
                store.dispatch(setApplyBtnClicked(false))
            }
        };

        // Initial fetch
        if (!data || applyBtnClicked==="productFetch") {
            fetchProducts();
        }

        // Refresh data every second
        const refreshTimer = setInterval(async () => {
            if (isFetching.current) return;

            isFetching.current = true;
            try {
                const response = await axios.post('readymade/viewproductsforpos', payload, HEADERS);
                setData(response.data);
                sessionStorage.setItem('productsData', JSON.stringify(response.data));
            } catch (err) {
                console.error('Error refreshing data:', err);
            } finally {
                isFetching.current = false;
            }
        }, 3600000);

        // Cleanup to clear the interval on component unmount
        return () => clearInterval(refreshTimer);
    }, [payload, data,applyBtnClicked]);

    return { data, loading, error };
};

export default useProducts;
